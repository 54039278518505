import React, { useState } from "react";
import { useFormState } from "react-use-form-state";
import validate from "../../helpers/validate";
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import Space from "../../components/Space";
import Button from "../../components/Button";
import Frame from "../../components/Frame";
import FrameFooter from "../../components/FrameFooter";
import InputRow from "../../components/InputRow";
import Label from "../../components/Label";
import TextInput from "../../components/TextInput";
import styles from './CustomerInfoForm.module.scss';

export default function CustomerInfoForm({ state, saving, onSave, isNew }) {
  const [formState, { text, email, select }] = useFormState({
    ...state
  });

  const valid = validate(formState, {
    required: ["name", "email", "country", "address", "city", "zip"]
  });
  const [address, setAddress] = useState(getAddress());
  function MapResults(result) {
    setAddress(result.formatted_address);
    result.address_components[0] && formState.setField("address", result.address_components[0].long_name + " " + result.address_components[1].long_name);
    result.address_components[2] && formState.setField("city", result.address_components[2].long_name);
    result.address_components[6] && formState.setField("zip", result.address_components[6].long_name);
    result.address_components[4] && formState.setField("state", result.address_components[4].long_name);
    result.address_components[5] && formState.setField("country", result.address_components[5].long_name);
  }

  function getAddress() {
    var address = "";

    if (formState.values.address !== undefined && formState.values.address !=='') {
      address += formState.values.address + ", ";
    }
    if (formState.values.city !== undefined && formState.values.city !=='') {
      address += formState.values.city + ", ";
    }
    if (formState.values.zip !== undefined && formState.values.zip !=='') {
      address += formState.values.zip + ", ";
    }
    if (formState.values.state !== undefined && formState.values.state !=='') {
      address += formState.values.state + ", ";
    }
    if (formState.values.country !== undefined && formState.values.country !=='') {
      address += formState.values.country;
    }
    return address;
  }
  function handleChange(address) {
    setAddress(address);
  };

  function handleSelect(address) {
    geocodeByAddress(address)
      .then(results => MapResults(results[0]))
  };

  function _save(e) {
    e.preventDefault();
    onSave(formState.values);
  }

  return (
    <>
      <Frame>
        <form onSubmit={_save}>

          <InputRow>
            <Label text="Name">
              <TextInput
                {...text("name")}
                error={formState.touched.name && formState.errors.name}
                required
              />
            </Label>

            <Label text="Email">
              <TextInput
                {...email("email")}
                error={formState.touched.email && formState.errors.email}
                required
              />
            </Label>
          </InputRow>

          <InputRow>
            <Space size="s">
              <div>
                <span style={{ display: 'block', paddingBottom: 14 }}>Address</span>
              </div>

              <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
              >
               {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <input
                      {...getInputProps({
                        //placeholder: 'Enter address',
                        className: styles.autocomplete,
                      })}
                    />
                    <div className={styles.autocompleteDiv}>
                      {loading && <div>Loading...</div>}
                      {suggestions.length!=0 && suggestions.map(suggestion => {
                        const className = suggestion.active
                          ? styles.autocompleteSuggestionActive
                          : styles.autocompleteSuggestion;

                        const style = suggestion.active
                          ? { backgroundColor: '#eeeeee', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,

                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

              </PlacesAutocomplete>
            
            </Space>
          </InputRow>
          <FrameFooter>
            <Button
              type="submit"
              theme="primary"
              isLoading={saving}
              disabled={!valid}
            >
              {isNew ? "Create" : "Save"}
            </Button>
          </FrameFooter>
        </form>
      </Frame>
    </>
  );
}
