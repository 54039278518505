import React from 'react';
import { Link } from 'react-router-dom';
import styles from './RowItemAlarmActionMobile.module.scss';

export default function RowItemAlarmActionMobile({
  title,
  subtitle,
  to,
  left,
  right=false,

}) {


  return to && (<div className={styles.outerDiv}>
    <div className={styles.leftDiv}>
      <a href={to} className={styles.row}>
        {left && <div className={styles.left}>{left}</div>}

        <div>
          {title && <div className={styles.title}>{title}</div>}
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}

        </div>
      </a>
    </div>
    {right && <div className={styles.rightDiv}>
      <div className={styles.rightMobile}>{right}</div>
    </div>}

  </div>)

}
