import React, { useContext } from 'react';

import NavContext from '../../contexts/Nav';

import Button from '../Button';

import styles from './PageHeader.module.scss';

export default function PageHeader({ right, children, noZIndex,secondheader }) {
  const { isNavShowing, setIsNavShowing } = useContext(NavContext);
   var style = secondheader? styles.secondheader : styles.header;
  return (
    <header className={style} style={noZIndex ? { zIndex: 1 } : null}>
      <div className={styles.menuButton}>
        <Button icon="menu" onClick={() => setIsNavShowing(!isNavShowing)} />
      </div>

      <div className={styles.content}>{children}</div>

      {right && <div className={styles.right}>{right}</div>}
    </header>
  );
}
