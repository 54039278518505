import React, { useEffect, useState, useContext } from 'react';
import { useConfirm } from 'material-ui-confirm';
import Button from '../../components/Button';
import { get,destroy } from '../../api';
import FrameHeader from '../../components/FrameHeader';
import Frame from '../../components/Frame';
import RowItem from '../../components/RowItem';
import LoadingFrame from '../../components/LoadingFrame';
import Space from '../../components/Space';
import styles from './CustomerDevices.module.scss';
import { SYS_ADMIN, TENANT_ADMIN } from '../../constants/roles';
import { isAuthorized } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import StatusIndicator from '../../components/StatusIndicator';
export default function CustomerDevices({ match: { params }, admin = false }) {
  const confirm = useConfirm();
  const [loading, setLoading] = useState(true);
  const { state: currentUser } = useContext(AuthContext);
  const [activeDevices, setActiveDevices] = useState([]);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveDevices, setInactiveDevices] = useState([]);
  const [inactiveCount, setInactiveCount] = useState(0);
  useEffect(() => {
    var isApiSubscribed = true;
    async function fetchDevices() {
      const finalParams = params.params !== undefined ? params.params : params;
      const [active, inactive] = await Promise.all([
        get(`/deviceslist`, {
          customer_id: finalParams.id,
          active: true,
          status: true
        }),

        get(`/deviceslist`, {
          customer_id: finalParams.id,
          active: false,
        })
      ]);


      if (isApiSubscribed) {
        setActiveDevices(active?.sort((a, b) => a.name > b.name ? 1 : -1));
        setActiveCount(active.length);
        setInactiveDevices(inactive?.sort((a, b) => a.name > b.name ? 1 : -1));
        setInactiveCount(inactive.length);
        setLoading(false);
      }
    }
    fetchDevices();

    return () => {
      isApiSubscribed = false;
    };
  }, [admin]);
  async function deleteDevice(id) {
    confirm({
      description: "Are you sure you want to delete this device?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        const res =  destroy(`/devices/${id}/admin`);
      })
      .catch(() => { /* ... */ });


  }
  return loading ? (
    <LoadingFrame />
  ) : (
    <>
      <Space size="l">
        <FrameHeader title={`Active trackers (${activeCount})`} />
        <Frame padding={0}>
          {activeDevices.length ? (
            activeDevices.map(device => (
              <RowItem
                key={device.id}
                title={device.name}
                //subtitle={subtitle(device.created_time)}
                to={(isAuthorized(currentUser.user.roles, [SYS_ADMIN, TENANT_ADMIN]) || admin === true) && `/TresorTrackdevice/${device.id}`}
                right={(isAuthorized(currentUser.user.roles, [SYS_ADMIN, TENANT_ADMIN]) || admin === true) &&
                  <div className={styles.wrap}> <StatusIndicator status={device.status} />
                    <Button onClick={() => deleteDevice(device.id)}>Delete</Button>
                  </div>}

              />
            ))
          ) : (
            <div className={styles.empty}>No active trackers</div>
          )}
        </Frame>
      </Space>
      <FrameHeader title={`Inactive trackers (${inactiveCount})`} />
      <Frame padding={0}>
        {inactiveDevices.length ? (
          inactiveDevices.map(device => (
            <RowItem
              key={device.id}
              title={device.name}
              //subtitle={subtitle(device.created_time)}
             // to={(isAuthorized(currentUser.user.roles, [SYS_ADMIN, TENANT_ADMIN]) || admin === true) && `/TresorTrackdevice/${device.id}`}
              right={(isAuthorized(currentUser.user.roles, [SYS_ADMIN, TENANT_ADMIN]) || admin === true) &&
                <div className={styles.wrap}>
                  <Button onClick={() => deleteDevice(device.id)}>Delete</Button>
                </div>}
            />
          ))
        ) : (
          <div className={styles.empty}>No inactive trackers</div>
        )}
      </Frame>
    </>
  );
}
