import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { get } from '../../api';

import FrameHeader from '../../components/FrameHeader';

import Frame from '../../components/Frame';
import RowItem from '../../components/RowItem';
import LoadingFrame from '../../components/LoadingFrame';
import Space from '../../components/Space';

import styles from './TenantDevices.module.scss';

export default function CustomerDevices({ match: { params } }) {
  const [loading, setLoading] = useState(true);
  const [activeDevices, setActiveDevices] = useState([]);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveDevices, setInactiveDevices] = useState([]);
  const [inactiveCount, setInactiveCount] = useState(0);

  useEffect(() => {
    async function fetchDevices() {
      const [active, inactive] = await Promise.all([
        get(`/devices`, {
          tenant_id: params.id,
          active: 'true',
          include: 'COUNT(*)'
        }),

        get(`/devices`, {
          tenant_id: params.id,
          active: 'false',
          include: 'COUNT(*)'
        })
      ]);

      setActiveDevices(active.results);
      setActiveCount(active.total);

      setInactiveDevices(inactive.results);
      setInactiveCount(inactive.total);

      setLoading(false);
    }

    fetchDevices();
  }, []);

  function subtitle(time) {
    const fullDateTime = moment(time).format('dddd, MMMM Do YYYY, h:mm:ss a');
    const timeAgo = moment(time).fromNow();

    return <span title={fullDateTime}>{`Provisioned ${timeAgo}`}</span>;
  }

  return loading ? (
    <LoadingFrame />
  ) : (
    <>
      <Space size="l">
        <FrameHeader title={`Inactive trackers (${inactiveCount})`} />
        <Frame padding={0}>
          {inactiveDevices.length ? (
            inactiveDevices.map(device => (
              <RowItem
                key={device.id}
                title={device.name}
                subtitle={subtitle(device.created_time)}
                to={`/devices/${device.id}`}
              />
            ))
          ) : (
            <div className={styles.empty}>No inactive trackers</div>
          )}
        </Frame>
      </Space>

      <FrameHeader title={`Active trackers (${activeCount})`} />
      <Frame padding={0}>
        {activeDevices.length ? (
          activeDevices.map(device => (
            <RowItem
              key={device.id}
              title={device.name}
              subtitle={subtitle(device.created_time)}
              to={`/devices/${device.id}`}
            />
          ))
        ) : (
          <div className={styles.empty}>No active trackers</div>
        )}
      </Frame>
    </>
  );
}
