import React from 'react';
import styles from './Label.module.scss';
import Icon from '../Icon';

export default function Label({ text, children, icon, theme, style, className }) {
  const classes = [styles.label, styles[theme]].join(' ');

  return (
    <label style={{ ...style }} className={className}>
      <span className={classes}>{text}</span>
      <div className={styles.value}>
        {icon && (
          <span className={styles.icon}>
            <Icon name={icon} />
          </span>
        )}
        {children}
      </div>
    </label>
  );
}
