import React, { useState, useContext } from 'react';
import { useFormState } from 'react-use-form-state';

import { hasPermission } from '../../helpers/authorization';
import { AuthContext } from '../../contexts/Auth';
import validate from '../../helpers/validate';

import Button from '../../components/Button';
import Frame from '../../components/Frame';
import FrameFooter from '../../components/FrameFooter';
import InputRow from '../../components/InputRow';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import FrameHeader from '../../components/FrameHeader';
import CheckboxWithLabel from '../../components/CheckboxWithLabel';
//import styles from 'react-simple-wysiwyg/lib/styles';
import styles from './UserInfoForm.module.scss';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import flags from 'react-phone-number-input/flags'

export default function UserInfoForm({
  state,
  permissions,
  saving,
  onSave,
  isNew
}) {
  const { state: currentUser } = useContext(AuthContext);

  const [permState, setPermState] = useState(
    permissions || {
      admin: false,
      assets: true,
      inspection: true
    }
  );

  const [phoneValidationError, setphoneValidationError] = useState(undefined);
  const [cboxNotificationEmail, setCboxNotificationEmail] = useState(!state.disable_notification);
  const [cboxnotificationTxt, setCboxNotificationTxt] = useState(!state.disable_notification_txt);
  const [cboxnotificationPush, setCboxNotificationPush] = useState(!state.disable_notification_push);
  const [formState, { text, tel, email, checkbox }] = useFormState({ ...state });
  const valid = validate(formState, {
    required: ['first_name', 'last_name', 'phone', ...(isNew ? ['email'] : [])]
  });

  const isLoggedInUser = currentUser.user.user_id === formState.values.id;

  function changeState(target, type) {
    switch (type) {

      case 0:
        formState.values.disable_notification = !target.target.checked;
        setCboxNotificationEmail(target.target.checked);
        break;


      case 1:
        formState.values.disable_notification_txt = !target.target.checked;
        setCboxNotificationTxt(target.target.checked);
        break;

      case 2:
        formState.values.disable_notification_push = !target.target.checked;
        setCboxNotificationPush(target.target.checked);
        break;

    }

  }

  function updateEmail(val) {
    if (val != undefined && val != '') {
      if (isValidPhoneNumber(val)) {
        formState.setField("phone", val);
        setphoneValidationError(false);
      }
      else { setphoneValidationError("Please enter a valid phone number."); }
    }
    else {
      setphoneValidationError("An email address is required");
    }
  }

  return (
    <>
      <Frame>
        <InputRow>
          <Label text="First name">
            <TextInput
              {...text('first_name')}
              error={
                formState.touched.first_name && formState.errors.first_name
              }
              required
            />
          </Label>

          <Label text="Last name">
            <TextInput
              {...text('last_name')}
              error={formState.touched.last_name && formState.errors.last_name}
              required
            />
          </Label>
        </InputRow>

        <InputRow>
          <Label text="Email">
            {isNew ? (
              <TextInput
                {...email('email')}
                error={formState.touched.email && formState.errors.email}
                required
              />
            ) : (
                <TextInput value={state.email} readOnly disabled />
              )}
          </Label>
          <div>
            <span style={{ display: 'block', paddingBottom: 14 }}>Telephone</span>
            <PhoneInput
              defaultCountry="US"
              countryCallingCodeEditable={false}
              required
              international
              error={phoneValidationError}
              flags={flags}
              inputComponent={TextInput}
              onChange={val => updateEmail(val)}
              value={formState.values.phone ? formState.values.phone : undefined}
            />
          </div>
        </InputRow>

        <InputRow>
        <Label text="Timezone">
            <TextInput
              {...text('timezone_offset')}
              error={formState.touched.timezone_offset && formState.errors.timezone_offset}
              required
            />
          </Label>
        </InputRow>

          <FrameHeader title="Notifications" />
          <InputRow>
            <CheckboxWithLabel
              fullwidth={true}
              checked={cboxNotificationEmail}
              label="Email"
              description="This user will receive email notifications from the TresorTrack dashboard."
              //checked={formState.values.disable_notification}
              onChange={e => {
                changeState(e, 0);
              }
              }
            />

            <CheckboxWithLabel
              fullwidth={true}
              checked={cboxnotificationTxt}
              label="Text message"
              description="This user will receive text notifications from the TresorTrack dashboard."
              //checked={formState.values.disable_notification}
              onChange={e => {
                changeState(e, 1);
              }
              }
            />
            <CheckboxWithLabel
              fullwidth={true}
              checked={cboxnotificationPush}
              label="Push notificaton (mobile app)"
              description="This user will receive in-app push notifications from the TresorTrack dashboard."
              //checked={formState.values.disable_notification}
              onChange={e => {
                changeState(e, 2);
              }
              }
            />
          </InputRow>

        {hasPermission(currentUser.user.perms, 'ADMIN') && !isLoggedInUser && (
          <>
            <FrameHeader title="Permissions" />
            <InputRow>
              <CheckboxWithLabel
                fullwidth={true}
                label="Admin"
                description="This user can manage all content on the account, including adding and removing users and their permissions."
                checked={permState.admin}
                onChange={e =>
                  setPermState({ ...permState, admin: e.target.checked })
                }
              />

              <CheckboxWithLabel
                fullwidth={true}
                label="Can manage assets"
                description="This user can create, edit, and delete assets, sites, and groups."
                disabled={permState.admin}
                checked={permState.admin || permState.assets}
                onChange={e =>
                  setPermState({ ...permState, assets: e.target.checked })
                }
              />
            </InputRow>
            <InputRow>
              <CheckboxWithLabel
                fullwidth={true}
                label="Can create notes and inspections"
                description="This user can create notes and inspections on devices and assets."
                disabled={permState.admin}
                checked={permState.admin || permState.inspection}
                onChange={e =>
                  setPermState({ ...permState, inspection: e.target.checked })
                }
              />

              <div />
            </InputRow>
            {/* 
            <FrameHeader title="Default Group" />
            <CheckboxWithLabel
              label="Include in default group"
              description="Allow this user to access assets that aren't assigned to a particular group in your customer account."
              checked={}
              onChange={ }
            /> */}
          </>
        )}

        <FrameFooter>
          <Button
            theme="primary"
            onClick={
              () => onSave(formState.values, permState)
            }
            isLoading={saving}
            disabled={!valid || phoneValidationError}
          >
            {isNew ? 'Create' : 'Save'}
          </Button>
        </FrameFooter>
      </Frame>
    </>
  );
}
