import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import TypesContext from '../../contexts/Types';
import Button from '../Button';
import DeviceStatus from '../DeviceStatus';
import Frame from '../Frame';
import Icon from '../Icon';
import Label from '../Label';
import { hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import styles from './AssociatedDevice.module.scss';
import { useConfirm } from 'material-ui-confirm';

export default function AssociatedDevice({
  assetId,
  link,
  device_type,
  deployed,
  device,
  relation,
  onRemove
}) {
  const types = useContext(TypesContext);
  const { state: currentUser } = useContext(AuthContext);
  const type = types.devices.find(
    t => t.id === (device && device.devicetype_id)
  );
  const confirm = useConfirm();

  function _removeDevice() {
    confirm({
      description: 'Are you sure you want to remove this device from the asset?',
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        onRemove(relation.id);
      })
      .catch(() => { /* ... */ });
  }

  function _renderDevice() {
    return (
      <>
        <div className={styles.status}>
          <DeviceStatus
            status={device.status}
            device_type={device_type}
            deployed={deployed}
            signal={device.signal}
            battery={device.battery}
            temp={device.temperature}
            firmware={device.firmware}
            updated={device.status_updated_time}
          />
          
        </div>
        <div className={styles.details}>
          <Label text="Serial number" theme="light">
            {link==false ? ( <h3>{device.serial_number}</h3>):(<Link to={`/devices/${device.id}`}>
              <h3>{device.serial_number}</h3>
            </Link>)}
          </Label>
          <Label text="Version" theme="light">
            <p className={styles.version}>{type.name}</p>
          </Label>
         {onRemove && (<div className={styles.button}>
            <Button
              theme="danger"
              size="s"
              icon="delete_forever"
              onClick={_removeDevice}>
              Remove device
            </Button>
          </div>)}
        </div>
      </>
    );
  }

  function _renderEmpty() {
    return (
      <div className={styles.emptyWrap}>
        <div className={styles.icon}>
          <Icon name="sync_disabled" size={36} />
        </div>
        {(hasPermission(currentUser.user.perms, "ADMIN") || hasPermission(currentUser.user.perms, "ASSET_U")) ? (
        <div className={styles.emptyContent}>
          <div className={styles.emptyInstructions}>
            <h3>There is no device associated with this asset.</h3>
            <p>To enable impact monitoring, you must complete the following:</p>
            <ul>
              <li>Install a device on the asset.</li>
              <li>Upload a photo of the device installed on the asset.</li>
            </ul>
          </div>
          <br/>
          <Link to={`/assets/${assetId}/install`}>
            <Button theme="primary">Install device on asset</Button>
          </Link>
        </div>):
        (<div className={styles.emptyContent}>
          <div className={styles.emptyInstructions}>
            <h3>There is no device associated with this asset.</h3>
          </div>
        </div>)}
      </div>
    );
  }

  return (
    <Frame padding={0}>
      {device.id && relation ? _renderDevice() : _renderEmpty()}
    </Frame>
  );
}
