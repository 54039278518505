import React, { useState, useEffect, useContext } from 'react';
import { differenceBy } from 'lodash';

import { get, post, destroy } from '../../api';
import { AuthContext } from '../../contexts/Auth';

import Button from '../../components/Button';
import Frame from '../../components/Frame';
import FrameHeader from '../../components/FrameHeader';
import Label from '../../components/Label';
import LoadingFrame from '../../components/LoadingFrame';
import NoResults from '../../components/NoResults';
import RowItem from '../../components/RowItem';
import Space from '../../components/Space';
import TextInput from '../../components/TextInput';
import { useConfirm } from 'material-ui-confirm';
// import styles from './DefaultGroupDetail.module.scss';

export default function DefaultGroupDetail() {
  const { state } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [groupUsers, setGroupUsers] = useState([]);
  const [nonGroupUsers, setNonGroupUsers] = useState([]);
  const [userLoading, setUserLoading] = useState(0);
  const confirm = useConfirm();

  useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    const [groupUsers, nonGroupUsers] = await Promise.all([
      get(`/groups/1/users`),
      get('/users', { customer_id: state.user.customer_id, include_groups: 1 })
    ]);

    const uniq = differenceBy(nonGroupUsers.results, groupUsers.results, 'id');

    setGroupUsers(groupUsers.results);
    setNonGroupUsers(uniq);

    setLoading(false);
  }

  async function addToGroup(userId) {
    setUserLoading(userId);

    await post('/relations', {
      from_id: 1,
      from_type: 'GROUP',
      to_id: userId,
      to_type: 'USER',
      relation_type_group: 'COMMON'
    });

    setUserLoading(0);
    fetchUsers();
  }

  async function removeFromGroup(userId) {
    setUserLoading(userId);
    const relation = await get('/relations', {
      from_id: 1,
      from_type: 'GROUP',
      to_id: userId,
      to_type: 'USER'
    });

    if (relation && relation.results && relation.results.length) {
      const toDelete = relation.results[0].id;
      await destroy(`/relations/${toDelete}`);

      setUserLoading(0);
      fetchUsers();
    }
  }

  if (loading) return <LoadingFrame />;

  return (
    <>
      <Space size="l">
        <FrameHeader title="Info" />
        <Frame>
          <Label text="Notification group name">
            <TextInput value="Default group" disabled required />
          </Label>
        </Frame>
      </Space>

      <Space size="l">
        <FrameHeader title="Users in this notification group" />
        <Frame padding={0}>
          {groupUsers.length ? (
            groupUsers.map(u => (
              <RowItem
                key={u.id}
                title={`${u.first_name} ${u.last_name}`}
                subtitle={u.email}
                right={
                  <Button
                    onClick={() => removeFromGroup(u.id)}
                    isLoading={userLoading === u.id}
                  >
                    Remove from group
                  </Button>
                }
              />
            ))
          ) : (
            <NoResults message="There are no users in this notification group yet." />
          )}
        </Frame>
      </Space>

      <FrameHeader title="Users not in this notification group" />
      <Frame padding={0}>
        {nonGroupUsers.length ? (
          nonGroupUsers.map(u => (
            <RowItem
              key={u.id}
              title={`${u.first_name} ${u.last_name}`}
              subtitle={u.email}
              right={
                <Button
                  onClick={() => addToGroup(u.id)}
                  isLoading={userLoading === u.id}
                >
                  Add to group
                </Button>
              }
            />
          ))
        ) : (
          <NoResults />
        )}
      </Frame>
    </>
  );
}
