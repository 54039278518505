import React, { useEffect, useState, useContext, useRef } from "react";
import { post, get, upload, put } from "../../api";
import { AuthContext } from "../../contexts/Auth";
import Frame from "../../components/Frame";
import moment from 'moment';
import FrameHeader from "../../components/FrameHeader";
import Breadcrumb from '../../components/Breadcrumb';
import Breadcrumbs from '../../components/Breadcrumbs';
import LoadingFrame from "../../components/LoadingFrame";
import Space from "../../components/Space";
import styles from "./DeviceReport.module.scss";
import { DateRange, DateRangePicker, Calendar, DefinedRange } from 'react-date-range';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { date } from '../../helpers/date';
import Label from '../../components/Label';
import InputRow from '../../components/InputRow';
import Button from '../../components/Button';
import SingleColumnPage from "../../components/SingleColumnPage/SingleColumnPage";
import Image from '../../components/Image';
import { useConfirm } from 'material-ui-confirm';
import { DateTime, Interval } from "luxon";
import { SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER } from '../../constants/roles';
import { isAuthorized, hasPermission } from "../../helpers/authorization";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  BarElement

} from 'chart.js';
import { Radar, Line, Bar } from 'react-chartjs-2';
export default function DeviceReport({ match: { params }, location: { search } }) {
  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    Title,
    BarElement
  );
  var milesConstant = 0.621371;
  const [pickerState, setPickerState] = useState(
    new Date
  );
  const transparentPixel = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [loadingReport, setLoadingReport] = useState(true);
  const [device, setDevice] = useState(null);
  const confirm = useConfirm();
  const [selectedDay, setSelectedDay] = useState(undefined);
  const [deviceSummary, setDeviceSummary] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [disabledDates, setDisabledDates] = useState([]);
  const [datesSummary, setDatesSummary] = useState([]);
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState([]);
  const tableRef = useRef()
  const tdRef = useRef()
  const [tableWidth, setTableWidth] = useState(undefined);
  const [tdWidth, setTdWidth] = useState(undefined);

  async function getDeviceDatesSummary() {
    setLoading(true);
    const res = await get(`/devices/${params.id}/dates_summary?timezone_offset=${(new Date()).getTimezoneOffset()}`);
    if (res && res[0]) {
      setDatesSummary(res);
      //find most recent month
      setSelectedMonthAndYear(res[0]?.year + '-' + res[0]?.months[0]);
      getRaportData(res[0]?.year + '-' + res[0]?.months[0]);
    }
    setLoading(false);
  }

  async function getRaportData(selectedMonthAndYear) {
    if (!selectedMonthAndYear) return
    setLoadingReport(true);
    var deviceSummary = await get(`/reports/${params.id}/?requestedDate=${selectedMonthAndYear}&timezone_offset=${(new Date()).getTimezoneOffset()}`);//${(new Date()).getTimezoneOffset()}`);
    if (deviceSummary?.reports) {
      setDeviceSummary(deviceSummary.reports);

      let _availableDates = []
      deviceSummary.reports.forEach(r => {
        _availableDates = _availableDates.concat(r?.availableDates);
      });
      setAvailableDates(
        _availableDates?.map(ad => {
          return new DateTime.fromSeconds(parseInt(ad?.slice(6, 16))).toJSDate()
        })
      )
    }
    setLoadingReport(false);
  }
  async function getDeviceInfo() {
    setLoading(true);
    const res = await get(`/devices/${params.id}`, { include_assets: '1' });
    if (res != null && res != undefined) {
      const { assets, ...device } = res;
      const asset = assets && assets.length > 0 && assets[0];
      device && setDevice(device);
    }

    setLoading(false);
  }
  useEffect(() => {

    getDeviceInfo();
    getDeviceDatesSummary();

    //getRaportData();
  }, []);

  useEffect(() => {
    if (!tableWidth && tableRef.current?.clientWidth) setTableWidth(tableRef.current?.clientWidth)
    if (!tdWidth && tdRef.current?.clientWidth) setTdWidth(tdRef.current?.clientWidth)
  });

  useEffect(() => {
    if (!availableDates || !availableDates?.length) return
    var getDaysArray = function (start, end) {
      for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
      }
      return arr;
    };

    if (availableDates?.length < 2) return
    const datesInRange = getDaysArray(availableDates[availableDates?.length - 1], availableDates?.[0])
    setDisabledDates(
      datesInRange.map(date => {
        if (availableDates.some((element) => element.getTime() === date.getTime())) return null
        else return date
      }).filter(Boolean)
    )
    setPickerState(availableDates?.[0])
    // console.log(datesInRange.map(date => {
    //   if (availableDates.some((element) => element.getTime() === date.getTime())) return null
    //   else return date
    // }).filter(Boolean))

  }, [availableDates]);

  const formatDate = (date) => {
    return DateTime.fromJSDate(date).toFormat('yyyy.MM.dd')
  }

  const addressesTimeline = (addresses) => {
    return <Accordion>
      <AccordionSummary>
        Show route
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ flexDirection: 'column', display: 'flex', flex: 1 }}>
          {addresses.map((a, i) => {
            return <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 0',
              borderTop: '0.5px #e7e7e7 solid'
            }}>
              <span>
                {(!a?.address &&
                  !a?.city &&
                  !a?.state &&
                  !a?.zip) ? "unknown address" : null}
                {a?.address ? `${a?.address}, `.replace(' ,', ',') : null}
                {a?.city ? `${a?.city}, ` : null}
                {a?.state} {a?.zip}
              </span>
              <span>
                {
                  new DateTime.fromISO(a?.created_time, { zone: 'UTC' }).toLocal().toFormat('h:mm a - dd/MM/yyyy')
                }
              </span>
            </div>
          })}
        </div>
      </AccordionDetails>
    </Accordion>

  }

  function changeReportDate(date) {
    setSelectedMonthAndYear(date);
    getRaportData(date)
  }
  function convertToDistance(input) {
    return (input?.trip_distance && input?.trip_distance !== 0 ? (((input?.trip_distance * milesConstant / 1000)).toFixed(2) + "mi") : (" "))
    //return (input?.trip_distance && input?.trip_distance !== 0 ? (((input?.trip_distance * milesConstant / 1000) ).toFixed(2) + "mi / " + ((input?.trip_distance / 1000) ).toFixed(2) + " km") : ("-"))
  }
  function convertToSpeed(input) {
    return (input?.trip_distance && input?.trip_distance !== 0 ? (((input?.trip_distance * milesConstant / input?.trip_duration) * 3.6).toFixed(0) + "mi/h") : (" "))
    //return (input?.trip_distance && input?.trip_distance !== 0 ? (((input?.trip_distance * milesConstant / input?.trip_duration) * 3.6).toFixed(0) + "mi/h " + (((input?.trip_distance  / input?.trip_duration) * 3.6).toFixed(2) + " km/h")) : ("-"))
  }
  function displayDatesSummary() {
    return (
      <div className={styles.center}>
        {datesSummary.map(data => {
          return (<>
            {data.months.toReversed().map(month => {
              return <Button onClick={() => changeReportDate(data.year + '-' + month)} disabled={selectedMonthAndYear === data.year + '-' + month}>{moment(month, 'M').format('MMMM')}</Button>;
            })}
          </>
          )
        })}</div>)
  }
  function displayTrip(trip) {
    const checkDate = DateTime.fromISO(JSON.parse(trip?.trip_addresses)?.[0]?.created_time, { zone: 'UTC' }).toLocal()
    const isValidDate = checkDate.isValid

    if (isValidDate) return (<>
      {/*<FrameHeader title={checkDate?.toFormat('DDDD')} />*/}
      <FrameHeader title={date(trip.start_date, "trip.start_date")} />
      <Frame padding={0}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}>
          <div className={styles.details}>
            <div className={styles.floatleft}>
              <ul>
                <Label text="Distance" theme="light">
                  {convertToDistance(trip)}
                </Label>
                <Label text="Duration" theme="light">
                  {`${(trip?.trip_duration / 60).toFixed(0)} min`}
                </Label>

                <Label text="Average speed" theme="light">
                  {convertToSpeed(trip)}
                </Label>
              </ul>
            </div>

          </div>
          <div className={styles.floatright} >
            <Image url={trip?.file?.url ?? transparentPixel}
              token={trip?.file?.url ? true : false}
              zoom={trip?.file?.url ? true : false}
            />
          </div>
        </div>
        {addressesTimeline(JSON.parse(trip?.trip_addresses))}
      </Frame>
    </>
    )
    else return null
  }
  function displayMonthSummary(data) {

    for (const week of data.weeks) {
      const fullWeek = []
      const firstDay = date(week.starting_date_week, 'untouched')
      for (let i = 0; i < 7; ++i) {
        fullWeek.push(
          firstDay.plus({ days: i }).weekdayShort
        )
      }
      if (week?.summary) {
        week.summary.fullWeek = fullWeek.toReversed()
      }
    }

    return (<> <FrameHeader title={"Summary"} > {isAuthorized(state.user.roles, [SYS_ADMIN, TENANT_ADMIN]) && (<Button icon={"refresh"} onClick={() => refreshReport(device.id)}>Refresh</Button>)}</FrameHeader><Frame>
      <h1>{moment(selectedMonthAndYear, "yyyy-M").format('MMMM')}</h1>
      <Space size="s" />
      {displaySummary(data.summary)}
      <div className={styles.floatright} >
        <Image
          url={data.summary?.file?.url ?? transparentPixel}
          token={data.summary?.file?.url ? true : false}
          zoom={data.summary?.file?.url ? true : false}
        />
      </div>
      <div style={{ width: '75%', margin: 'auto', }}>
        <Bar
          options={{
            plugins: {
              legend: {
                display: false
              }
            },
            responsive: true,
            aspectRatio: 4,
            scales: {
              x: {
                display: false,
                grid: {
                  display: false
                }
              },
              y: {
                display: false,
                grid: {
                  display: false
                }
              }
            },
          }}
          data={{
            labels: data.weeks.map(week => date(week.starting_date_week, 'untouched').toFormat("MM-dd") + " < - > " + date(week.ending_date_week, 'untouched').toFormat("MM-dd")),
            datasets: [{
              fill: true,
              data: data.weeks.map(week => parseFloat((week.summary?.trip_distance / 1000).toFixed(2)))
            }]
          }}
        />
      </div>

      <table>

        <tr>
          <th></th>
          {data.weeks.map(week => {
            return <th i={week.week}>{date(week.starting_date_week, 'untouched').toFormat("MM-dd") + " < - > " + date(week.ending_date_week, 'untouched').toFormat("MM-dd")}</th>
          })}
        </tr>
        <tr>
          <td>Distance</td>
          {data.weeks.map(week => {
            return <td>  {convertToDistance(week.summary)}</td>
          })}
        </tr>

      </table>


    </Frame>
      <Space size="s" />
    </>);
  }
  function displayWeekSummary(summary, trips) {
    const fullWeekTrips = []
    summary?.fullWeek.map((dayName) => {
      const trip = trips.find(t => date(t.start_date, 'untouched')?.weekdayShort === dayName)
      fullWeekTrips.push(trip ?? {})
    })

    return (<>
      <div className={styles.floatright} >
        {summary?.file?.url && <Image url={summary?.file?.url} zoom={true} />}
      </div>

      <div style={{
        opacity: (tableWidth && tdWidth) ? 1 : 0.5,
        width: (tableWidth && tdWidth) ?
          (tableWidth - tdWidth) - (tableWidth - tdWidth) / summary?.fullWeek?.length
          : undefined,
        marginRight: (tableWidth && tdWidth) ?
          ((tableWidth - tdWidth) / summary?.fullWeek?.length) / 2
          : undefined,
        marginLeft: 'auto'

      }}>
        {tableWidth && tdWidth ?
          <Line
            options={{
              plugins: {
                legend: {
                  display: false
                }
              },
              responsive: true,
              aspectRatio: 4,
              scales: {
                x: {
                  display: false,
                  grid: {
                    display: false
                  }
                },
                y: {
                  display: false,
                  grid: {
                    display: false
                  }
                }
              },
            }}
            data={{
              labels: summary?.fullWeek ?? Array.from(Array(7)),
              datasets: [{
                fill: true,
                data:
                  summary?.fullWeek.map((dayName) => {
                    const trip = trips.find(t => date(t.start_date, 'untouched')?.weekdayShort === dayName)
                    if (trip) return parseFloat((trip.trip_distance / 1000).toFixed(2))
                    return 0
                  }
                  ),
              }]
            }}
          />
          : null
        }
      </div>

      <table
        style={{ opacity: (tableWidth && tdWidth) ? 1 : 0.5 }}
        ref={tableWidth ? null : tableRef}>


        <tr>
          <th />
          {summary.fullWeek.map((day, i) => {
            return <th
              style={{
                width: (tableWidth && tdWidth) ?
                  (tableWidth - tdWidth) / summary?.fullWeek?.length
                  : undefined
              }}
              i={day}>{day}</th>
          })}
        </tr>
        <tr>
          <td>Distance</td>
          {fullWeekTrips.map(day => {
            return <td>  {convertToDistance(day)}</td>
          })}
        </tr>
        <tr>
          <td>Duration</td>
          {fullWeekTrips.map(day => {
            return <td>{(day?.trip_duration / 60).toFixed(0) > 0 ? `${(day?.trip_duration / 60).toFixed(0)} min` : ' '}</td> //hhh
          })}
        </tr>
        <tr>
          <td ref={tdWidth ? null : tdRef}>Avg speed</td>
          {fullWeekTrips.map(day => {
            return <td>{convertToSpeed(day)}</td>
          })}
        </tr>
      </table>

    </>);
  }
  async function refreshReport(device_id) {
    setLoading(true);
    const refreshRes = await post(`/reports/${device_id}/regenerate`);
    if (refreshRes) getDeviceDatesSummary()
    else setLoading(false)
  }
  function displaySummary(day) {
    return (<>
      <InputRow>
        <Label text="Distance" theme="light">
          {convertToDistance(day)}
        </Label>
        <Label text="Duration" theme="light">
          {(day?.trip_duration / 60).toFixed(0) > 0 ? `${(day?.trip_duration / 60).toFixed(0)} min` : ' '}
        </Label>
        <Label text="Average speed" theme="light">
          {convertToSpeed(day)}
        </Label>
      </InputRow>
    </>);
  }

  function displayMonth(data) {
    return (<>

      {displayMonthSummary(data)}
      <FrameHeader title={"Details"} />
      <Frame padding={0}>
        <br />
        {data.weeks.map((week, i) => {
          return (<div className={styles.week}>

            {i !== 0 && <><br /><br /><hr /><br /><br /></>}

            <div><h2>Week: {date(week.starting_date_week, "untouched").toUTC().toFormat('yyyy/MM/dd')} - {date(week.ending_date_week, "untouched").toUTC().toFormat('yyyy/MM/dd')}</h2> <br /></div>
            <div>Trips: {week.trips.length}</div>
            <br />
            <div>Week summary:
              {displaySummary(week.summary)}
            </div>
            <div>{displayWeekSummary(week.summary, week.trips)}</div>
            <br />
            <div>
              {week.trips.map(trip => { return <>{displayTrip(trip)}<br /></>; })}
            </div>
          </div>
          )
        })}
      </Frame>
    </>
    );
  }

  function breadcrumbs() {
    return <><Breadcrumb title="Report" to={'/reports'} /><Breadcrumb title={device?.name} /><Breadcrumb title={moment(selectedMonthAndYear, "yyyy-M").format('yyyy')} /><Breadcrumb title={moment(selectedMonthAndYear, "yyyy-M").format('MMMM')} /></>;
  }
  function displayReport() {
    return loadingReport ? (<LoadingFrame>Loading report</LoadingFrame>) : (
      deviceSummary && deviceSummary?.length && deviceSummary.map((selectedMonth, index) => {
        return (displayMonth(selectedMonth))
      }
      ))
  }
  function buttons() {
    return (<Button icon={"save"}>Export</Button>);
  }
  return (loading ? <LoadingFrame /> :
    <SingleColumnPage breadcrumbs={breadcrumbs} buttons={buttons} breadcrumbs_extra={displayDatesSummary}>


      {displayReport()}
    </SingleColumnPage>
  );
}
