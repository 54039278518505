import React, { useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import styles from './Modal.module.scss';

export default function Modal({ children, footer, header, small=false, simpleMode, simpleModeAssetsList }) {
  const ref = useRef(null);
  const isTouch = 'ontouchstart' in document.documentElement;

  useEffect(() => {
    disableBodyScroll(ref.current);

    return () => {
      enableBodyScroll(ref.current);
    };
  }, []);

  const classes = [styles.wrap, isTouch ? styles.touch : ''].join(' ');

  return (
    <>
      <div className={classes}>
        <div className={styles.overlay} style={simpleMode && { height: "100vh" }} />
        <div className={small ? (styles.framesmall) : (styles.frame)} style={simpleMode && { background: '#f8f8f8' }}>
          {simpleMode && header && <div style={simpleMode && { justifyContent: "center" }} className={styles.header}>{header}</div>}

          <div ref={ref} className={small == false && styles.scrollWrap} style={simpleMode && (header && { top: "65px" } || footer && { bottom: "65px" })} >
            <div style={simpleMode && { height: "100%" }} className={styles.innerFrame}>{children}</div>
          </div>
          {footer && <footer /*style={simpleMode && { justifyContent: "center" }}*/ className={styles.footer}>{footer}</footer>}
        </div>
      </div>
    </>
  );
}
