import React, { useEffect, useState, useContext } from "react";
import { Route, Link } from "react-router-dom";
import moment from 'moment';
import {
  fetchAsset,
  saveAsset,
  fetchRelation,
  fetchActivity,
  removeRelation,
  updateActivityFilters,
  updateActivityPage
} from "../../actions/Asset";
import {
  isolateMarker,
  toggleMap,
  showAllMarkers,
  setMarkers,
  displayGPSRecalibrateButton,
  //setSingleMarker,
  removeMarker,
  centerSmallMap,
  removeAllMarkers,
  resetMapState
} from "../../actions/Map";
import CheckboxWithLabel from '../../components/CheckboxWithLabel';
import { destroy, get, upload, put } from "../../api";
import { hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import history from "../../helpers/history";
import AssetContext from "../../contexts/Asset";
import MapContext from "../../contexts/Map";
import TypesContext from "../../contexts/Types";
import useThunk from "../../hooks/thunk";
import { AssetReducer, initialAssetState } from "../../reducers/Asset";
import { getOrientation, getXYZ, low, low_medium, medium, medium_high, high } from "../../helpers/orientation";
import InstallOnAsset from "../InstallOnAsset";
import AssetNote from "../AssetNote";
import AssetInspectionWizard from "../AssetInspectionWizard";
import AssetInspectionEdit from "../AssetInspectionEdit";
import AssetActivity from "../../components/AssetActivity";
import AssociatedDevice from "../../components/AssociatedDevice";
import Breadcrumb from "../../components/Breadcrumb";
import Breadcrumbs from "../../components/Breadcrumbs";
import Button from "../../components/Button";
import Frame from "../../components/Frame";
import FrameFooter from "../../components/FrameFooter";
import FrameHeader from "../../components/FrameHeader";
import Label from "../../components/Label";
import LoadingFrame from "../../components/LoadingFrame";
import MapToggleButton from "../../components/MapToggleButton";
import { useMediaQuery } from 'react-responsive'
import OrientationOptions from "../../components/OrientationOptions";
import PageHeader from "../../components/PageHeader";
import PageWrap from "../../components/PageWrap";
import Space from "../../components/Space";
import Image from "../../components/Image";
import styles from "./AssetDetail.module.scss";
import SliderCustom from '../../components/SliderCustom';

import RepairStart from "../RepairStart";
import { fetchDevice} from '../../actions/Device';
import AssetEventEdit from "../AssetEventEdit";
import AssetNoteEdit from "../AssetNoteEdit/AssetNoteEdit";
import AssetEdit from "../AssetEdit";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import queryString from "query-string";
import { useConfirm } from 'material-ui-confirm';
export default function AssetDetail({ match: { params }, location: { search }  }) {
  const queryParams = queryString.parse(search);
  const [currentDeviceStatus,setCurrentDeviceStatus] = useState('');
  const { state: currentUser } = useContext(AuthContext);
  const [state, dispatch] = useThunk(AssetReducer, initialAssetState);
  const [maintenanceState, setMaintenanceState] = useState(false);
  const types = useContext(TypesContext);
  const [, mapDispatch] = useContext(MapContext);
  const [initialLoad, setInitialLoad] = useState(true);
  const [assetForm, setAssetForm] = useState({});
  const [groups, setGroups] = useState([]);
  const [sites, setSites] = useState([]);
  const [assetStats, setAssetStats] = useState({});
  const [statsLoading, setStatsLoading] = useState(true);
  const [TresorTrackType, setTresorTrackType] = useState(1);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const [simpleMode, setSimpleMode] = useState(false);
  const confirm = useConfirm();
  const [noZIndex, setNoZIndex] = useState(false);
  const [resetMap, setResetMap] = useState(true);
  useEffect(() => {
   
    async function getAssetStats() {
      const res = await get(`/assets/${params.id}/stats`);
      res && setAssetStats(res);
      setStatsLoading(false);
    }

    dispatch(fetchAsset(params.id));
    getAssetStats();

  }, [params.id]);

  useEffect(() => {
    const { filters, page } = state.activity;
    dispatch(fetchActivity(params.id, filters, page));
  }, [
    state.relation.id,
    state.activity.filters,
    state.activity.page,
    state.asset,
    state.device,
  ]);
  function refreshActivity(refresh) {
    const { filters, page } = state.activity;
    dispatch(fetchActivity(params.id, filters, page));
    if (refresh == true) {
      dispatch(fetchAsset(params.id));
      dispatch(fetchDevice(state.device.id));
      const marker = [{ ...state.device, assets: [state.asset] }];
      mapDispatch(setMarkers(marker));
    }
    dispatch(updateActivityPage(page));
  }
  useEffect(() => {
    async function fetchGroups() {
      if (state.asset.customer_id) {
        const res = await get(`/users/${currentUser.user.user_id}/groups`);

        if (res && res.results && res.results.length) {
          setGroups(res.results);
        }
      }
    }
    async function fetchSites() {
      if (state.asset.customer_id) {
        const res = await get(`/assets/sites`);

        if (res && res.results && res.results.length) {
          setSites(res.results);
        }
      }
    }

    /** Set the initial values in the asset form */
    setAssetForm({
      name: state.asset.name,
      type: state.asset.assettype_id,
      serial: state.asset.serial,
      threshold_x: state.asset.threshold_x,
      threshold_y: state.asset.threshold_y,
      threshold_z: state.asset.threshold_z,
      assettype_id: state.asset.assettype_id,
      group_id: state.asset.group_id,
      site_id: state.asset.site_id,
      maintenance: state.asset.maintenance,
      sensitivity: SensitivityRetrieval(),
      deployed: state.asset.deployed,

    });
    //Set asset maintenance mode on/off
    setMaintenanceState(state.asset.maintenance);
    /** Get the groups for the asset's customer */
    fetchGroups();
    /*get sites */
    fetchSites();
    /** If active, center the map on this device */
    if (resetMap)
    {
      mapDispatch(resetMapState());
      mapDispatch(removeAllMarkers());
      setResetMap(false);
    }

    if (state.device.id && state.asset.id) {
      const { longitude, latitude, status } = state.device;
      setCurrentDeviceStatus(state.device.status);
      const marker = [{ ...state.device, assets: [state.asset] }];
      setTresorTrackType(state.device.devicetype_id);
      mapDispatch(setMarkers(marker));
      if (longitude && latitude) {
        mapDispatch(isolateMarker([longitude, latitude]));
        hasPermission(currentUser.user.perms, "ASSET_U") && (mapDispatch(displayGPSRecalibrateButton()));}

      else {
        if (status === 'OK' || status === 'WORK_IN_PROGRESS' || status === 'MOVEMENT') {
          var res = put(`/devices/${state.device.id}/gps/1`);
          if (res && !isMobile) {
            setNoZIndex(true);
          }
        }
      }
    } else {
      if (state.device.id) {
        mapDispatch(removeMarker(state.device.id));
      } else {
        //alert('no gps, center small');
        mapDispatch(centerSmallMap());
      }
    }
    /** False-out the initial load so the store's loading state can be used */
    setInitialLoad(false);
  }, [state.device, state.asset.id]);

  useEffect(() => {
    /** Get the relation, if exists */
    state.device.id && dispatch(fetchRelation(state.asset.id, state.device.id));
    //state.device.status!==undefined && setCurrentDeviceStatus(state.device.status);
    const timer = setInterval(() => {
      state.device.id && getDeviceState();
    }, 1000 * 5);
    return () => {
      clearInterval(timer);
    };
  }, [state.device,currentDeviceStatus]);


  async function getDeviceState() {
    var refreshedDevice = undefined;
    if (state.device !== undefined) {
      refreshedDevice = await get(`/devices/${state.device.id}`);
      if (refreshedDevice !== undefined && state.device !== undefined) {
        if (refreshedDevice.status !== currentDeviceStatus) {
          setCurrentDeviceStatus(refreshedDevice.status);
          const marker = [{ ...refreshedDevice, assets: [state.asset] }];
          mapDispatch(setMarkers(marker));
        }
      }
    }
  }

    function _deleteAssetConfirmation() {
    confirm({ 
      description: 'Are you sure you want to delete this asset?', 
      title: null, 
      dialogProps: { fullScreen: false }, 
      cancellationButtonProps: {color: "error", disableRipple: true},
      confirmationButtonProps: {color: "primary", disableRipple: true}
     })
      .then(() => {
        _deleteAsset();
      })
      .catch(() => { /* ... */ });
  }

  async function _deleteAsset() {
      await destroy(`/assets/${params.id}`);
      history.push("/assets_dashboard");
  }


  function changeMaintenanceState() {
    setAssetForm({ ...assetForm, maintenance: !maintenanceState });
    setMaintenanceState(!maintenanceState);
  }

  function simpleModeChangeMaintenanceStateConfirmation() {
    confirm({
      description: `Are you sure you want to ${maintenanceState ? "enable" : "disable"} impact notifications?`,
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        simpleModeChangeMaintenanceState();
      })
      .catch(() => { /* ... */ });
  }

  function simpleModeChangeMaintenanceState() {
    setAssetForm({ ...assetForm, maintenance: !maintenanceState });
    setMaintenanceState(!maintenanceState);
    async function simpleModeChangeMaintenanceStateCall() {
      await put(`/assets/${state.asset.id}/maintenance`, {
        enable: !maintenanceState
      });
    }
    simpleModeChangeMaintenanceStateCall();
  }

  async function _chooseFiles(e) {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    const files = (e.dataTransfer && e.dataTransfer.files) || (e.target && e.target.files);
    const valid = Array.from(files).every(f => validTypes.includes(f.type));

    if (valid) {
      const formData = new FormData();
      formData.set('entity_type', 'ASSET');
      formData.set('entity_id', params.id);
      formData.set('type', 'IMAGE');
      formData.append('file', files[0]);
      const res = await upload(formData);
      if (res) {
        window.location.reload();

      } else {
        alert('Please select valid photo file!');
      }
    }
  }

  const SensitivityRetrieval = () => {

    var finalSensitivity = 2; //default

    if (state.asset.threshold_x == state.asset.threshold_y) {
      finalSensitivity = state.asset.threshold_z;
    }

    if (state.asset.threshold_x == state.asset.threshold_z) {
      finalSensitivity = state.asset.threshold_y;
    }

    if (state.asset.threshold_y == state.asset.threshold_z) {
      finalSensitivity = state.asset.threshold_x;
    }

    switch (finalSensitivity) {
      case low: return 0; break;
      case low_medium: return 1; break;
      case medium: return 2; break;
      case medium_high: return 3; break;
      case high: return 4; break;
      default: return medium;
    }

  }


  function _renderContent() {
    const x = assetForm.threshold_x;
    const y = assetForm.threshold_y;
    const z = assetForm.threshold_z;

    function disableSimpleMode() {
      confirm({ 
        description: 'Are you sure you want to exit to Desktop Dashboard?', 
        title: null, 
        dialogProps: { fullScreen: false }, 
        cancellationButtonProps: {color: "error", disableRipple: true},
        confirmationButtonProps: {color: "primary", disableRipple: true}
       })
        .then(() => {
          setSimpleMode(false);
         
        })
        .catch(() => { /* ... */ });
    };

    const goToInspection = () =>{ 
      let path = `/assets/${params.id}/inspection`; 
      history.push(path);
    }

    function _sendGpsRecalibrationRequest(id) {
      var res = put(`/devices/${id}/gps/1`);
      if (res) {
        confirm({
          description: "GPS will be recalibrated within 24 hours.",
          title: null,
          dialogProps: { fullScreen: false },
          cancellationButtonProps: { color: "error", disabled: true },
          confirmationButtonProps: { color: "primary", disableRipple: true }
        })
          .then(() => {
          })
          .catch(() => { /* ... */ });
      }
    }

    return (
      <>
        

        <Space size="m">
         
          <Frame padding={0}>
          {state.device.id && (
          <Space size="m">
          
            <Frame padding={0}>
              <div className={styles.bannerImage}>
                {state.installPhoto.id && <Image url={state.installPhoto.url} zoom={true} />}
                {!state.installPhoto.id && <Image url="/img/TresorTrack.png" zoom={true} />}
              </div>
            </Frame>
          </Space>
        )}
            {hasPermission(currentUser.user.perms, "ASSET_U") ? (<div className={styles.editButton}><Link to={`/assets/${params.id}/edit`}><Button theme="">Edit</Button></Link></div>) : (null)}
            <>
              <div className={styles.detailsHead}>
                <Label text="Name" theme="light">
                  <div className={[styles.value, hasPermission(currentUser.user.perms, "ASSET_U") ? styles.admin : null].join(" ")}>{assetForm.name}</div>
                </Label>
                <Label text="Type" theme="light">
                  <div className={styles.value}>{types.assets && types.assets.find(x => x.id == state.asset.assettype_id).name}</div>
                </Label>
              </div>

             
            </>
          </Frame>
        </Space>

        <Space size="m">
        
          <Frame padding={0}>
            <div className={styles.notificationsControlWrapper}>
              <div className={styles._w70}>
                <CheckboxWithLabel
                  label={"Disable notifications"}
                  checked={maintenanceState}
                  onChange={() => changeMaintenanceState()}
                />
              </div>
              <div>
                <Button
                  theme="primary"
                  onClick={() => dispatch(saveAsset(assetForm))}
                  isLoading={state.saving}
                  disabled={(state.saving || !hasPermission(currentUser.user.perms, "ASSET_U"))}>
                  Save
                </Button>
              </div>
            </div>
          </Frame>
        </Space>



        {/*state.device.id && state.asset.id && (<Space size="m">
          <Space size="m" />
          <FrameHeader title="Sensitivity" />
          <Frame>
            
            <SliderCustom
              inputValue={assetForm.sensitivity}
              onChangeAction={(e, v) => { hasPermission(currentUser.user.perms, "ASSET_U") && setAssetForm({ ...assetForm, ...getXYZ(getOrientation(x, y, z), v) }) }}
            />
            {hasPermission(currentUser.user.perms, "ASSET_U") && (<FrameFooter>
              <Button
                theme="primary"
                onClick={() => dispatch(saveAsset(assetForm))}
                isLoading={state.saving}
                disabled={state.saving}
              >
                Save
              </Button>
            </FrameFooter>)}
          </Frame>
            </Space>)*/}


        <Space size="m">
        
          <AssociatedDevice
            link={false}
            assetId={state.asset.id}
            device={state.device}
            device_type={state.device.devicetype_id}
            deployed={assetForm.deployed}
            relation={state.relation}
            onRemove={(hasPermission(currentUser.user.perms, 'ADMIN') || hasPermission(currentUser.user.perms, 'ASSET_U')) && (relationId => {
              dispatch(removeRelation(state.asset.id, relationId));
              mapDispatch(showAllMarkers());
            })}
          />
        </Space>

        <Space size="l">
          <FrameHeader title="Activity" />
          <AssetActivity
            events={state.activity.events}
            loading={state.activity.loading}
            page={state.activity.page}
            perpage={500}
            total={state.activity.total}
            filters={state.activity.filters}
            setFilters={filters => dispatch(updateActivityFilters(filters))}
            setCurrentPage={page => dispatch(updateActivityPage(page))}
            onRefresh={refresh => refreshActivity(refresh)}
          />
        </Space>

        {hasPermission(currentUser.user.perms, "ASSET_D") && (
          <div className={styles.delete}>
            <Button theme="danger" icon="delete_forever" onClick={_deleteAssetConfirmation}>
              Delete asset
            </Button>
          </div>
        )}
      </>
    );
  }

  const assetsDashboardMediaQuery = useMediaQuery({ query: '(min-width: 1500px)' }) 
  return (
    <AssetContext.Provider value={[state, dispatch]}>
      {(!simpleMode || !isMobile) &&
      <PageHeader
        noZIndex={noZIndex ? true : false}
        right={
          <>
            <MapToggleButton text={false} onClick={() => mapDispatch(toggleMap())} />
            {/* {hasPermission(currentUser.user.perms, "INSPECTION_C") && state.asset.id && (
              <Link to={`/assets/${params.id}/inspection`}> <Button theme="primary" icon="add">New{!isMobile && " report"}</Button></Link>
            )} */}
          </>
        }
      >
        <Breadcrumbs>
          <Breadcrumb title="Assets" to={assetsDashboardMediaQuery ? "/assets_dashboard" : "/assets"} />
          <Breadcrumb title={state.asset && state.asset.name} tooltip={true} />
        </Breadcrumbs>
      </PageHeader>}
      <PageWrap>
        {initialLoad || state.loading > 0 ? <LoadingFrame /> : _renderContent()}
        {!initialLoad && (
          <>
            <Route
              path={`/assets/${params.id}/install`}
              exact
              component={InstallOnAsset}
            />
            <Route path={`/assets/${params.id}/note`} component={AssetNote} />
            <Route
              path={`/assets/:assetId/inspection/:id/edit`}
              component={AssetInspectionEdit} />
            <Route
              path={`/assets/:assetId/event/:id/edit`}
              component={AssetEventEdit} />
            <Route
              path={`/assets/:assetId/edit`}
              component={AssetEdit} />
            <Route
              path={`/assets/:assetId/note/:id/edit`}
              component={AssetNoteEdit} />
            <Route
              path={`/assets/:assetId/alarmaction/:id/`}
              component={RepairStart} />
            <Route
              path={`/assets/:assetId/start/:id/`}
              component={RepairStart} />
            <Route
              path={`/assets/${params.id}/inspection`}
              exact
              component={AssetInspectionWizard}
            />
          </>
        )}
      </PageWrap>
    </AssetContext.Provider>
  );
}
