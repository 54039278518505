import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';

import { AuthContext } from '../../contexts/Auth';
import { logOut } from '../../actions/Auth';
import { get, put } from '../../api';

import Breadcrumb from '../../components/Breadcrumb';
import Space from '../../components/Space';
import Button from '../../components/Button';
import FrameHeader from '../../components/FrameHeader';
import LoadingFrame from '../../components/LoadingFrame';
import UserInfoForm from '../../components/UserInfoForm';
import SingleColumnPage from '../../components/SingleColumnPage';
import ReleaseInfo from '../../components/ReleaseInfo';
import Frame from '../../components/Frame';

import styles from './Account.module.scss';

export default function Account() {
  const { state, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    var isApiSubscribed = true;
    async function fetchUser() {
      const res = await get(`/users/${state.user.user_id}`);
      const { customer, tenant, ...user } = res;
      if (isApiSubscribed) {
        setUser(user);
        setLoading(false);
      }
    }
    fetchUser();
    return () => {
      isApiSubscribed = false;
    };
  }, []);

  async function saveUser(user) {
    setSaving(true);
    const res = await put(`/users/${state.user.user_id}`, user);

    setUser(res);
    setSaving(false);
  }

  const breadcrumbs = () => <Breadcrumb title="Account" />;

  return loading ? (
    <LoadingFrame />
  ) : (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      <Space size="l">
        <FrameHeader title="Session" />
        <Frame>
          <div className={styles.session}>
            <p>
              You will automatically be logged out on{' '}
              <b>
                {moment
                  .utc(user.token_expiration)
                  .format('MMMM Do, YYYY [at] h:mma')}
              </b>
            </p>
            <Button theme="danger" onClick={() => dispatch(logOut())}>
              Log out
            </Button>
          </div>
        </Frame>
      </Space>

      <FrameHeader title="Account info" />
      <UserInfoForm
        state={user}
        saving={saving}
        onSave={user => saveUser(user)}
      />

      <ReleaseInfo />
    </SingleColumnPage>
  );
}
