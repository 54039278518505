import queryString from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { updateRelation } from "../../actions/Asset";
import { destroy, get } from "../../api";
import InstallOnAssetStep1 from "../../components/InstallOnAssetStep1";
import InstallOnAssetStep2 from "../../components/InstallOnAssetStep2";
import InstallOnAssetStep3 from "../../components/InstallOnAssetStep3";
import InstallOnAssetStep4 from "../../components/InstallOnAssetStep4";
import InstallOnAssetStep5 from "../../components/InstallOnAssetStep5";
import LoadingFrame from "../../components/LoadingFrame";
import Modal from "../../components/Modal";
import AssetContext from "../../contexts/Asset";
import { AuthContext } from "../../contexts/Auth";
import history from "../../helpers/history";
import { useConfirm } from 'material-ui-confirm';
export default function InstallOnAsset({ match: { params }, location: { search } }) {
  const queryParams = queryString.parse(search);
  const { state: currentUser } = useContext(AuthContext);
  const [state, dispatch] = useContext(AssetContext);
  const { customer_id } = state.asset;
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [switchOwner, setSwitchOwner] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(params.devId);
  const [device, setDevice] = useState({});
  const [removeOnCancel, setRemoveOnCancel] = useState(false);
  const confirm = useConfirm();
  function isDeviceActivated(deviceId) {
    return devices.filter(x => x.id === Number(deviceId))[0].activation_time !== undefined;
  }
  function returnDeviceSelected(deviceId) {
    return devices.filter(x => x.id === Number(deviceId))[0];
  }
  function returnDeviceSelectedBySerial(serial) {
    return devices.filter(x => x.serial_number === Number(serial))[0];
  }
  async function fetchInactiveDevices(assign = false) {
    setLoading(true);
    const devices = await get(`/deviceslist`, {
      active: "false",
      customer_id: customer_id
    });
    setDevices(devices);
    setLoading(false);
  }
  useEffect(() => {
    fetchInactiveDevices();

    let roc = queryParams.roc; // remove on cancel
    roc && roc !== '' && setRemoveOnCancel(true);

  }, []);
  function deleteAssetConfirmation() {
    confirm({
      description: 'Are you sure you want to cancel? The TresorTrack device will not be activated.',
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        deleteAsset();
      })
      .catch(() => { /* ... */ });
  };

  async function deleteAsset() {
    await destroy(`/assets/${state.asset.id}`);
    if (window.screen.width < 1000) {
      history.push("/assets");
    } else {
      history.push("/assets_dashboard");
    }
  }

  function _renderLoading() {
    return (
      <Modal>
        <LoadingFrame />
      </Modal>
    );
  }

  function quitSilent() {
    confirm({
      description: 'Are you sure you want to cancel?',
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        history.push(`/assets/${state.asset.id}`);
      })
      .catch(() => { /* ... */ });
  }

  async function determineNextStep(deviceId, serial) {
    setLoading(true);
    const devices = await get(`/deviceslist`, {
      active: "false",
      customer_id: customer_id
    });
    setDevices(devices);

    
    if (deviceId != null) {
      setSelectedDevice(deviceId);
      var dev = devices.filter(x => x.id === Number(deviceId))[0];
      dev && setDevice(dev) && setSwitchOwner(dev.customer_id !== currentUser.user.customer_id);
    } else {
      var dev = devices.filter(x => x.serial_number === Number(serial))[0]
      dev && setDevice(dev);
      setSelectedDevice(dev.id);
      setSwitchOwner(dev.customer_id !== currentUser.user.customer_id);
    }

    setPage(3);
    setLoading(false);
  }
  function _renderPage() {
    switch (page) {
      case 1:
        return (
          <InstallOnAssetStep1
            asset={state.asset}
            devices={devices}
            loading={loading}
            back={() => removeOnCancel ? deleteAssetConfirmation() : quitSilent()}
            //activation_feedback={fetch =>{fetchInactiveDevices()}}
            next={(deviceId, serial) => {
              determineNextStep(deviceId, serial);
            }}
          />
        );

      case 2:
        return (
          <InstallOnAssetStep2
            asset={state.asset}
            back={() => setPage(1)}
            next={() => setPage(3)}
          />
        );

      case 3:
        return (
          <InstallOnAssetStep3
            switch_owner={switchOwner}
            back={() => setPage(2)}
            assetId={state.asset.id}
            selectedDevice={selectedDevice}
            next={relation => {
              dispatch(updateRelation(state.asset.id, relation));
              setPage(4);
            }}
          />
        );

      case 4:
        return <InstallOnAssetStep4 assetId={state.asset.id} />;

      case 5:
        //activation
        return (<InstallOnAssetStep5
          deviceInput={device}
          back={() => setPage(1)}
          activation_feedback={fetch => { fetchInactiveDevices() }}
          next={() => {
            setPage(2);
          }}
        />);

      default:
        return null;
    }
  }

  return state.loading > 0 ? _renderLoading() : _renderPage();
}
