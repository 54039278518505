import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { post, put } from '../../api';
import UserModel from '../../models/user';
import { AuthContext } from '../../contexts/Auth';
import CustomerContext from '../../contexts/Customer';
import { CUSTOMER_USER } from '../../constants/roles';
import { normalizePermissions } from '../../helpers/user';

import UserInfoForm from '../../components/UserInfoForm';
import FrameHeader from '../../components/FrameHeader';

// import styles from './NewCustomerUser.module.scss';

export default function NewCustomerUser({ match: { params } }) {
  const { state } = useContext(AuthContext);
  const { customer } = useContext(CustomerContext);
  const [saving, setSaving] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const createUser = async (user, perms) => {
    setSaving(true);

    const res = await post('/users', {
      ...user,
      tenant_id: customer.tenant_id,
      customer_id: state.user.customer_id,
      authority: CUSTOMER_USER
    });

    await put(`/users/${res.id}/permissions`, {
      permissions: normalizePermissions(perms)
    });

    await post('/users/sendActivationMail', {
      email: res.email
    });

    setRedirect(`/customer/users`);
  };

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <FrameHeader title="New user" />
      
      <UserInfoForm
        state={UserModel}
        saving={saving}
        onSave={createUser}
        isNew
      />
    </>
  );
}
