import React, { useState, useEffect } from 'react';
import Button from  '../../components/Button';
import {
  OK,
  MOVEMENT,
  BATTERY_LOW,
  TEMPERATURE_LOW,
  TEMPERATURE_HIGH,
  BATTERY_CRITICAL,
  SIGNALSTRENGTH_LOW,
  COMMUNICATION_ERROR,
  SIGNALSTRENGTH_CRITICAL,
  WORK_IN_PROGRESS,
  INACTIVE,
  READY,
} from '../../constants/statuses';
import Label from '../../components/Label/Label';
import CheckboxWithLabel from '../../components/CheckboxWithLabel';
import styles from './StatusFilters.module.scss';
import LoadingFrame from '../LoadingFrame';
export default function StatusFilters({ onChange,reset,onRefresh,dataLoading,unselectAll=true}) {
  const [loading,setLoading]= useState(true);
  const [filters, setFilters] = useState({
    ok: true,
    warning: true,
    critical: true,
    impacted: true,
    in_progress:true,
    ready:true,
  });
  var resetFilter = false;
  async function unselectSelection() {
    setLoading(true);
    setFilters({
      ok:false,
      warning:false,
      critical:false,
      impacted:false,
      in_progress:false,
      ready:false
    })
    resetFilter = true;

    let statuses = `${INACTIVE}`;
    onChange(statuses);
   // onRefresh();
   setLoading(false);
  }
  async function resetSelection() {
    setLoading(true);
    setFilters(filters.ok = true);
    setFilters(filters.warning = true);
    setFilters(filters.critical = true);
    setFilters(filters.impacted = true);
    setFilters(filters.in_progress = true);
     setFilters(filters.inactive = true);
     setFilters(filters.ready = true);
    let statuses = '';
    statuses += `${OK},`;
    statuses += `${SIGNALSTRENGTH_LOW},${TEMPERATURE_LOW},${BATTERY_LOW},`;
    statuses += `${SIGNALSTRENGTH_CRITICAL},${TEMPERATURE_HIGH},${BATTERY_CRITICAL},${COMMUNICATION_ERROR},`;
    statuses += `${MOVEMENT},`;
    statuses += `${WORK_IN_PROGRESS},`;
    statuses += `${READY},`;
    //statuses += `${INACTIVE},`;
    const all = Object.values(filters).every(f => f);
    onChange(all ? '' : statuses.slice(0, -1));
    onRefresh();
    setLoading(false);
  }
  useEffect(() => {
    let statuses = '';

    if (resetFilter !== true) {
      if (filters.ok && filters !== false) {
        statuses += `${OK},`;
      }

      if (filters.warning && filters !== false) {
        statuses += `${SIGNALSTRENGTH_LOW},${TEMPERATURE_LOW},${BATTERY_LOW},`;
      }

      if (filters.critical && filters !== false) {
        statuses += `${SIGNALSTRENGTH_CRITICAL},${TEMPERATURE_HIGH},${BATTERY_CRITICAL},${COMMUNICATION_ERROR},`;
      }

      if (filters.impacted && filters !== false) {
        statuses += `${MOVEMENT},`;
      }
      if (filters.in_progress && filters !== false) {
        statuses += `${WORK_IN_PROGRESS},`;
      }
      if (filters.inactive && filters !== false) {
        //statuses += `${INACTIVE},`;
      }
      if (filters.ready && filters !== false) {
        statuses += `${READY},`;
      }

      const all = Object.values(filters).every(f => f);
      if (statuses !== '') {
        onChange(all ? '' : statuses.slice(0, -1));
      }
    } else {
      setFilters(filters.ok = false);
      setFilters(filters.warning = false);
      setFilters(filters.critical = false);
      setFilters(filters.impacted = false);
      setFilters(filters.in_progress = false);
      setFilters(filters.inactive = false);
      setFilters(filters.ready = false);
      resetFilter = false;
      onChange('INACTIVE');

    }
/*if(filters.ok == false && filters.warning==false && filters.critical == false && filters.impacted==false && filters.in_progress==false &&filters.ready==false){
  statuses += `${OK},`;
  setFilters({ ...filters, ok: true });
  //onRefresh();
}*/
 
    setLoading(false);
  }, [filters]);

  return (loading ? (<LoadingFrame/>):(

    <>
    
    {reset && <div className={styles.spacer}> <Button disabled={dataLoading} size='s' theme='secondary' onClick={e => resetSelection()}>Select all</Button>  <Button size='s' disabled={dataLoading} theme='secondary' onClick={e => unselectSelection()}>Select none</Button> </div>}
   
    {reset==null && <Label text="Statuses" />}
    
      <div className={styles.filter}>
        <CheckboxWithLabel
          label="OK"
          checked={filters.ok}
          onChange={e => setFilters({ ...filters, ok: e.target.checked })}
          disabled={dataLoading}
        />

        <div className={`${styles.status} ${styles.ok}`} />
      </div>
      <div className={styles.filter}>
        <CheckboxWithLabel
          label="Ready"
          checked={filters.ready}
          onChange={e => setFilters({ ...filters, ready: e.target.checked })}
          disabled={dataLoading}
        />

        <div className={`${styles.status} ${styles.ready}`} />
      </div>
      <div className={styles.filter}>
        <CheckboxWithLabel
          label="Work in progress"
          checked={filters.in_progress}
          onChange={e => setFilters({ ...filters, in_progress: e.target.checked })}
          disabled={dataLoading}
        />

        <div className={`${styles.status} ${styles.inprogress}`} />
      </div>
      <div className={styles.filter}>
        <CheckboxWithLabel
          label="Movement detected"
          checked={filters.impacted}
          onChange={e => setFilters({ ...filters, impacted: e.target.checked })}
          disabled={dataLoading}
        />

        <div className={`${styles.status} ${styles.impacted}`} />
      </div>
      <div className={styles.filter}>
        <CheckboxWithLabel
          label="Warnings"
          checked={filters.warning}
          onChange={e => setFilters({ ...filters, warning: e.target.checked })}
          disabled={dataLoading}
        />

        <div className={`${styles.status} ${styles.warning}`} />
      </div>

      <div className={styles.filter}>
        <CheckboxWithLabel
          label="Critcal warnings"
          checked={filters.critical}
          onChange={e => setFilters({ ...filters, critical: e.target.checked })}
          disabled={dataLoading}
        />

        <div className={`${styles.status} ${styles.critical}`} />
      </div>

      
      
      {/*<div className={styles.filter}>
        <CheckboxWithLabel
          label="Inactive"
          checked={filters.inactive}
          onChange={e => setFilters({ ...filters, inactive: e.target.checked })}
        />

        <div className={`${styles.status} ${styles.inactive}`} />
  </div>*/}
    </>
  ));
}
