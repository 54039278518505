

export const low = 1.5;
export const low_medium =  1.3;
export const medium = 1.16;
export const medium_high = 1.05;
export const high = 0.9;


export function getXYZ(orientation,sensitivity) {
var desiredThreshold = medium;
  switch (sensitivity) {
    case 0:
      desiredThreshold = low;
      break;
    case 1:
      desiredThreshold = low_medium;
      break;
    case 2:
      desiredThreshold = medium;
      break;
    case 3:
      desiredThreshold = medium_high;
      break;
    case 4:
      desiredThreshold = high;
      break;
    default:
      break;
}

  switch (orientation) {
    case "flat":
      return {
        threshold_x: 0.57,
        threshold_y: 0.57,
        threshold_z: desiredThreshold,
        sensitivity:sensitivity,
      };

    case "vertical":
      return {
        threshold_x: desiredThreshold,
        threshold_y: 0.57,
        threshold_z: 0.57,
        sensitivity:sensitivity,
      };

    case "horizontal":
      return {
        threshold_x: 0.57,
        threshold_y: desiredThreshold,
        threshold_z: 0.57,
        sensitivity:sensitivity,
      };

    default:
      return {
        threshold_x: 0,
        threshold_y: 0,
        threshold_z: 0,
        sensitivity:2,
      };
  }
}

export function getOrientation(x, y, z) {
  x = parseFloat(x);
  y = parseFloat(y);
  z = parseFloat(z);

  if (x === 0.57 && y === 0.57){// && z === 1.12) {
    return "flat";
  }

  if (/*x === 1.12 && */y === 0.57 && z === 0.57) {
    return "vertical";
  }

  if (x === 0.57 /*&& y === 1.12*/ && z === 0.57) {
    return "horizontal";
  }

  return;
}
