import React, { useEffect, useState, useContext } from 'react';
import MapContext from '../../contexts/Map';
import { get } from "../../api";
import Loading from '../Loading';
import styles from './HistoryMap.module.scss';
const google = window.google;
const defaultBonds = { lat: 20, lng: -4 };
const defaultBondsSmallMap = { lat: 0.0, lng: 10.2 };

export default function HistoryMap({ reportId, deviceId, customBackButton = false }) {
  const [state, dispatch] = useContext(MapContext);
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState(null);

  useEffect(() => {
    const init = new google.maps.Map(document.getElementById("map"), {
      center: state.centerSmallMap === false ? new google.maps.LatLng(defaultBonds.lat, defaultBonds.lng) : (new google.maps.LatLng(defaultBondsSmallMap.lat, defaultBondsSmallMap.lng)),
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      gestureHandling: 'greedy',
      mapId: '67fa8b61f3dfc470',
      panControl: false,
      panControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      rotateControl: false,
      rotateControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoomControl: false,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.SMALL
      },

      mapTypeControl: false,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP,
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        mapTypeIds: ["roadmap", "satellite"],
      },
      streetViewControl: false,
      streetViewControlOptions: {
      },
    });

    setMap(init);

  }, []);

  useEffect(() => {
    if (map) {
      try {
        var routeBounds = new google.maps.LatLngBounds();
        get(`/reports/${deviceId}/${reportId}`, {
          timezone_offset: new Date().getTimezoneOffset(),
        }).then(res => {
          if (!res || !res?.trip_map) {
            setLoading(false)
            return
          }

          var singleLocation = true
          if (!JSON.parse(res?.trip_map).length) {
            setLoading(false)
            // adjust map / add infobox
            return console.log("no data")
          }
          JSON.parse(res?.trip_map).forEach(ar => {
            const parsed = JSON.parse(ar)
            parsed.geocoded_waypoints = {}
            parsed.request = {}

            if (parsed?.routes) {
              if (parseInt(parsed?.routes?.[0]?.duration?.replace('s','')) > 0) singleLocation = false
              parsed.routes[0]?.legs.forEach((leg, i) => {
                if (leg?.steps?.length > 1) singleLocation = false
                leg.steps.forEach((step, i) => {
                  if (step?.startLocation?.latLng?.latitude && step?.startLocation?.latLng?.longitude) {
                    routeBounds.extend({ lat: step?.startLocation?.latLng?.latitude, lng: step?.startLocation?.latLng?.longitude });
                  }
                  step.path = google.maps.geometry.encoding.decodePath(step.polyline.encodedPolyline);
                });
              });

              const dr = new google.maps.DirectionsRenderer();
              dr.setMap(map);
              dr.setOptions({ suppressMarkers: true, preserveViewport: true });
              dr.setDirections(parsed)
            }
            else {
              singleLocation = false
            }
          });

          if (singleLocation) {
            var marker = new google.maps.Marker({
              position: routeBounds.getCenter(),
            });
            map.setZoom(15)
            marker.setMap(map);
          }
          else map.fitBounds(routeBounds);
         
          map.setCenter(routeBounds.getCenter());
          setLoading(false)
        }
        );
      }

      catch (e) {
        setLoading(false)
        console.log(e)
      }

    }

  }, [map]);

  return (
    <>
      <div id="map" className={styles.mapFull}></div>

      {loading && (
        <div className={styles.loading}>
          <Loading color="gray" size={36} />
        </div>
      )}
    </>
  );
}