import React, { useEffect, useState, useContext } from "react";
import { createTheme } from 'baseui';
import { PinCode } from "baseui/pin-code";
import { get, post } from "../../api";
import LoadingFrame from "../../components/LoadingFrame";
import Modal from "../../components/Modal";
import styles from "./DeviceInstallRedirect.module.scss";
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import Space from '../../components/Space';
import Icon from '../../components/Icon';
import DeviceCard from '../../components/DeviceCard';
import Image from '../../components/Image';
import { Redirect } from 'react-router-dom';
import InstallDeviceStep1 from "../../components/InstallDeviceStep1";
import InstallDeviceStep2 from "../../components/InstallDeviceStep2";
import InstallDeviceStep3 from "../../components/InstallDeviceStep3";
import TypesContext from '../../contexts/Types';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider, styled } from 'baseui';
import { hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import { useConfirm } from 'material-ui-confirm';

export default function DeviceInstallRedirect({ computedMatch: { params } }) {
  const { state: currentUser } = useContext(AuthContext);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [types, setTypes] = useState({});
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [switchOwner, setSwitchOwner] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [step, setStep] = useState(0);
  const [activationFailureCounter, setActivationFailureCounter] = useState(0);
  const [proceed, setProceed] = useState(false);
  const [alreadyActivated, setAlreadyActivated] = useState(false);
  const ACTIVATE = 0;
  const INSTALL_ON_ASSET = 1;
  const ORIENTATION = 2;
  const PHOTO = 3;
  const COMPLETE = 4;
  const { serialNumber } = params;
  const [checkingPin, setCheckingPin] = useState(false);
  const [device, setDevice] = useState(null);
  const [displayDevice, setDisplayDevice] = useState(null);
  const [error, setError] = useState(false);
  const [values, setValues] = useState([
    "",
    "",
    "",
    ""
  ]);
  const [pinRequired, setPinRequired] = useState(true);
  const [allowedUser, setAllowedUser] = useState(false);
  const engine = new Styletron();

  const primitives = {
    inputFillActive: '#a9a9a9',
    inputFill: '#d9d9d9',
  };
  const overrides = {
    colors: {
      inputFillActive: primitives.inputFillActive,
      inputFill: primitives.inputFill,
    },
  };
  const pinTheme = createTheme(primitives, overrides);
  const [activating, setActivating] = useState(false);
  const [activated, setActivated] = useState(false);
  const confirm = useConfirm();
  useEffect(() => {

    async function fetchDevice() {
      const [assetTypes, deviceTypes] = await Promise.all([
        get('/assettypes'),
        get('/devicetypes')
      ]);

      setTypes({
        assets: assetTypes.results,
        devices: deviceTypes.results
      });

      const autoProvision = await get(`/getautoprovision/${serialNumber}`);
      setPinRequired(autoProvision);
      autoProvision===false && setProceed(true);
      const devices = await get(`/deviceredirect`, {
        count: 1,
        serial_number: serialNumber,
        include_assets: true,
      });

      if (devices && devices.results && devices.results.length) {

        const _device = devices.results[0];
        _device && setDisplayDevice(_device);
        const assets = await get(`/assets`, {
          active: "false",
          _device
        });

        setAssets(assets.results);
        const activation_date = _device.activation_time;
        if (activation_date === undefined) {
          setStep(0)
          setSwitchOwner(_device.customer_id !== currentUser.user.customer_id);
          if(autoProvision===false){
            setDevice(_device);
          }
        
        } else {
          setAlreadyActivated(true);
          setActivated(true);
          setProceed(true);
          setDevice(_device);
          setSwitchOwner(_device.customer_id !== currentUser.user.customer_id);
          setStep(1);
        }

        if (_device.active === true && _device.assets !== undefined) {
          var asset = _device.assets[0];
          if (asset !== undefined && asset.id !== 0) {
            setRedirect(`/assets/${asset.id}?qr=true`);
          }
        }
      } else {
        /** Couldn't find a device with the provided serial number */
        setRedirect("/");
      }
      (hasPermission(currentUser.user.perms, "ADMIN") || hasPermission(currentUser.user.perms, "ASSET_U")) && setAllowedUser(true);
      setLoading(false);
    }

    fetchDevice();
  }, []);

  function markError() {
    setActivationFailureCounter(activationFailureCounter + 1)
    setError(true)
  }

  function nextStep(step) {
    setLoading(true);
    setStep(step);
    setLoading(false);
    if (step == 4 && alreadyActivated == false) {
      activateTheDevice();
    }
  }
  function prevStep() {
    setLoading(true);
    if (step <= 3) {
      setStep(step - 1);
    }
    setLoading(false);
  }
  async function activateTheDevice() {
    setActivating(true);
    const device = await post(`/device/${serialNumber}/activate/`, {
      activation_code: values.toString(),
      require_pin:pinRequired,
    });
    device && device.active == true ? (
      device.assets && device.assets[0] && setActivated(true) && setSelectedAsset(device.assets[0]))
      :
      (markError())

    setActivating(false);
  }

  async function changeValues(input) {
    setValues(input);
    setError(false);
    if (input[0] !== "" && input[1] !== "" && input[2] !== "" && input[3] !== "") {
      setCheckingPin(true);
      const device = await post(`/device/${serialNumber}/pin/`, {
        activation_code: input.toString()
      });
      if (device !== undefined && device.id !== 0) {
        setDevice(device);
        setProceed(true);
      } else {
        setValues([
          ,
          ,
          ,

        ])
        setError(true)
        setValues([
          "",
          "",
          "",
          ""
        ])
      }
      setCheckingPin(false);
    }
  }

  function quit() {
    confirm({ 
      description:  'Are you sure you want to cancel? The TresorTrack device will not be activated.', 
      title: null, 
      dialogProps: { fullScreen: false }, 
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
     })
      .then(() => {
        window.location.replace("/")
      })
      .catch(() => { /* ... */ });
  }

  function quitActivated() {
    confirm({
      description: 'Are you sure you want to cancel the device installation?',
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        setLoading(true);
        setRedirect("/");
        setLoading(false);
        window.location.replace("/");
      })
      .catch(() => { /* ... */ });
  }

  function renderInput() {
    return (<>
      <Space size="mobile" />
      <div className={styles.wrap}>
        <h3>Enter the 4-digit PIN code that is located on the TresorTrack device.</h3>
      </div>
      <Space size="s" />
      <div className={styles.inputWrap}>
        <StyletronProvider value={engine}>
          <BaseProvider theme={pinTheme}>
            <PinCode
              values={values}
              onChange={({ values }) => changeValues(values)}
              disabled={checkingPin || proceed}
              clearOnEscape
              placeholder=""
              autoFocus
            />
          </BaseProvider>
        </StyletronProvider>
      </div></>);
  }

  function _renderFooter() {
    return (allowedUser == true ? (step < 4 && (
      <>
        <Button theme="secondary" onClick={() => alreadyActivated ? quitActivated() : quit()}>Cancel</Button>
        <Button theme="primary" onClick={() => nextStep(step + 1)} disabled={!proceed}>Next</Button>
      </>)) : (<Button theme="primary" onClick={() => (setLoading(true),
        setRedirect("/"),
        setLoading(false))}>Close</Button>)
    );
  }

  function renderContent() {
    if (allowedUser === false) {
      return <Space size="s" >
        <div className={styles.wrap}>
          <div className={styles.error}>
            <Icon name="error" size={32} />
          </div>
          <h1>User not authorized</h1>
          <p className={styles.body}>
            Your user has no permission to activate and install devices on assets. Please contact with administrator.
          </p>
        </div>
      </Space>;
    }

    switch (step) {
      case ACTIVATE:
        return (<>
          {alreadyActivated == false ? (<>
            <Space size="s">
              <h2>Step 1: Activate your device</h2>
            </Space>
            {displayDevice && <DeviceCard device={displayDevice} />}

            {pinRequired ? (
              <>
                <Space size="s" />
                <Space size="s">
                  <div className={styles.bannerImage}>
                    <Image url={"/img/TresorTrack.png"} token={false} zoom={true} />
                  </div>
                </Space>
                <Space size="s" />
                <Space size="s" >{renderInput()}</Space>
                <Space size="s" >
                  {checkingPin && <LoadingFrame />}
                  <div className={styles.wrap}>
                    {device && device.id !== 0 && (
                      <>
                        <div className={styles.icon}>
                          <Icon name="check_circle" size={32} />
                        </div>
                        <h1>PIN correct</h1>
                        <p className={styles.body}>PIN matches our records. Let's move to the next step.</p>
                      </>)}
                    {error && (
                      <>
                        <div className={styles.error}>
                          <Icon name="error" size={32} />
                        </div>
                        <h1>Wrong PIN</h1>
                        <p className={styles.body}>Looks like you typed in the wrong PIN code.</p>
                      </>)}
                  </div>
                </Space>

              </>
            ) :
              (<>
              <Space size="l" />
              <Space size="l" /> 
                <Space size="s">
                  <div className={styles.wrap}>
                    <div className={styles.wrapvert}>
                      <div className={styles.icon}>
                        <Icon name="check_circle" size={22} />
                      </div>
                      <h1 className={styles.margin}>Get ready for activation</h1>
                    </div>
                    <p className={styles.body}>Getting ready to activate your device. Proceed to the next step to continue the activation process.</p>
                  </div>
                </Space></>)
            }
          </>) : (
            <>
              <Space size="s">
                <h2>Device already activated</h2>
              </Space>
              {displayDevice && <DeviceCard device={displayDevice} />}
              <Space size="l" />
              <Space size="l" />
              <Space size="s">
                <div className={styles.wrap}>
                  <h1>Device already activated</h1>
                  <p className={styles.body}>Looks like your device is already activated.Press next button to continue.</p>
                </div>
              </Space>
            </>
          )}
        </>);
        break;

      case INSTALL_ON_ASSET:
        return (<>
          {assets && device && types && (<InstallDeviceStep1
            device={device}
            assets={assets}
            assetTypes={types}
            loading={loading}
            step={alreadyActivated}
            back={() => prevStep()}
            next={asset => {
              if (typeof asset === "string") {
                setSelectedAsset(assets.find(a => a.id === parseInt(asset)));
              } else {
                setSelectedAsset(asset);
              }
              nextStep(step + 1);
            }}
          />)}
        </>);
        break;

      case ORIENTATION:
        return (<InstallDeviceStep2
          selectedAsset={selectedAsset}
          step={alreadyActivated}
          back={() => prevStep()}
          next={() => nextStep(3)}
        />);
        break;

      case PHOTO:
        return (<InstallDeviceStep3
          back={() => prevStep()}
          deviceId={device.id}
          step={alreadyActivated}
          switch_owner={switchOwner}
          selectedAsset={selectedAsset}
          next={relation => {
            nextStep(step + 1);
          }}
        />)
        break;

      case COMPLETE:
        return (
          activating ? (<div className={styles.wrap}>
            <div className={styles.icon}>
              <LoadingFrame />
            </div>

            <h1>Activating...</h1>
            <p className={styles.body}>
              We are activating your device now, please wait.
        </p>
          </div>) : (activated == true ? (
            <div className={styles.wrap}>
              <div className={styles.icon}>
                <Icon name="check_circle" size={32} />
              </div>

              <h1>All set</h1>
              <p className={styles.body}>
                The device is now running through a brief calibration process and will
                begin monitoring shortly.
          </p>
              <Space size="l" />
              <Link to={`/assets/${selectedAsset.id}`}>
                <Button theme="primary">Finish</Button>
              </Link>
            </div>) : (

            activationFailureCounter <= 2 ? (<>
              <div className={styles.wrap}>
                <div className={styles.error}>
                  <Icon name="error" size={32} />
                </div>

                <h1>Device activation failed</h1>
                <p className={styles.body}>
                  Whoops. Something went wrong. Press activate to try again.
            </p>
                <Space size="l" />
                <Button onClick={() => activateTheDevice()} theme="primary">Activate</Button>
              </div>
            </>) : (<>
              <div className={styles.wrap}>
                <div className={styles.error}>
                  <Icon name="error" size={32} />
                </div>

                <h1>Device activation failed</h1>
                <p className={styles.body}>
                  Looks like something went wrong with the activation process.
                      <br />
                      Contact the TresorTrack support team to troubleshoot this issue.
                      <br /><br />
                      Call 650-495-8758 or e-mail support@tresortrack.com
          </p>
                <Space size="l" />
                <Button onClick={() => window.location.replace("/")} theme="primary">Finish</Button>
              </div>
            </>)

          )));
        break;
    }
  }
  return loading ? <LoadingFrame /> : redirect !== "" ? (<Redirect to={redirect} noThrow />) :
    (<TypesContext.Provider value={types}>
      <Modal footer={_renderFooter()}>
        {renderContent()}
      </Modal>
    </TypesContext.Provider>
    );
}
