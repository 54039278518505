import React, { useContext, useRef, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import queryString from "query-string";

import validate from "../../helpers/validate";
import { AuthContext } from "../../contexts/Auth";
import { authWithCredentials } from "../../actions/Auth";

import Space from "../../components/Space";
import Button from "../../components/Button";
import Label from "../../components/Label";
import TextInput from "../../components/TextInput";
import LoginFrame from "../../components/LoginFrame";
import AlertBanner from "../../components/AlertBanner";

import styles from "./LogIn.module.scss";

export default function LogIn({ location: { search } }) {
  const queryParams = queryString.parse(search);
  const { state, dispatch } = useContext(AuthContext);

  const [formState, { email, password }] = useFormState();
  const valid = validate(formState, {
    required: ["email", "password"]
  });
  const emailEl = useRef(null);

  useEffect(() => {
    if (!state.isAuthenticated) emailEl.current.focus();
  }, []);

  async function authenticate(e) {
    e.preventDefault();

    const credentials = {
      email: formState.values.email,
      password: formState.values.password
    };

    dispatch(authWithCredentials(credentials));
  }

  function _logInFirst() {
    return (
      <Space size="l">
        <AlertBanner>
          You'll need to log in before
          <br /> accessing that page.
        </AlertBanner>
      </Space>
    );
  }

  function _tokenExpired() {
    return (
      <Space size="l">
        <AlertBanner theme="danger">
          Your session has expired.
          <br />
          Please log in with your credentials below.
        </AlertBanner>
      </Space>
    );
  }

  function _error() {
    return (
      <Space size="l">
        <AlertBanner theme="danger">{state.error.message}</AlertBanner>
      </Space>
    );
  }

  function _activated() {
    return (
      <Space size="l">
        <AlertBanner>
          Your account was successfully activated. You may log in with your
          credentials below.
        </AlertBanner>
      </Space>
    );
  }

  function _reset() {
    return (
      <Space size="l">
        <AlertBanner>
          Your password was successfully reset. You may log in with your
          credentials below.
        </AlertBanner>
      </Space>
    );
  }

  function _redirect() {
    return <Redirect from="log-in" to="/" nothrow />;
  }

  function _loginForm() {
    const error = state.error && state.error.message;
    return (
      <>
        <LoginFrame >
          {state.intent && _logInFirst()}

          {error && _error()}
          {!error && queryParams.tokenExpired && _tokenExpired()}

          {queryParams.activated && _activated()}
          {queryParams.reset && _reset()}

          <form onSubmit={authenticate}>
            <div className={styles.inputWrapLogin}>
              <Space size="xs">
                
                <Label text="Email">
                  <TextInput
                    ref={emailEl}
                    {...email("email")}
                    required
                    error={formState.touched.email && formState.errors.email}
                  />
                </Label>
              </Space>
              <Space size="l">
                <Label text="Password">
                  <TextInput
                    {...password("password")}
                    required
                    error={
                      formState.touched.password && formState.errors.password
                    }
                  />
                </Label>
              </Space>
            </div>

            <Space size="m">
              <Button
                type="submit"
                theme="primary"
                isLoading={state.isAuthenticating}
                disabled={!valid}
              >
                Log in
              </Button>
            </Space>
          </form>
        </LoginFrame>
      </>
    );
  }

  return state.isAuthenticated ? _redirect() : _loginForm();
}
