import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import TypesContext from '../../contexts/Types';

import Label from '../Label';

import styles from './AssetCard.module.scss';

export default function AssetCard({ asset }) {
  const types = useContext(TypesContext);
  const type = types.assets.find(t => t.id === asset.assettype_id);

  return (
    <div className={styles.wrap}>
      <div className={styles.name}>
        <Label text="Asset name" theme="light">
          <Link to={`/assets/${asset.id}`}>
            <h3>{asset.name}</h3>
          </Link>
        </Label>
      </div>
      <div className={styles.meta}>
        <div className={styles.metaItem}>
          <Label text="Asset type" icon="local_shipping" theme="light">
            {type.name}
          </Label>
        </div>

        
      </div>
    </div>
  );
}
