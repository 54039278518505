import React,{ useState} from 'react';
import styles from './SliderCustom.module.scss';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Space from "../Space";
export default function SliderCustom({
  onChangeAction,
  inputValue
}) {
const [sensitivity,setSensitivity] =useState(inputValue);
  const marks = [
    {
      value: 0,
      label: 'Low',
    },
    {
      value: 1,
      label: 'Low/medium',
    },
    {
      value: 2,
      label: 'Medium',
    }
    ,
    {
      value: 3,
      label: 'Medium/high',
    }
    ,
    {
      value: 4,
      label: 'High',
    }
  ];

  function valuetext(value) {
    return `${value}`;
  }

  function valueLabelFormat(value) {
    switch (value) {
      case 0: return "L";
      case 1: return "L/M";
      case 2: return "M";
      case 3: return "M/H";
      case 4: return "H";
    }
  }
  const ColorSlider = withStyles({
    root: {
      color: '#ffffff',
      background: 'rgb(238,238,238)',
      background: 'linear-gradient(90deg, rgba(238,238,238,0.6) 22%, rgba(242,105,37,0.8) 100%)',
      borderRadius:'3px',
      border: '2px solid #eeeeee',
      height: 20,
    },
    markLabel:{
      fontSize:14, ['@media (max-width:415px)']: { fontSize: '12px'}, // eslint-disable-line no-useless-computed-key
      marginTop:32,
    },
    markActive:{
    },
   mark: {
      /* backgroundColor: '#ffffff',
      height: 8,
      width: 1,
      marginTop: -3,*/
      opacity: 0.01,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid #4e4e4e',
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
      marginTop: -1,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
        },
      },
    },
    active: {},
    valueLabel: {
      //left: 'calc(-50% + 4px)',
      color: '#777777'
    },
    track: {
      marginTop: 9,
      height: 5,
      opacity: 0.5,
      backgroundColor: '#a9a9a9',
      borderRadius:'3px',
    },
    rail: {
      marginTop: 9,
      height: 5,
      opacity: 0.5,
      borderRadius:'3px',
      backgroundColor: '#d9d9d9',
    },
  })(Slider);


function displayInfo(){
  switch (inputValue) {
    case 0: return 'Low sensitivity allows for extreme road vibrations and barrier movement before it triggers the alarm. This setting is recommended if the TresorTrack is placed in a construction work zone during barrier realignment and for bridge deck and overpass installations. This setting will minimize the number of impact notifications you will receive.';
    case 1: return 'Low-medium sensitivity provides the systems manager the flexibility to filter out moderate road vibrations if needed.';
    case 2: return 'Medium sensitivity (recommended) removes 95% of road vibrations and false alarms.';
    case 3: return 'Medium-high sensitivity provides the systems manager the flexibility to monitor more moderate road vibrations if needed.';
    case 4: return 'High sensitivity (most sensitive setting) will trigger the alarm for most road vibrations. It could be considered if workers are in the vicinity for an extended period of time. This setting will maximize the number of impact notifications you will receive.';
    default:
      return 'Please select the desired sensitivity';
  }
}
  return <Space size="m">
    <div className={styles.sliderPadding}>
      <ColorSlider
        value={inputValue}
        valueLabelFormat={valueLabelFormat}
        getAriaValueText={valuetext}
        aria-label="pretto slider"
        step={1}
        max={4}
        valueLabelDisplay="off"
        marks={marks}
        onChange={onChangeAction}
      />
    </div>
    <Space size="m" />
    <Space size="m"><div className={styles.lineHeight}>{displayInfo()}</div></Space>
  </Space>
}
