import React from 'react';
import moment from 'moment';
import getStatus from '../../helpers/statuses';
import {date} from '../../helpers/date'
import StatusIndicator from '../StatusIndicator';
import HealthIndicator from '../HealthIndicator';
import styles from './DeviceStatus.module.scss';
export default function DeviceStatus({
  status,
  signal,
  device_type,
  deployed,
  battery,
  firmware,
  temp,
  updated
}) {
  return (
    <div className={styles.wrap}>
      <StatusIndicator status={status} />

      <div className={styles.text}>
        <h3 className={styles.status}>{getStatus(status).name}</h3>

        {updated && (
          <p
            className={styles.subtitle}
            title={date(updated,'dddd, MMMM Do YYYY, h:mm:ss a')}
          >{}
            Updated {date(updated, 'fromNow')}
          </p>
        )}
      </div>
     
      <div className={styles.meta}>
        {signal ? (
          <HealthIndicator
            title="Signal strength"
            icon="signal_cellular_alt"
            value={`${signal}%`}
          />
        ) : null}
        {battery ? (
          <HealthIndicator
            title="Battery level"
            icon="battery_full"
            value={`${battery}%`}
          />
        ) : null}
        {temp ? (
          <HealthIndicator
            title="Temperature"
            icon="whatshot"
            value={`${temp}°F`}
          />
        ) : null}
        {firmware ? (
          <HealthIndicator
            title="Firmware version"
            icon="sd_card"
            value={`v${firmware}`}
          />
        ) : null}
      </div>
    </div>
  );
}
