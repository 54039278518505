import React, { useEffect, useState, useContext } from "react";
import { getNoAuth } from "../../api";
import LoadingFrame from "../../components/LoadingFrame";
import styles from "./LocationSharing.module.scss";
import moment from 'moment';
import {date} from '../../helpers/date'

let markers = [];
var desired_zoom_level = 17;//range 2-22
const defaultBonds = { lat: 10.7, lng: -10.8 };
const google = window.google;

export default function LocationSharing({ match: { params } }) {
  const [loading, setLoading] = useState(true);
  const [locationSharing, setLocationSharing] = useState(null);
  const [accessKey, setAccesKey] = useState(params.accessKey);
  const [map, setMap] = useState(null);
  async function fetchLocatonSharingDetails() {

    Promise.all([getNoAuth(`/locationsharing/${accessKey}`)])
      .then(res => {
        setLocationSharing(res[0]);
        setLoading(false);
      }
      )
      .catch();
  }

  async function refreshDevice() {

    Promise.all([getNoAuth(`/locationsharing/${accessKey}`)])
      .then(res => {
        setLocationSharing(res[0]);
      }
      )
      .catch();
  }


  useEffect(() => {
    const timer = setInterval(() => {
      refreshDevice();
    }, 1000 * 5);

    return () => {
      clearInterval(timer);
    };
  }, [locationSharing]);

  useEffect(() => {
    const init = new google.maps.Map(document.getElementById("map"), {
      center: new google.maps.LatLng(defaultBonds.lat, defaultBonds.lng),
      zoom: 3,
      mapId: '67fa8b61f3dfc470',
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      gestureHandling: 'greedy',
      panControl: false,
      rotateControl: false,
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
    });
    setMap(init);
    fetchLocatonSharingDetails();
  }, []);

  useEffect(() => {

    if (map) {
      var init_bounds = new google.maps.LatLngBounds();
      if (locationSharing && locationSharing) {
        if (locationSharing.device !== undefined) {
          var device = locationSharing.device;
          const data = {
            assetName: device.name,
            longitude: device.longitude,
            latitude: device.latitude,
            lastUpdated: device.status_updated_time,
            status: device.status,
          };
          const timeAgo = moment(data.lastUpdated).fromNow();
          var pinBottomLayerImage = {
            url: '/pins/pinWatch2.png',
            scaledSize:
              new google.maps.Size(56.5, 64.5),
            origin: new google.maps.Point(0, 0),
            anchor:
              new google.maps.Point(28.25, 51),
          };

          var pinTopLayerImage = {
            url: device.photo.url,
            scaledSize:
              new google.maps.Size(34, 34),
            origin: new google.maps.Point(0, 0),
            anchor:
              new google.maps.Point(17, 44),

          };

          init_bounds.extend({ lat: data.latitude, lng: data.longitude });
          if (markers.length > 0) {
            var firstMarker = markers[0];
            if (firstMarker.lastUpdated !== device.status_updated_time) {
              map.clearOverlays();

              var pinTopLayer = new google.maps.Marker({
                position: { lat: data.latitude, lng: data.longitude },
                map: map,
                icon: pinTopLayerImage,
                zIndex: 2,
                lastUpdated: device.status_updated_time,
              });

              var pinBottomLayer = new google.maps.Marker({
                position: { lat: data.latitude, lng: data.longitude },
                map: map,
                title: "BottomPin",
                optimized: false,
                icon: pinBottomLayerImage,
                zIndex: 1,
                lastUpdated: device.status_updated_time,
              });
              
              var myoverlay2 = new google.maps.OverlayView();
              myoverlay2.draw = function () {
                  this.getPanes().markerLayer.id = 'BottomPin';
              };
              myoverlay2.setMap(map)

              markers.push(pinTopLayer);
              markers.push(pinBottomLayer);

              var point = { lat: data.latitude, lng: data.longitude };
              map.setTilt(45);
              map.setCenter(point);
              map.setZoom(desired_zoom_level);
            } else {
              //console.log("no change");
            }
          } else {
            var pinTopLayer = new google.maps.Marker({
              position: { lat: data.latitude, lng: data.longitude },
              map: map,
              icon: pinTopLayerImage,
              zIndex: 2,
              lastUpdated: device.status_updated_time,
            });

            var pinBottomLayer = new google.maps.Marker({
              position: { lat: data.latitude, lng: data.longitude },
              map: map,
              title: "BottomPin",
              optimized: false,
              icon: pinBottomLayerImage,
              zIndex: 1,
              lastUpdated: device.status_updated_time,
            });

            var myoverlay = new google.maps.OverlayView();
            myoverlay.draw = function () {
                this.getPanes().markerLayer.id = 'BottomPin';
            };
            myoverlay.setMap(map)
            
            markers.push(pinTopLayer);
            markers.push(pinBottomLayer);

            var point = { lat: data.latitude, lng: data.longitude };
            map.setTilt(45);
            map.setCenter(point);
            map.setZoom(desired_zoom_level);
            //console.log("marker created");
          }
        }
      }
    } else {
      //console.log('nomap');
    }

  }, [locationSharing]);


  google.maps.Map.prototype.clearOverlays = function () {
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    markers.length = 0;
  }
  function displaySharingInfoBottom() {
    
    if (locationSharing != null) {
      var user = locationSharing.user;
      var device = locationSharing.device;
      return (
        <>
          <div>
            {user && device &&
              <>
                <div style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '125%',
                }}>
                  <span className={styles.deviceName}>{device.name}</span>
                  <span style={{ color: '#FFC059' }}>{device.status === 'MOVEMENT' ? 'In motion' : "Still"}</span>
                </div>

                <div style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '20px 0 20px 0',
                }}>

                  {device.battery ? (
                    <div className={styles.indicatorContainer}>
                      <img
                        style={{ width: '20px' }}
                        src='/icons/battery.svg' />
                      <div className={styles.indicatorTextContainer}>
                        <span className={styles.indicatorLabel}>Lifetime</span>
                        <span className={styles.indicatorText}>{device.battery}%</span>
                      </div>
                    </div>
                  ) : null}

                  {device.temperature ? (
                    <div className={styles.indicatorContainer}>
                      <img
                        style={{ width: '20px' }}
                        src='/icons/temperature.svg' />
                      <div className={styles.indicatorTextContainer}>
                        <span className={styles.indicatorLabel}>Temp.</span>
                        <span className={styles.indicatorText}>{device.temperature}°F</span>
                      </div>
                    </div>
                  ) : null}


                  {device.humid || true ? (
                    <div className={styles.indicatorContainer}>
                      <img
                        style={{ width: '33px' }}
                        src='/icons/humidity.svg' />
                      <div className={styles.indicatorTextContainer}>
                        <span className={styles.indicatorLabel}>Humid.</span>
                        <span className={styles.indicatorText}>0</span>
                      </div>
                    </div>
                  ) : null}

                  {device.signal ? (
                    <div className={styles.indicatorContainer}>
                      <img
                        style={{ width: '27px', filter: 'invert(16%) sepia(25%) saturate(7204%) hue-rotate(264deg) brightness(73%) contrast(105%)'}}
                        src='/icons/signal4.svg' />
                      <div className={styles.indicatorTextContainer}>
                        <span className={styles.indicatorLabel}>Signal</span>
                        <span className={styles.indicatorText}>{device.signal}%</span>
                      </div>
                    </div>
                  ) : null}

                </div>
              </>
            }
          </div>
          {device.status_updated_time && (<p style={{ textAlign: 'center' }}><b>Updated</b> {date(device.status_updated_time, 'fromNow')}</p>)}
          {locationSharing.expiryDate ? <p style={{ textAlign: 'center' }}>This link will expire {date(locationSharing.expiryDate, 'fromNow')}</p> : null}
        </>
      )
    }
  }
  function displaySharingInfoTop() {
    if (locationSharing != null) {
      var user = locationSharing.user;
      var device = locationSharing.device;

      if (user && device) return (
        <div className={styles.overMapTopInner}>
          {user.image && (<div style={{display: 'flex'}}><img className={styles.avatar} src={user.image?.url} /></div>)}
          <span><b>{user.first_name} {user.last_name}</b> is sharing with you a TresorTrack real time location</span>
        </div>)
      else return null
    }
  }


  function displayError() {
    return (<div className={styles.overMapErrorContainer}>
      <div className={styles.overMapErrorInner}>
      <b>Location sharing already expired!</b>
      </div>
    </div>)
  }

  return (
    <>
      <div id="map" className={styles.mapFull}></div>
      {loading ? <LoadingFrame /> :
        (
          <div>
            {locationSharing !== null ? (<>
              <div className={styles.overMapTopContainer}>
                {displaySharingInfoTop()}
              </div>
              <div className={styles.overMapBottomContainer}>
                {displaySharingInfoBottom()}
              </div>
            </>) : displayError()}
          </div>
        )}
    </>
  );
}
