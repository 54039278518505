import React, { useEffect, useState } from 'react';

import {
  normalizePermissions,
  denormalizePermissions
} from '../../helpers/user';
import { get, put } from '../../api';

import UserInfoForm from '../../components/UserInfoForm';
import FrameHeader from '../../components/FrameHeader';
import LoadingFrame from '../../components/LoadingFrame';

// import styles from './UserUserInfo.module.scss';

export default function UserUserInfo({ match: { params } }) {
  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState({});

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const { permissions } = await get(`/users/${params.id}/permissions`);
      const user = await get(`/users/${params.id}`);

      setPermissions(denormalizePermissions(permissions));
      setUser(user);
      setLoading(false);
    }

    fetchData();
  }, []);

  const updateUser = async (user, perms) => {
    setSaving(true);

    setPermissions(perms);

    const [userReq /*, permsReq */] = await Promise.all([
      put(`/users/${user.id}`, { ...user }),
      put(`/users/${user.id}/permissions`, {
        permissions: normalizePermissions(perms)
      })
    ]);

    setUser(userReq);
    setSaving(false);
  };

  if (loading) return <LoadingFrame />;

  return (
    <>
      <FrameHeader title="Info" />

      <UserInfoForm
        state={user}
        permissions={permissions}
        saving={saving}
        onSave={updateUser}
      />
    </>
  );
}
