import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useFormState } from 'react-use-form-state';
import { post, get } from '../../api';
import validate from '../../helpers/validate';
import assetTypesSelect from '../../helpers/assetTypes';
import TypesContext from '../../contexts/Types';
import { AuthContext } from '../../contexts/Auth';
import history from '../../helpers/history';
import queryString from "query-string";
import Button from '../../components/Button';
import Label from '../../components/Label';
import Modal from '../../components/Modal';
import InputRow from '../../components/InputRow';
import TextInput from '../../components/TextInput';
import Select from '../../components/Select';
import Space from '../../components/Space';
import LoadingFrame from '../../components/LoadingFrame';

export default function NewAsset({ location: { search } }) {
  const queryParams = queryString.parse(search);
  const { state } = useContext(AuthContext);
  const types = useContext(TypesContext);

  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [sites, setSites] = useState([]);

  const [saving, setSaving] = useState(false);
  const [formState, { text, select }] = useFormState({
    name: '',
    type: 1,
    serial: '',
    group_id: 1,
    site_id: 1
  });
  const valid = validate(formState, { required: ['name'] });

  useEffect(() => {
    async function fetchGroupsAndSites() {

      const [groups, sites] = await Promise.all([
        get(`/users/${state.user.user_id}/groups`),
        get(`/assets/sites`),
      ]);

      if (groups && groups.results && groups.results.length) {
        setGroups(groups.results);
        formState.setField('group_id', groups.results[0].id);
      }

      if (sites && sites.results && sites.results.length) {
        setSites(sites.results);
        formState.setField('site_id', sites.results[0].id);
      }

      setLoading(false);
    }

    fetchGroupsAndSites();
  }, []);

  async function _create() {
    const { name, type, serial, group_id, site_id } = formState.values;
    const checkName = await get(`/assets/name/${name}`);
    if (checkName != undefined) {
      if (checkName === false) {
        alert("That asset name already exists. Type in a new asset name.");
      } else {
        // ok 
        setSaving(true);
        const { tenant_id, customer_id } = state.user;


        const res = await post(`/assets`, {
          name,
          assettype_id: type,
          serial,
          tenant_id,
          customer_id,
          group_id,
          site_id
        });

        if (res !== undefined && res.id!=0) {
          history.push(`/assets/${res.id}/install?roc=true`);
        } else {
          alert("Sorry! Something went wrong. Please try again.");
          setSaving(false);
        }
      }
    }
  }
  async function checkName(name) {
    formState.setField('name', name);

  }
  function _renderFooter() {
    return (
      <>
        <Link to={queryParams.m === undefined ? ("/assets_dashboard") : ("/assets")}>
          <Button>Cancel</Button>
        </Link>

        <Button
          theme="primary"
          onClick={_create}
          isLoading={saving}
          disabled={!valid}
        >
          Create
        </Button>
      </>
    );
  }

  return (
    <Modal footer={_renderFooter()}>
      {loading ? (
        <LoadingFrame />
      ) : (
        <>
          <Space size="m">
            <h2>New asset</h2>
          </Space>

          <InputRow>
            <Label text="Name">
              <TextInput
                {...text('name')}
                error={formState.errors.name}
                placeholder=""
                required
                onChange={e => {
                  checkName(e.target.value);
                }}
              />
            </Label>
          </InputRow>

          <InputRow>
            <Label text="Type">
              <Select {...select('type')}>
                {assetTypesSelect(types.assets)}
              </Select>
            </Label>

            {/*<Label text="Serial Number">
              <TextInput {...text('serial')} />
      </Label>*/}
          </InputRow>
          {sites.length > 1 ? (
            <>
              <Label text="Site" />
              <Select {...select('site_id')}>
                {sites.map(site => (
                  <option key={site.id} value={site.id}>
                    {site.name}
                  </option>
                ))}
              </Select>
            </>
          ) : null}


          <Space size="m" />
          {groups.length > 1 ? (
            <>
              <Label text="Notification group" />
              <Select {...select('group_id')}>
                {groups.map(group => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Select>
            </>
          ) : null}

        </>
      )}
    </Modal>
  );
}
