import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { post } from '../../api';
import history from '../../helpers/history';
import { fetchAlarms } from '../../actions/Alarms';
import AlarmsContext from '../../contexts/Alarms';
import { MOVEMENT } from '../../constants/statuses';
import Button from '../../components/Button';
import Breadcrumb from '../../components/Breadcrumb';
import LoadingFrame from '../../components/LoadingFrame';
import SingleColumnPage from '../../components/SingleColumnPage';
import FrameHeader from '../../components/FrameHeader';
import Frame from '../../components/Frame';
import RowItemAlarmActionMobile from '../../components/RowItemAlarmActionMobile';
import { hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import InputRow from '../../components/InputRow';
import Icon from '../../components/Icon';
import Popover from '../../components/Popover';
import styles from './Alarms.module.scss';
import getStatus from '../../helpers/statuses';
import Space from '../../components/Space';
import { useMediaQuery } from 'react-responsive'
export default function Alarms() {
  const [state, dispatch] = useContext(AlarmsContext);
  const [acknowledgingId, setAcknowledgingId] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { state: currentUser } = useContext(AuthContext);
  const isMobile = useMediaQuery({ query: '(max-width: 400px)' })
  useEffect(() => {
    dispatch(fetchAlarms());
    setLoading(false);
  }, []);

  async function actionTaken(e, id, type, assetId) {
    e.stopPropagation();
    e.preventDefault();
    setAcknowledgingId(id);
    const res = await post(`/alarms/${id}/action/${type}`);


    //Redirect to inspection after work is done
    if (type == 3 || type == 4) {
      return history.push(`/assets/${assetId}`);
    } else {
      // close popup
      setLoading(true);
      setLoading(false);

      dispatch(fetchAlarms());
      setAcknowledgingId(null);
    }

  }

  function breadcrumbs() {
    return <Breadcrumb title="Alarms" />;
  }

  function icon(type, severity) {
    const color = severity === 'CRITICAL' ? '#ee0e0e' : '#ffb217';
    return (
      <div style={{ color }}>
        <Icon name={getStatus(type).icon} size={24} />
      </div>
    );
  }
  function icon_small(type) {
    var color = '#ffb217';
    const green = '#03b106';
    const orange = '#ffb217';
    const red = '#ee0e0e';
    const gray = '#777777';
    var selectedName = "done";
    switch (type) {
      case 0:
        selectedName = "done";
        color = green;
        break;
      case 1:
        selectedName = "error"
        color = red;
        break;
      case 2:
        selectedName = "refresh"
        color = orange;
        break;
      case 3:
        selectedName = "build"
        color = gray;
        break;
      case 4:
        selectedName = "done"
        color = green;
        break;
      default:
        break;
    }
    return (
      <div style={{ color }}>
        <Icon name={selectedName} size={20} />
      </div>
    );
  }
  function title(type, severity, assetName, text) {
    const critical = severity === 'CRITICAL';

    switch (type) {
      case 'MOVEMENT':
        return `Movement detected`;// on ${assetName}`;
      case 'ACCIDENT':
        return `Accident detected`;// on ${assetName}`;

      case 'BATTERY':
        return `${critical ? `Critically l` : `L`
          }ow battery detected`;// on ${assetName}`;

      case 'GPS_FENCE_ENTER':
        return text;// on ${assetName}`;
      case 'GPS_FENCE_EXIT':
        return text;// on ${assetName}`;
      case 'TEMPERATURE':
        return `${critical ? `Critically h` : `H`
          }igh temperature detected`;// on ${assetName}`;


      case 'SIGNALSTRENGTH':
        return `${critical ? `Critically l` : `L`
          }ow signal detected on`;// ${assetName}`;

      default:
        return `There is an issue with this asset.`;
    }
  }

  function time(timestamp) {
    if (isMobile) { return moment.utc(timestamp).local().format('MMM Do, YYYY [at] h:mma'); }
    return moment.utc(timestamp).local().format('MMMM Do, YYYY [at] h:mma');
  }

  function getAlarmOptions(a, maintenance = false, assetId) {
    return (
      a.type == MOVEMENT ? (<Popover
        text={"Action"}
        align="left"
        spacing={-230}
        width={240}
      >

        <div className={styles.wrap}><Button
          icon={icon_small(1)}
          onClick={e => actionTaken(e, a.id, 1, assetId)}
          isLoading={acknowledgingId === a.id}
          size="l"
        >
          False alert
        </Button> </div>

        <div className={styles.wrap}><Button
          icon={icon_small(0)}
          onClick={e => actionTaken(e, a.id, 4, assetId)}
          isLoading={acknowledgingId === a.id}
          size="l"
        >
          Acknowledge
        </Button>
        </div>
      </Popover>) : (null)
    );
  }
  return state.loading || loading ? (
    <LoadingFrame />
  ) : (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      {!state.alarms.length ? (
        <div className={styles.empty}>No active alarms.</div>
      ) : (
        state.alarms.map((a, index) => (
          a.asset && (<span key={index}> {a.body}
            <FrameHeader title={a.asset?.name} inlineClass="alarms">
              {a.asset.maintenance && (<div className={styles.right}><p>Movement notifications disabled</p></div>)}
            </FrameHeader>
            <Frame padding={0}>
              {(a.alarms?.map((alarm, index) => (
                alarm && (<InputRow key={index} noPadding={true}><RowItemAlarmActionMobile
                  key={a.id}
                  size="l"
                  to={a.asset && a.asset.id ? `/assets/${a.asset?.id}` : null}
                  rightspace={true}
                  title={title(alarm.type, alarm.severity, a.asset?.name, alarm.body)}
                  subtitle={time(alarm.start_time)}
                  left={icon(alarm.type, alarm.severity)}
                  right={(hasPermission(currentUser.user.perms, "ADMIN") || hasPermission(currentUser.user.perms, "ASSET_U")) && getAlarmOptions(alarm, a.asset?.maintenance, a.asset?.id)}
                /></InputRow>)
              )))
              }
            </Frame>
            <Space size="s" />
          </span>
          )
        ))
      )
      }
    </SingleColumnPage>
  );
}
