import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import Iframe from 'react-iframe'
import styles from './Help.module.scss';
import PageHeader from '../../components/PageHeader';
import Breadcrumbs from '../../components/Breadcrumbs';

export default function Help() {
  const breadcrumbs = () => (
    <Breadcrumb title="Help" />
  );

  return (
    <div className={styles.wrapper}>
      <PageHeader>
        {breadcrumbs && <Breadcrumbs>{breadcrumbs()}</Breadcrumbs>}
      </PageHeader>
      
    </div>
  );
}