import {
  OK,
  SIGNALSTRENGTH_LOW,
  SIGNALSTRENGTH_CRITICAL,
  TEMPERATURE_LOW,
  TEMPERATURE_HIGH,
  BATTERY_LOW,
  BATTERY_CRITICAL,
  COMMUNICATION_ERROR,
  MOVEMENT,
  DEVICE_REPAIRED,
  FALSE_ALERT,
  MAINTENANCE,
  WORK_COMPLETE,
  WORK_IN_PROGRESS,
  READY,
  GPS_FENCE_ENTER,
  GPS_FENCE_EXIT
} from '../constants/statuses';

const green = ['#03b106', '#018b04'];
const gray = ['#777777', '#777777'];
const yellow = ['#ffb316', '#c88b12'];
const orange = ['#ff7b00', '#c86000'];
const red = ['#ee0e0e', '#ba0808'];
const ready= ['#03b106', '#018b04'];
const info= ['#01568b', '#0065a3'];
export default function getStatus(status) {
  switch (status) {
    case OK:
      return {
        name: 'Not moving',
        icon: '',
        pin: 'ok',
        colors: green
      };

    case SIGNALSTRENGTH_LOW:
      return {
        name: 'Low signal strength',
        icon: 'flag',
        pin: 'warning',
        colors: yellow
      };

    case SIGNALSTRENGTH_CRITICAL:
      return {
        name: 'Critically low signal strength',
        icon: 'warning',
        pin: 'critical',
        colors: orange
      };

    case TEMPERATURE_LOW:
      return {
        name: 'Device temperature is low',
        icon: 'flag',
        pin: 'warning',
        colors: yellow
      };

    case TEMPERATURE_HIGH:
      return {
        name: 'Device temperature is high',
        icon: 'warning',
        pin: 'critical',
        colors: yellow
      };

    case BATTERY_LOW:
      return {
        name: 'Low battery',
        icon: 'flag',
        pin: 'warning',
        colors: yellow
      };

    case BATTERY_CRITICAL:
      return {
        name: 'Critically low battery',
        icon: 'warning',
        pin: 'critical',
        colors: orange
      };

    case COMMUNICATION_ERROR:
      return {
        name: 'Communication error',
        icon: 'warning',
        pin: 'critical',
        colors: orange
      };

    case MOVEMENT:
      return {
        name: 'In motion',
        icon: 'error_outline',
        pin: MOVEMENT,
        colors: orange
      };

    case DEVICE_REPAIRED:
      return {
        name: 'Under repair',
        icon: 'refresh',
        pin: MOVEMENT,
        colors: gray
      };
    case FALSE_ALERT:
      return {
        name: 'False alert',
        icon: 'error',
        pin: MOVEMENT,
        colors: red
      };
    case MAINTENANCE:
      return {
        name: 'Maintenance',
        icon: 'warning',
        pin: MOVEMENT,
        colors: red
      };
    case WORK_IN_PROGRESS:
      return {
        name: 'Work in progress',
        icon: 'build',
        pin: 'in_progress',
        colors: gray
      };
      case WORK_COMPLETE:
      return {
        name: 'Repair complete',
        icon: 'done',
        pin: 'ok',
        colors: green
      };
      case READY:
      return {
        name: 'Ready',
        icon: 'schedule',
        pin: 'schedule',
        colors: ready
      };
      case GPS_FENCE_ENTER:
      return {
        name: 'Ready',
        icon: 'map',
        pin: 'map',
        colors: info
      };
      case GPS_FENCE_EXIT:
      return {
        name: 'Ready',
        icon: 'map',
        pin: 'map',
        colors: info
      };
    case false:
      return {
        name: 'Inactive',
        icon: 'warning',
        pin: 'ok',
        colors: gray
      };

    default:
      return {
        name: 'Inactive',
        icon: '',
        pin: 'ok',
        colors: gray
      };
  }
}
