import React, { useContext, useState } from 'react';

import { put } from '../../api';
import CustomerContext from '../../contexts/Customer';

import CustomerInfoForm from '../../components/CustomerInfoForm';
import FrameHeader from '../../components/FrameHeader';

// import styles from './TenantInfo.module.scss';

export default function CustomerInfo() {
  const { customer, setCustomer } = useContext(CustomerContext);
  const [saving, setSaving] = useState(false);

  const updateCustomer = async customer => {
    setSaving(true);
    const res = await put(`/customers/${customer.id}`, { ...customer });
    setCustomer(res);
    setSaving(false);
  };

  return (
    <>
      <FrameHeader title="Info" />

      <CustomerInfoForm
        state={customer}
        saving={saving}
        onSave={customer => updateCustomer(customer)}
      />
    </>
  );
}
