import React, { useState } from "react";

import Button from "../Button";
import Space from "../Space";
import Modal from "../Modal";
import styles from './InstallOnAssetStep5.module.scss';

import Image from '../../components/Image';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { createTheme } from 'baseui';
import { PinCode } from "baseui/pin-code";
import { post } from "../../api";
import LoadingFrame from "../../components/LoadingFrame";
import Icon from '../Icon';


export default function InstallOnAssetStep5({ deviceInput, back, next ,activation_feedback}) {
  const [activationError, setActivationError] = useState(false);
  const [pinError,setPinError] = useState(false);
  const [values, setValues] = useState([
    "",
    "",
    "",
    ""
  ]);
  const [checkingPin, setCheckingPin] = useState(false);
  const [activating, setActivating] = useState(false);
  const [activated, setActivated] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [activationFailureCounter, setActivationFailureCounter] = useState(0);
  const [device, setDevice] = useState(null);
  const [abort, setAbort] = useState(false);
  const engine = new Styletron();

  const primitives = {
    inputFillActive: '#a9a9a9',
    inputFill: '#d9d9d9',
  };
  const overrides = {
    colors: {
      inputFillActive: primitives.inputFillActive,
      inputFill: primitives.inputFill,
    },
  };
  const pinTheme = createTheme(primitives, overrides);



  function markError() {
    if (activationFailureCounter + 1 >= 3) {
      setAbort(true);
    }
    setActivationFailureCounter(activationFailureCounter + 1)
    
    setActivationError(true)
  }
  async function activateTheDevice(pincode) {
    setActivating(true);
    const device = await post(`/device/${deviceInput.serial_number}/activate/`, {
      activation_code: pincode.toString(),
      set_to_active: false,
    });
    if (device !== undefined && device.id !== 0) {
      setActivated(true);
      setDevice(device);
      activation_feedback(true);
    } else {
      markError()
    }
    setActivating(false);
  }

  async function changeValues(input) {
    setValues(input);
    setPinError(false);
    if (input[0] !== "" && input[1] !== "" && input[2] !== "" && input[3] !== "") {
      setCheckingPin(true);
      const device = await post(`/device/${deviceInput.serial_number}/pin/`, {
        activation_code: input.toString()
      });
      if (device.id !== 0) {
        setProceed(true);
        activateTheDevice(input.toString());
      } else {
        setValues([
          ,
          ,
          ,
        ])
        setPinError(true)
        setValues([
          "",
          "",
          "",
          ""
        ])
      }
      setCheckingPin(false);
    }
  }

  function renderInput() {
    return (<>
      <div className={styles.wrap}>
        <h3>Enter the 4-digit PIN code that is located on the TresorTrack device.</h3>
      </div>
      <Space size="s" />
      <div className={styles.inputWrap}>
        <StyletronProvider value={engine}>
          <BaseProvider theme={pinTheme}>
            <PinCode
              values={values}
              onChange={({ values }) => changeValues(values)}
              disabled={checkingPin || proceed}
              clearOnEscape
              placeholder=""
            />
          </BaseProvider>
        </StyletronProvider>
      </div></>);
  }
  function _renderFooter() {
    return (
      <>
        <Button onClick={back}>Back</Button>

        {!abort && (<Button theme="primary" onClick={next} disabled={!activated}>
          Next
        </Button>)}
      </>
    );
  }

  return (
    <Modal footer={_renderFooter()}>

      <Space size="s">
        <h2>Activate your device</h2>
      </Space>

      <Space size="s" />
      <Space size="s">
        <div className={styles.bannerImage}>
          <Image url={"/img/TresorTrack.png"} token={false} zoom={true} />
        </div>
      </Space>
      <Space size="s" />
      <Space size="s" >{renderInput()}</Space>
      <Space size="s" >
        {(checkingPin == true || activating == true) && <LoadingFrame />}
        <div className={styles.wrap}>
          {!activated && device && device.id !== 0 && (
            <>
              <div className={styles.icon}>
                <Icon name="check_circle" size={64} />
              </div>
              <h1>PIN correct</h1>
              <p className={styles.body}>PIN matches our records. Let's move to the next step.</p>
            </>)}
          {!activated && pinError && (
            <>
              <div className={styles.error}>
                <Icon name="error" size={64} />
              </div>
              <h1>Wrong PIN</h1>
              <p className={styles.body}>Looks like you typed in the wrong PIN code.</p>
            </>)}

          {activated && (<div className={styles.wrap}>
              <div className={styles.icon}>
                <Icon name="check_circle" size={64} />
              </div>

              <h1>All set</h1>
              <p className={styles.body}>
               The device was activated succesfully. Please proceed to the next step.
          </p>
          
              </div>)}

          {!abort && !checkingPin && !activating && activationError && activationFailureCounter <= 2 && (<>
            <div className={styles.wrap}>
              <div className={styles.error}>
                <Icon name="error" size={64} />
              </div>

              <h1>Device activation failed</h1>
              <p className={styles.body}>
                Whoops. Something went wrong. Press activate to try again.
</p>
              <Space size="l" />
              <Button onClick={() => activateTheDevice(values.toString())} theme="primary">Activate</Button>
            </div>
          </>)}

          {abort &&  (<>
            <div className={styles.wrap}>
              <div className={styles.error}>
                <Icon name="error" size={64} />
              </div>

              <h1>Device activation failed</h1>
              <p className={styles.body}>
                Looks like something went wrong with the activation process.
                      <br />
                      Contact the TresorTrack support team to troubleshoot this issue.
                      <br /><br />
                      Call 650-495-8758 or e-mail support@tresortrack.com
          </p>
              
            </div>
          </>)}

        </div>
      </Space>

    </Modal>
  );
}
