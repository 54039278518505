import React from 'react';
import styles from './Support.module.scss';

export default function Support() {
  return (
    <div className={styles.support}>
      <span style={{ marginTop: 18, marginBotom: 15 }} className={styles.h}>Contact Us</span>
      <p>
        <span style={{ display: 'block' }}>Tresor Track Inc.</span>
        <span style={{ display: 'block' }}>440 N. Wolfe rd</span>
        <span style={{ display: 'block' }}> Sunnyvale, California 94085</span>
        <span style={{ display: 'block' }}>Email Address: <a href="mailto:support@tresortrack.com">support@tresortrack.com</a></span>
      </p>
      <p style={{ fontWeight: '500' }}><a href="../terms-and-conditions">Terms and Conditions</a></p>
    </div>
  );
}
