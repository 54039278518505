import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { get } from '../../api';
import MapContext from '../../contexts/Map';
import { AuthContext } from '../../contexts/Auth';
import { CUSTOMER_USER } from '../../constants/roles';
import { isAuthorized, hasPermission } from '../../helpers/authorization';
import { toggleMap, showAllMarkers,setMarkers } from '../../actions/Map';
import {
  MOVEMENT,
  SIGNALSTRENGTH_LOW,
  SIGNALSTRENGTH_CRITICAL,
  TEMPERATURE_LOW,
  TEMPERATURE_HIGH,
  BATTERY_LOW,
  BATTERY_CRITICAL,
  COMMUNICATION_ERROR,
  OK,
  INACTIVE,
  WORK_IN_PROGRESS,
  READY,
} from '../../constants/statuses';
import ActiveAssets from '../ActiveAssets';
import InactiveAssets from '../InactiveAssets';
import NewAsset from '../NewAsset';
import Breadcrumb from '../../components/Breadcrumb';
import Breadcrumbs from '../../components/Breadcrumbs';
import Button from '../../components/Button';
import DataValue from '../../components/DataValue';
import FrameHeader from '../../components/FrameHeader';
import LoadingFrame from '../../components/LoadingFrame';
import MapToggleButton from '../../components/MapToggleButton';
import Overview from '../../components/Overview';
import PageHeader from '../../components/PageHeader';
import PageWrap from '../../components/PageWrap';
import Space from '../../components/Space';
import StatusIndicator from '../../components/StatusIndicator';
import SubNavigation from '../../components/SubNavigation';
import {
  TOOLTIP_TOTAL_ASSETS,
  TOOLTIP_ACTIVE_ASSETS,
  TOOLTIP_INACTIVE_ASSETS,
  TOOLTIP_READY_ASSETS,
  TOOLTIP_MOVEMENT_DETECTED,
  TOOLTIP_WORK_IN_PROGRESS,
  TOOLTIP_CRITICAL_WARNINGS,
  TOOLTIP_REGULAR_WARNINGS,
} from '../../helpers/tooltips.js';
import Modal from '../../components/Modal';
import { useMediaQuery } from 'react-responsive';
import { useConfirm } from 'material-ui-confirm';

export default function Assets({ history }) {
  const { state } = useContext(AuthContext);
  const [, dispatch] = useContext(MapContext);

  const [counts, setCounts] = useState({
    active: 0,
    inactive: 0,
    impacted: 0,
    warning: 0,
    critical: 0,
    work_in_progress: 0,
    ready: 0,
  });
  const [loading, setLoading] = useState(true);
  
  const [simpleMode, setSimpleMode] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const confirm = useConfirm();

  useEffect(() => {
    async function fetchActiveDevices() {
      const devices = await get('/devices', {
        active: 'true',
        include_assets: '1'
      });

      if (devices && devices.results) {
        dispatch(setMarkers(devices.results));
        dispatch(showAllMarkers());/**/
      }
    }
    async function fetchAssetCounts() {
      // prettier-ignore
      const [active, inactive, impacted, critical,warning, work_in_progress, ready] = await Promise.all([
        get('/assets', { active: 'true', include: 'COUNT(*)', take: '1' }),
        get('/assets', { active: 'false', include: 'COUNT(*)', take: '1' }),
        get('/devices', { statuses: `${MOVEMENT}`, active: 'true', include: 'COUNT(*)', take: '1' }),
        get('/devices', { statuses: `${SIGNALSTRENGTH_CRITICAL},${BATTERY_CRITICAL},${TEMPERATURE_HIGH},${COMMUNICATION_ERROR}`, active: 'true', include: 'COUNT(*)', take: '1' }),
        get('/devices', { statuses: `${SIGNALSTRENGTH_LOW},${BATTERY_LOW},${TEMPERATURE_LOW}`, active: 'true', include: 'COUNT(*)' }),
        get('/devices', { statuses: `${WORK_IN_PROGRESS}`, active: 'true', include: 'COUNT(*)', take: '1' }),
        get('/devices', { statuses: `${READY}`, active: 'true', include: 'COUNT(*)', take: '1' }),
        //get(`/users/${state.user.user_id}/groups`),
      ]);
      //Counts
      setCounts({
        active: active.total,
        inactive: inactive.total,
        impacted: impacted.total,
        critical: critical.total,
        warning: warning.total,
        work_in_progress: work_in_progress.total,
        ready: ready.total,
      });

      setLoading(false);
    }

    fetchAssetCounts();
    fetchActiveDevices();
  }, []);

  const pageHeaderActions = () => (
    <>
     
        <MapToggleButton onClick={() => {dispatch(toggleMap())} }/>
     
      {(isAuthorized(state.user.roles, [CUSTOMER_USER]) ) &&
        hasPermission(state.user.perms, 'ASSET_C') && (
          <Link to="/assets/new">
            <Button icon="add" theme="primary">
              New asset
            </Button>
          </Link>
        )}
    </>
  );

 

  

  function _renderHeader() {
    return (
 
      <div style={{marginLeft:'auto', width: 'unset'}}><MapToggleButton onClick={() => dispatch(toggleMap())} /></div>
    );
  }

     function _renderContent() {
    return (
      
      (<><PageWrap>
        <Space size="l">
          <FrameHeader title="Overview" />
          <Overview>
            <DataValue
              mobile={true}
              tooltip={TOOLTIP_TOTAL_ASSETS}
              label="Total assets">
              {counts.active + counts.inactive}
            </DataValue>
            <DataValue
              mobile={true}
              tooltip={TOOLTIP_ACTIVE_ASSETS}
              label="Active assets">
              <StatusIndicator status={OK} /> {counts.active}
            </DataValue>
            <DataValue
              mobile={true}
              tooltip={TOOLTIP_INACTIVE_ASSETS}
              label="Inactive assets">
              <StatusIndicator status={INACTIVE} /> {counts.inactive}
            </DataValue>
           
            <DataValue
              mobile={true}
              tooltip={TOOLTIP_REGULAR_WARNINGS}
              label="Warnings">
              <StatusIndicator status={BATTERY_LOW} /> {counts.warning}
            </DataValue>
          </Overview>
        </Space>

        <ActiveAssets mobile={true} />

       
        <Route path="/assets/new" component={NewAsset} exact />
      </PageWrap>
      </>)
    );
  }

  return (
    (simpleMode && isMobile)? 
    (loading ? <LoadingFrame /> : _renderContent()):
    (<>
      <PageHeader right={pageHeaderActions()}>
        <Breadcrumbs>
          <Breadcrumb title="Assets" />
        </Breadcrumbs>
      </PageHeader>
      {loading ? <LoadingFrame /> : _renderContent()}
    </>)
  );
}
