import React, { useState, useEffect, useContext } from 'react';
import { useFormState } from 'react-use-form-state';
import { differenceBy } from 'lodash';
import history from '../../helpers/history';
import validate from '../../helpers/validate';
import { get, post, destroy, put } from '../../api';
import { AuthContext } from '../../contexts/Auth';
import Button from '../../components/Button';
import Frame from '../../components/Frame';
import FrameFooter from '../../components/FrameFooter';
import FrameHeader from '../../components/FrameHeader';
import Label from '../../components/Label';
import LoadingFrame from '../../components/LoadingFrame';
import NoResults from '../../components/NoResults';
import RowItem from '../../components/RowItem';
import Space from '../../components/Space';
import TextInput from '../../components/TextInput';
import styles from './UserGroupDetail.module.scss';
import { useConfirm } from 'material-ui-confirm';

export default function GroupDetail({ match: { params } }) {
  const { state } = useContext(AuthContext);
  const [formState, { text }] = useFormState();
  const valid = validate(formState, {
    required: ['name']
  });

  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(0);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [group, setGroup] = useState(null);
  const [groupUsers, setGroupUsers] = useState([]);
  const [nonGroupUsers, setNonGroupUsers] = useState([]);
  const confirm = useConfirm();

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (group) {
      formState.setField('name', group.name);
    }
  }, [group]);

  async function fetchUsers() {
    const groupId = params.groupId;

    const [group, groupUsers, nonGroupUsers] = await Promise.all([
      get(`/groups/${groupId}`),
      get(`/groups/${groupId}/users`),
      get('/users', { customer_id: state.user.customer_id, include_groups: 1 })
    ]);

    const uniq = differenceBy(nonGroupUsers.results, groupUsers.results, 'id');

    setGroup(group);
    setGroupUsers(groupUsers.results);
    setNonGroupUsers(uniq);

    setLoading(false);
  }

  async function save() {
    setSaving(true);

    await put(`/groups/${params.groupId}`, {
      ...group,
      name: formState.values.name
    });

    setSaving(false);
  }

  function deleteGroupConfirmaton() {
    confirm({
      description: "Are you sure you want to remove this notification group?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        deleteGroup();
      })
      .catch(() => { /* ... */ });
  }

  async function deleteGroup() {
    setDeleting(true);
    await destroy(`/groups/${params.groupId}`);
    history.push(`/customer/groups`);
  }

  async function addToGroup(userId) {
    setUserLoading(userId);

    await post('/relations', {
      from_id: params.groupId,
      from_type: 'GROUP',
      to_id: userId,
      to_type: 'USER',
      relation_type_group: 'COMMON'
    });

    setUserLoading(0);
    fetchUsers();
  }

  async function removeFromGroup(userId) {
    const confirm = true;
    
    /*window.confirm(
      "Are you sure you want to remove this user from the notification group?"
    );*/

    if (confirm) {
      setUserLoading(userId);

      const relation = await get('/relations', {
        from_id: params.groupId,
        from_type: 'GROUP',
        to_id: userId,
        to_type: 'USER'
      });

      if (relation && relation.results && relation.results.length) {
        const toDelete = relation.results[0].id;
        await destroy(`/relations/${toDelete}`);

        setUserLoading(0);
        fetchUsers();
      }
    }
  }

  if (loading) return <LoadingFrame />;

  return (
    <>
      <Space size="l">
        <FrameHeader title="Info" />
        <Frame>
          <Label text="Group name">
            <TextInput {...text('name')} required />
          </Label>
          <FrameFooter>
            <Button
              type="submit"
              theme="primary"
              disabled={!valid}
              isLoading={saving}
              onClick={save}
            >
              Save
            </Button>
          </FrameFooter>
        </Frame>
      </Space>

      <Space size="l">
        <FrameHeader title="Users in this notification group" />
        <Frame padding={0}>
          {groupUsers.length ? (
            groupUsers.map(u => (
              <RowItem
                key={u.id}
                title={`${u.first_name} ${u.last_name}`}
                subtitle={u.email}
                right={
                  <Button
                    onClick={() => removeFromGroup(u.id)}
                    isLoading={userLoading === u.id}
                  >
                    Remove from group
                  </Button>
                }
              />
            ))
          ) : (
            <NoResults message="There are no users in this notification group yet." />
          )}
        </Frame>
      </Space>

      <FrameHeader title="Users not in this notification group" />
      <Frame padding={0}>
        {nonGroupUsers.length ? (
          nonGroupUsers.map(u => (
            <RowItem
              key={u.id}
              title={`${u.first_name} ${u.last_name}`}
              subtitle={u.email}
              right={
                <Button
                  onClick={() => addToGroup(u.id)}
                  isLoading={userLoading === u.id}
                >
                  Add to group
                </Button>
              }
            />
          ))
        ) : (
          <NoResults />
        )}
      </Frame>

      <div className={styles.deleteWrap}>
        <Button onClick={deleteGroupConfirmaton} theme="danger" isLoading={deleting}>
          Delete group
        </Button>
      </div>
    </>
  );
}
