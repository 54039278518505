import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { post, upload } from '../../api';
import AssetContext from '../../contexts/Asset';
import { ASSET_NOTE } from '../../constants/events';
import { fetchActivity } from '../../actions/Asset';
import history from '../../helpers/history';

import Button from '../../components/Button';
import AssetCard from '../../components/AssetCard';
import FileUploader from '../../components/FileUploader';
import FileUploadProgress from '../../components/FileUploadProgress';
import Label from '../../components/Label';
import LoadingFrame from '../../components/LoadingFrame';
import Modal from '../../components/Modal';
import Space from '../../components/Space';
import TextArea from '../../components/TextArea';

// import styles from './AssetNote.module.scss';

export default function AssetNote() {
  const [state, dispatch] = useContext(AssetContext);

  const [saving, setSaving] = useState(false);
  const [body, setBody] = useState('');
  const [attachments, setAttachments] = useState([]);

  async function _saveNote() {
    setSaving(true);

    const event = await post(`/events`, {
      type: ASSET_NOTE,
      ...(state.device.id && { device_id: state.device.id }),
      asset_id: state.asset.id,
      body
    });

    let attachmentReqs = [];

    attachments.forEach(attachment => {
      const formData = new FormData();

      formData.set('entity_type', 'EVENT');
      formData.set('entity_id', event.id);
      formData.set('type', 'IMAGE');
      formData.append('file', attachment);

      const res = upload(formData);
      attachmentReqs.push(res);
    });

    await Promise.all(attachmentReqs);

    dispatch(fetchActivity(state.asset.id, state.activity.filters, 1));
    history.push(`/assets/${state.asset.id}`);
  }

  function _removeFile(i) {
    const newAttachments = attachments
      .slice(0, i)
      .concat(attachments.slice(i + 1, attachments.length));

    setAttachments(newAttachments);
  }
  function saveFile(attachment) {
    if (attachment != undefined) {
      const isArray = Array.isArray(attachment);
      if (isArray) {
        var firstElement = attachment[0];
        if (firstElement !== undefined && firstElement.name !== undefined) {
          setAttachments([...attachments, ...attachment])
        } else {
          setAttachments([...attachments, ...firstElement])
        }
      } else {
        var newArray = [attachment]
        setAttachments([...attachments, ...newArray])
      }
    } else {
      alert('File saving error!');
    }
  }
  function _renderFooter() {
    return (
      <>
        <Link to={`/assets/${state.asset.id}`}>
          <Button>Cancel</Button>
        </Link>
        <Button theme="primary" onClick={_saveNote} isLoading={saving}>
          Save
        </Button>
      </>
    );
  }

  return (
    <Modal footer={_renderFooter()}>
      {!state.asset.id ? (
        <LoadingFrame />
      ) : (
        <>
          <Space size="m">
            <h2>Add Note</h2>
          </Space>

          <Space size="m">
            <Label text="Asset" />
            <AssetCard asset={state.asset} />
          </Space>

          <Space size="m">
            <Label text="Note">
              <TextArea
                placeholder="Enter your note here"
                value={body}
                onChange={e => setBody(e.target.value)}
              />
            </Label>
          </Space>

          <Space size="m">
            <Label text="Attachments" />
            <Space size="m">
              <FileUploader
                onSelect={files => saveFile([files])}
              />
            </Space>

            <FileUploadProgress
              files={attachments}
              uploading={saving}
              onRemove={i => _removeFile(i)}
            />
          </Space>
        </>
      )}
    </Modal>
  );
}
