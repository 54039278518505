import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/Auth';
import { TENANT_ADMIN, CUSTOMER_USER, SYS_ADMIN } from '../../constants/roles';
import { isAuthorized, hasPermission } from '../../helpers/authorization';
import FrameHeader from '../../components/FrameHeader';
import RowItem from '../../components/RowItem';
import LoadingFrame from '../../components/LoadingFrame';
import Space from '../../components/Space';
import Button from '../../components/Button';
import CollectionListing from '../../components/CollectionListing';
import { Link } from 'react-router-dom';

export default function CustomerSites({ match: { params } }) {
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [siteUrl, setSiteUrl] = useState('');
  const [toUrl, setToUrl] = useState('');

  /*async function createNewSite() {
    setLoading(true);
    await post(`/site/new`, { customer_id: params.id, name: newSite });
    setNewSite('');
    setLoading(false);
  }*/

  useEffect(() => {

    if (params.id === undefined) {
      setSiteUrl(`/customer/sites`)
      setToUrl(`/customer/site/`)
    } else {
      setSiteUrl(`/sites/${params.id}`)
      setToUrl(`/customers/${params.id}/sites/`)
    }

    setLoading(false);

  }, []);
  return loading ? (
    <LoadingFrame />
  ) : (
    <>
      <Space size="l">
        <FrameHeader title={`Sites`} >
          {isAuthorized(state.user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) && hasPermission(state.user.perms, 'ADMIN') && (
            <Link to={`/customer/sites/new`}>
              <Button theme="primary" icon="add" fixedWidth={126}>
                New site
              </Button>
            </Link>
          )}
        </FrameHeader>
        <CollectionListing
          collection={siteUrl}
          item={c => (
            <RowItem
              key={c.id}
              title={c.name}
              to={`${toUrl}${c.id}`}

            />
          )}
        /*sortOptions={
          <>
           
            <option value="name">Name</option>
          </>
        }*/
        />
      </Space>
    </>
  );
}