import React, { useState } from 'react';

import { post } from '../../api';

import Label from '../../components/Label';
import Space from '../../components/Space';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import LoginFrame from '../../components/LoginFrame';
import AlertBanner from '../../components/AlertBanner';

import styles from './Forgot.module.scss';

export default function Forgot() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  async function _sendResetEmail(e) {
    e.preventDefault();

    /** Reset response state */
    setSuccess(false);
    setError(false);

    setLoading(true);

    const res = await post('/users/forgotPassword', { email }, false);
    res && res.ok ? setSuccess(true) : setError(true);

    setLoading(false);
  }

  function _renderSucess() {
    return (
      <Space size="l">
        <AlertBanner>
          We've just sent you an email that includes instructions on how to
          reset your password.
        </AlertBanner>
      </Space>
    );
  }

  function _renderError() {
    return (
      <Space size="l">
        <AlertBanner theme="danger">
          We're unable to process your request to reset your password. Please
          ensure you entered your email correctly and try again.
        </AlertBanner>
      </Space>
    );
  }

  return (
    <LoginFrame
      heading="Forgot your password?"
      subHeading="Enter your email and we'll send you a link to reset your password."
    >
      {success && _renderSucess()}
      {error && _renderError()}

      {!success && (
        <form onSubmit={_sendResetEmail}>
          <div className={styles.inputWrap}>
            <Space size="l">
              <Label text="Email">
                <TextInput
                  type="email"
                  label="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Label>
            </Space>
          </div>

          <Space size="m">
            <Button type="submit" theme="primary" isLoading={loading}>
              Reset password
            </Button>
          </Space>
        </form>
      )}
    </LoginFrame>
  );
}
