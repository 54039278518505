import React, { useEffect, useState } from "react";

import { post, upload, get } from '../../api';

import Button from '../Button';
import FileUploader from '../FileUploader';
import FileUploadProgress from '../FileUploadProgress';
import Label from '../Label';
import Space from '../Space';
import Modal from '../Modal';
import Image from "../Image";

import styles from './InstallOnAssetStep3.module.scss';

export default function InstallOnAssetStep3({
  back,
  selectedDevice,
  assetId,
  next,
  switch_owner=false,
}) {
  const [saving, setSaving] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [oldAssetPhoto, setOldAssetPhoto] = useState(false);

  /*async function _fetchInstallPhoto(id) {
    const files = await get(`/files`, {
      entity_type: 'ASSET',
      entity_id: id,
      take: 1,
      orderBy: '-created_time'
    });
    return files.results.length && files.results[0];
  }

  async function tryToGetOldAssetPhoto() {
    const installPhoto = await _fetchInstallPhoto(assetId);
    setOldAssetPhoto(installPhoto);
  }

  useEffect(() => {
    tryToGetOldAssetPhoto();
  }, []);*/

  async function _finish() {
    setSaving(true);

    const relation = await post('/relations', {
      from_type: 'ASSET',
      from_id: assetId,
      to_type: 'DEVICE',
      to_id: selectedDevice,
      switch_owner:switch_owner,
    });

    let fileReqs = [];

    attachments.forEach(file => {
      const formData = new FormData();

      formData.set('entity_type', 'ASSET');
      formData.set('entity_id', assetId);
      formData.set('type', 'IMAGE');
      formData.append('file', file);

      const res = upload(formData);
      fileReqs.push(res);
    });

    if (fileReqs.length > 0) { 
      await Promise.all(fileReqs);
    }
    next(relation);
  }

  function _removeFile(i) {

    if (attachments != undefined) {
      const newAttachments = attachments
        .slice(0, i)
        .concat(attachments.slice(i + 1, attachments.length));

      setAttachments(newAttachments);
    
    } else {
      alert('delete error');
    }
  }
  function saveFile(attachment) {
    if (attachment != undefined) {
      const isArray = Array.isArray(attachment);
      if (isArray) {
        var firstElement = attachment[0];
        if (firstElement !== undefined && firstElement.name !== undefined) {
          setAttachments([...attachments, ...attachment])
        } else {
          setAttachments([...attachments, ...firstElement])
        }
      } else {
        var newArray = [attachment]
        setAttachments([...attachments, ...newArray])
      }
    } else {
      alert('File saving error!');
    }
  }
  function _renderFooter() {
    return (
      <>
        <Button onClick={back}>Back</Button>

        <Button
          theme="primary"
          onClick={_finish}
          isLoading={saving}
          disabled={saving}
        >
          Next
        </Button>
      </>
    );
  }

  return (
    <Modal footer={_renderFooter()}>
      <Space size="l">
        <h2>Step 3: Upload photos of the installation</h2>
      </Space>

      <Space size="m">
        <div className={styles.examplesWrap}>
          {false && oldAssetPhoto && oldAssetPhoto.url.length > 0 ?
            <Image url={oldAssetPhoto.url} zoom={true} />
            :
            <div className={styles.examples}>
              <div className={styles.example}>
                <img src="/img/sample_att_1.png" alt="" />
              </div>

              <div className={styles.example}>
                <img src="/img/sample_att_2.png" alt="" />
              </div>
            </div>
          }
        </div>
      </Space>

      <Space size="m">
        <p style={{ textAlign: "center" }}>
          You're almost there! The final step is to take photos of the device installed on the asset.<br />
          You can upload photos now, or choose to upload them later.
        </p>
      </Space>

      <Space size="m">
        <Label text="Installation photos" theme="light" />
        <Space size="m">
          <FileUploader
            onSelect={newFiles => saveFile([newFiles])}
          />
        </Space>

        <FileUploadProgress
          files={attachments}
          uploading={saving}
          onRemove={i => _removeFile(i)}
        />
      </Space>
    </Modal>
  );
}
