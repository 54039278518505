import React from 'react';
import Label from '../Label';
import styles from './InspectionCard.module.scss';
import {assetInspectionStates} from '../../helpers/assetTypes';
import { MINOR_REPAIR, MINOR_WITH_NO_REPAIR, MAJOR } from '../../constants/statuses';
import moment from 'moment';

export default function InspectionCard({ inspection }) {
  function decodeHTML(content) {
    return (
      <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
    );
  }

  function displaySeverity(severity) {
    if (severity !== "" && severity!==undefined) {
      switch (severity) {
        case MINOR_WITH_NO_REPAIR: return "1-Minor with no repair";
        case MINOR_REPAIR: return "2-Minor repair";
        case MAJOR: return "3-Major, Severe, Replacement";
      }
    } else {
      return "0-No issues";
    }
  }
 
  return (
    <div className={styles.wrap}>
      <div className={styles.row}>
        <Label text="Asset status" theme="light">
          <p>{assetInspectionStates[inspection.damage] && assetInspectionStates[inspection.damage]}</p>
        </Label>

        <Label text="Severity" theme="light">
          <p>{displaySeverity(inspection.severity)}</p>
        </Label>

        {inspection.reference_event && (<Label text="Movement date" theme="light">
          <p>{moment(inspection.reference_event.created_time).format('MMMM Do YYYY')}</p>
        </Label>)}

        {inspection.work_order && (<Label text="Work order number" theme="light">
          <p>{inspection.work_order}</p>
        </Label>)}
        {inspection.service_request && (<Label text="Service request number" theme="light">
          <p>{inspection.service_request}</p>
        </Label>)}

        {(inspection.cost != "false" && inspection.cost) && (<Label text="Repair cost" theme="light">
          <p>${inspection.cost}</p>
        </Label>)}

        {inspection.repair_complete_time && (<Label text="Repair completion date" theme="light">
          <p>{moment(inspection.repair_complete_time).format('MMMM Do YYYY')}</p>
        </Label>)}
        <Label>

        </Label>
      </div>
      {(inspection.body != "" && inspection.body != "<br>" && inspection.body)  && (<Label text="Notes" theme="light">
         <div className={styles.inspectionNotes}>{decodeHTML(inspection.body)}</div>
      </Label>)}

    </div>
  );
}
