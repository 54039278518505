import React from 'react';

export default function Icon({children, name, size = 20,color=false }) {

  return (<>
    <i
      className={`material-icons`}
      style={{ fontSize: size,  color: color } }
      aria-hidden="true"
    >
      {name}
    </i>{children}
    </>
  );
}
