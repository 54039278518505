import { DateTime, Interval } from "luxon";
export const date = (date, format) => {
    if (!date || !format) return null

    const parsedDate = DateTime.fromSeconds(parseInt(date?.slice(6, 16)))
    // const serverTime = DateTime.now().setZone('Europe/Warsaw').offset;
    const serverTime = DateTime.now().setZone('America/Los_Angeles').offset;
    const utcTime = DateTime.now().setZone('UTC').offset;
    const hoursDifference = (serverTime - utcTime) / 60
    // const adjustedDate = parsedDate.plus({ hours: hoursDifference });
    const adjustedDate = parsedDate//.plus({ minutes: -(new Date).getTimezoneOffset() })



    switch (format) {
        case 'untouched':
            return parsedDate
        case 'raw':
            return adjustedDate
        case 'longIso':
            return adjustedDate.toFormat('yyyy/MM/dd h:mm');
        case 'trip.start_date':
            return adjustedDate.plus({ minutes: (new Date).getTimezoneOffset() }).toFormat('DDDD');
        case 'time':
            return adjustedDate.toLocaleString(DateTime.TIME_SIMPLE);
        case 'short':
            return adjustedDate.toFormat('M/dd yyyy, h:mm a');
        case 'summary':
            return adjustedDate.toFormat('yyyy/MM/dd');
        case 'weekday':
            return adjustedDate.weekdayShort;
        case 'fromNow':
            return adjustedDate.toRelative()
        default:
            return adjustedDate.toFormat(format)
    }
}