import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import {
  OK_INDEX,
  WARNING_INDEX,
  WIP_INDEX,
  CRITICAL_INDEX,
  MOVEMENT_INDEX,
} from "../../helpers/pinPostions";
import history from "../../helpers/history";
import getStatus from "../../helpers/statuses";
import MapContext from "../../contexts/Map";
import { toggleMap } from "../../actions/Map";
import { useWindowSize } from "../../hooks/window";
import { put, get } from "../../api";
import Button from "../Button";
import Loading from "../Loading";
import styles from "./Map.module.scss";
import NavContext from "../../contexts/Nav";
import { useMediaQuery } from "react-responsive";
import { useConfirm } from "material-ui-confirm";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import Label from "../../components/Label";
import InputRow from "../../components/InputRow";
import Popover from "../../components/Popover";
import {
  DateRange,
  DateRangePicker,
  Calendar,
  DefinedRange,
} from "react-date-range";
import { DateTime, Interval } from "luxon";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
const google = window.google;
const popoverTemplate = `
  <div class="map-popover">
    <label class="map-popover__label">Asset name</label>
    <div class="map-popover__name">{assetName}</div>

    <label class="map-popover__label">Status</label>
    <div class="map-popover__status">
      <span class="map-popover__icon map-popover__icon--{status} material-icons">{icon}</span>
      <span>{statusName}</span>
    </div>
  </div>
`;
const popoverHistoryTemplate = `
  <div class="map-popover">
    <label class="map-popover__label">City name</label>
    <div class="map-popover__status">{city}</div>
    <div class="map-popover__status">{address}</div>
    <div class="map-popover__status">{gps_lat}</div>
    <div class="map-popover__status">{gps_lon}</div>
    <div class="map-popover__status">{cell_lat}</div>
    <div class="map-popover__status">{cell_lon}</div>
    <div class="map-popover__status">{zip}</div>
    <div class="map-popover__status">{when}</div>
    <div class="map-popover__status">{timeago}</div>
    <div class="map-popover__status">CellId {cellid}</div>
  </div>
`;

const defaultBonds = { lat: 10.7, lng: -10.8 };
const defaultBondsSmallMap = { lat: 0.0, lng: 10.2 };
let markers = [];
var desired_zoom_level = 17; //range 2-22

export default function Map({ customBackButton = false }) {
  const { height } = useWindowSize();
  const [state, dispatch] = useContext(MapContext);
  const { isNavShowing, setIsNavShowing } = useContext(NavContext);
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState(null);
  const [controlsChanged, setControlsChanged] = useState(false);
  const [streetView, setStreetView] = useState(false);
  const [bounds, setBounds] = useState(new google.maps.LatLngBounds());
  const [boundsChanged, setBoundsChanged] = useState(false);
  const zoomMapQuery3 = useMediaQuery({
    query:
      "(min-width: 768px) and (max-width: 1024px) and (orientation : portrait), (min-width: 1024px) and (max-width: 1366px)",
  });
  const zoomMapQuery2 = useMediaQuery({ query: "(max-width: 600px)" });
  const confirm = useConfirm();
  const [gpsModal, openGpsModal] = useState(false);
  const [deviceHistoryDates, setDeviceHistoryDates] = useState([]);
  const [deviceHistoryDatesRaw, setDeviceHistoryDatesRaw] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [historyDayLoading, setHistoryDayLoading] = useState(false);
  const [gpsUserPosition, setGpsUserPosition] = useState({});
  const [bearerToken, setBearerToken] = useState(null);
  const [roadPathData, setRoadPathData] = useState(null);
  const [directions, setDirections] = useState([]);
  const [staticImgUrl, setStaticImgUrl] = useState(null);
  const [showStaticImage, setShowStaticImage] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [historyDate, setHistoryDate] = useState(
    moment(Date.now()).format("YYYY/M/DD")
  );
  const [availableDates, setAvailableDates] = useState([]);
  const [disabledDates, setDisabledDates] = useState([]);
  const [pickerState, setPickerState] = useState(new Date());

  useEffect(async () => {
    if (historyLoading) return;

    const deviceHistoryData = await deviceHistoryDatesRaw?.map((d) => {
      return new DateTime.fromSeconds(parseInt(d?.slice(6, 16))).toJSDate();
    });

    deviceHistoryData && setAvailableDates(deviceHistoryData);
  }, [historyLoading]);

  useEffect(() => {
    var getDaysArray = function (start, end) {
      for (
        var arr = [], dt = new Date(start);
        dt <= new Date(end);
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt));
      }
      return arr;
    };
    if (!availableDates || !availableDates?.length) {
      setDisabledDates(
        getDaysArray(
          new Date(new Date().getFullYear(), 0, 1), //first of hte year
          new Date(new Date().getFullYear(), 11, 31) //last of the year
        )
      );
      return;
    } else {
      setDisabledDates([]);
    }

    if (availableDates?.length < 2) return;
    const datesInRange = getDaysArray(
      availableDates?.[0],
      availableDates[availableDates?.length - 1]
    );
    setDisabledDates(
      datesInRange
        .map((date) => {
          if (
            availableDates.some(
              (element) => element.getTime() === date.getTime()
            )
          )
            return null;
          else return date;
        })
        .filter(Boolean)
    );
    setPickerState(availableDates[availableDates?.length - 1]);
  }, [availableDates]);

  useEffect(() => {
    setBearerToken(window.localStorage.getItem("bearerToken"));
  }, []);

  useEffect(() => {
    const init = new google.maps.Map(document.getElementById("map"), {
      center:
        state.centerSmallMap === false
          ? new google.maps.LatLng(defaultBonds.lat, defaultBonds.lng)
          : new google.maps.LatLng(
              defaultBondsSmallMap.lat,
              defaultBondsSmallMap.lng
            ),
      zoom: 3,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      gestureHandling: "greedy",
      mapId: "67fa8b61f3dfc470",
      panControl: false,
      panControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      rotateControl: false,
      rotateControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoomControl: true,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.SMALL,
      },

      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP,
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        mapTypeIds: ["roadmap", "satellite"],
      },
      streetViewControl: true,
      streetViewControlOptions: {
        //  position: google.maps.ControlPosition.LEFT_TOP,
      },
    });

    //const trafficLayer = new google.maps.TrafficLayer();
    //trafficLayer.setMap(init);

    setMap(init);
    setLoading(false);

    setTimeout(function () {
      google.maps.event.addListener(init, "bounds_changed", function () {
        setBoundsChanged(true);
      });
      google.maps.event.addListener(init, "mouseover", () => {
        setControlsChanged(true);
      });
      google.maps.event.addListener(init, "mouseup", () => {
        setControlsChanged(true);
      });
    }, 1500);
  }, []);

  function getPinIcon(marker) {
    var status = getStatus(marker.status);

    switch (status.pin) {
      case "ok":
        return "/pins/ok.png";
        break;
      case "warning":
        return "/pins/warning.png";
        break;
      case "critical":
        return "/pins/critical.png";
        break;
      case "movement":
        return "/pins/impact.png";
        break;
      case "in_progress":
        return "/pins/in_progress_shadow.png";
        break;
      default:
        break;
    }
  }

  google.maps.Map.prototype.clearOverlays = function () {
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    markers.length = 0;
  };
  function getZIndexValue(marker) {
    var status = getStatus(marker.status);
    switch (status.pin) {
      case "ok":
        return OK_INDEX;
        break;
      case "warning":
        return WARNING_INDEX;
        break;
      case "in_progress":
        return WIP_INDEX;
        break;
      case "movement":
        return MOVEMENT_INDEX;
        break;
      case "critical":
        return CRITICAL_INDEX;
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    if (map && bearerToken && state.markers.length) {
      let points = [];
      var init_bounds = new google.maps.LatLngBounds();
      state.markers && getDeviceSummary();
      map.clearOverlays();
      map.markers = [];
      state.markers
        .filter((x) => x.longitude !== 0)
        .map((marker) => {
          if (
            marker.longitude &&
            marker.latitude &&
            marker.assets &&
            marker.assets.length
          ) {
            if (marker.longitude !== 0 || marker.latitude !== 0) {
              const data = {
                assetId: marker.assets[0].id,
                assetName: marker.assets[0].name,
                longitude: marker.longitude,
                latitude: marker.latitude,
                lastUpdated: marker.status_updated_time,
                status: marker.status,
                devicetypeid: marker.devicetype_id,
                cell_accuracy: marker.cell_accuracy,
              };
              var image = {
                url: getPinIcon(data),
                size: new google.maps.Size(25, 34),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(12, 34),
              };

              var pinTopLayerImage = {
                url: marker.photo
                  ? marker.photo.url + `?ss-tok=${bearerToken}`
                  : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
                scaledSize: new google.maps.Size(34, 34),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 44.25),
              };

              var pinBottomLayerImage = {
                url: "/pins/pinWatch2.png",
                scaledSize: new google.maps.Size(56, 64.5),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(28, 51),
              };

              const timeAgo = moment(data.lastUpdated).fromNow();
              const infowindow = new google.maps.InfoWindow({
                content: popoverTemplate
                  .replace(/{assetName}/g, data.assetName)

                  .replace(/{lastUpdated}/g, timeAgo)
                  .replace(/{status}/g, data.status)
                  .replace(/{statusName}/g, getStatus(data.status).name)
                  .replace(/{icon}/g, getStatus(data.status).icon),
                closeBoxURL: "",
              });

              var marker = new google.maps.Marker({
                position: { lat: data.latitude, lng: data.longitude },
                map: map,
                icon: pinTopLayerImage,
                assetId: data.assetId,
                zIndex: 2,
                title: data?.assets?.[0]?.name,
              });

              var backgroundMarker = new google.maps.Marker({
                position: { lat: data.latitude, lng: data.longitude },
                map: map,
                optimized: true,
                icon: pinBottomLayerImage,
                zIndex: 1,
                assetId: data.assetId,
              });

              data.cell_accuracy !== undefined &&
                new google.maps.Circle({
                  strokeColor: "#4e4e4e",
                  strokeOpacity: 0.8,
                  strokeWeight: 1.3,
                  fillColor: "#777777",
                  fillOpacity: 0.15,
                  map,
                  center: { lat: data.latitude, lng: data.longitude },
                  radius: data.cell_accuracy,
                });

              marker.addListener("click", () => {
                if (!state.isolatedMarker) {
                  history.push(`/assets/${marker.assetId}`);
                }
              });

              /*marker.addListener('rightclick', () => {
              openInNewTab(`/assets/${marker.assetId}`);
            });*/

              marker.addListener("mouseover", () => {
                infowindow.open(map, marker);
                //var x = document.getElementsByClassName('gm-ui-hover-effect')[0].remove();
              });
              marker.addListener("mouseout", () => {
                infowindow.close(map, marker);
              });
              init_bounds.extend({ lat: data.latitude, lng: data.longitude });
              markers.push(marker);
              if (data.longitude && data.latitude) {
                return points.push(marker);
              }
            }
          } else {
            //device with no asset
            var marker = new google.maps.Marker({
              position: { lat: marker.latitude, lng: marker.longitude },
              map: map,
              zIndex: parseInt(getZIndexValue(marker)),
            });
            points.push(marker);
          }
          return false;
        });

      if (points.length !== 0) {
        setBounds(init_bounds);
        setBoundsChanged(false);

        if (state.isolatedMarker) {
          var point = {
            lat: state.isolatedMarker[1],
            lng: state.isolatedMarker[0],
          };
          map.setTilt(45);
          map.setCenter(point);
          map.setZoom(desired_zoom_level);
        } else if (state.markers.length) {
          map.setTilt(45);
          map.fitBounds(init_bounds);
          map.setCenter(init_bounds.getCenter());
          var swLat = init_bounds.getSouthWest().lat();
          var swLng = init_bounds.getSouthWest().lng();
          var neLat = init_bounds.getNorthEast().lat();
          var neLng = init_bounds.getNorthEast().lng();
          var latDiff = Math.abs(swLat - neLat);
          var swDiff = Math.abs(swLng - neLng);
          var boundsThreshold = 75;

          if (latDiff > boundsThreshold || swDiff > boundsThreshold) {
            if (zoomMapQuery3) {
              map.setZoom(3);
            }
            if (zoomMapQuery2) {
              map.setZoom(2);
            }
          }
        } else {
        }
      }
    }
  }, [
    bearerToken,
    map,
    state.markers,
    state.isolatedMarker,
    state.displayGPSbutton,
    state.centerSmallMap,
    state.isMapShowing,
  ]);
  useEffect(() => {
    if ((!state.markers || !state.markers.length) && state.centerSmallMap) {
      map.center = new google.maps.LatLng(
        defaultBondsSmallMap.lat,
        defaultBondsSmallMap.lng
      );
      map.zoom = 2;
    }
  }, [state.centerSmallMap]);

  const showAllMarkers = () => {
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());
  };
  async function getDeviceSummary() {
    var res = await get(
      `/devices/${
        state.markers[0].id
      }/history_summary?timezoneOffset=${new Date().getTimezoneOffset()}`
    );
    if (res) {
      var string = "";
      var formatDateArray = [];
      res.dates &&
        res.dates.map((date) => {
          formatDateArray.push(moment(date).format("YYYY/M/DD"));
          string += moment(date).format("YYYY/M/DD") + "              ";
        });
      if (res?.dates) setDeviceHistoryDatesRaw(res.dates);
      setDeviceHistoryDates(formatDateArray);
      //alert(string);
      setHistoryLoading(false);
    }
  }
  async function getDeviceHistoryForDate(date) {
    setHistoryDate(date);
    showDeviceHistoryForDate(date);
  }
  async function showDeviceHistoryForDate(date) {
    setHistoryDayLoading(true);
    var res = await get(
      `/devices/${state.markers[0].id}/history_date?&date=${
        date ? date : historyDate
      }&timezoneOffset=${new Date().getTimezoneOffset()}`
    );
    drawHistoryOnMap(res);
  }

  async function drawHistoryOnMap(res) {
    setStaticImgUrl(null);
    setRoadPathData(null);
    const directionsRenderer = new google.maps.DirectionsRenderer();
    directionsRenderer.setMap(map);
    var legs = [];
    var init_bounds = new google.maps.LatLngBounds();
    var directions = new google.maps.DirectionsService();
    if (res) {
      var cellhistory = [];
      var counter = 0;
      var previousPosition = { lat: 0, lng: 0 };
      var allMarkers = [];
      var parts = [];
      if (res.length > 0) {
        res.map((marker) => {
          if (marker.latitude !== 0 && marker.longitude !== 0) {
            allMarkers.push({ lat: marker.latitude, lng: marker.longitude });
          }
        });
      }
      // Divide route to several parts because max limit is 25 (23 waypoints + 1 origin + 1 destination)
      for (var i = 0, parts = [], max = 25 - 1; i < res.length; i = i + max) {
        parts.push(allMarkers.slice(i, i + max + 1));
      }
      for (var i = 0; i < parts.length; i++) {
        // Waypoints does not include first location (origin) and last (destination)
        var waypoints = [];
        for (var j = 1; j < parts[i].length - 1; j++)
          waypoints.push({ location: parts[i][j], stopover: false });

        // Send request
        directions.route(
          {
            origin: parts[i][0],
            destination: parts[i][parts[i].length - 1],
            waypoints: waypoints,
            //draggable: true,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              const dr = new google.maps.DirectionsRenderer();
              dr.setMap(map);
              dr.setOptions({ suppressMarkers: true, preserveViewport: true });
              dr.setDirections(result);

              legs.push(result.routes[0].legs[0]);
              setRoadPathData(legs);
            } else {
              alert(`error fetching directions ${result}`);
            }
          }
        );
      }
      res.length > 0 &&
        res.map((history) => {
          if (history.latitude !== 0 && history.longitude !== 0) {
            const randomColor = Math.floor(Math.random() * 16777215).toString(
              16
            );
            document.body.style.backgroundColor = "#" + randomColor;
            let randomColorFinal = "#" + randomColor;

            var mIcon = {
              path: google.maps.SymbolPath.CIRCLE,
              fillOpacity: 0.2,
              fillColor: "#fff",
              strokeOpacity: 0.7,
              strokeWeight: 1,
              strokeColor: randomColorFinal,
              scale: 12,
            };
            var Historymarker = new google.maps.Marker({
              position: { lat: history.latitude, lng: history.longitude },
              map: map,
              icon: mIcon,
              label: {
                color: "#000",
                fontSize: "12px",
                fontWeight: "600",
                text: counter.toString(),
              },
            });
            if (previousPosition.lat !== 0) {
              const lineSymbol = {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeOpacity: 1,
                strokeWeight: 1,
              };
              const dash = {
                path: "M 0,-1 0,1",
                strokeOpacity: 0.8,
                scale: 4,
              };
              var flightPath = new google.maps.Polyline({
                path: [
                  { lat: previousPosition.lat, lng: previousPosition.lng },
                  { lat: history.latitude, lng: history.longitude },
                ],
                geodesic: true,
                strokeColor: "#376fbd",
                strokeOpacity: 0,
                icons: [
                  {
                    icon: lineSymbol,
                    offset: "100%",
                  },
                  {
                    icon: dash,
                    offset: "0",
                    repeat: "20px",
                  },
                ],
              });
              flightPath.setMap(map);
            }
            counter++;

            previousPosition = {
              lat: history.latitude,
              lng: history.longitude,
            };

            init_bounds.extend({
              lat: history.latitude,
              lng: history.longitude,
            });
            const timeAgo = moment.utc(history.created_time).fromNow();
            const when = moment
              .utc(history.created_time)
              .format("MMMM Do YYYY [at] h:mma");
            const infowindow = new google.maps.InfoWindow({
              content: popoverHistoryTemplate
                .replace(/{city}/g, history.city)
                .replace(/{when}/g, when)
                .replace(/{timeago}/g, timeAgo)
                .replace(/{address}/g, history.address)
                .replace(/{cellid}/g, history.cell_tower_id)
                .replace(/{zip}/g, history.zip)
                .replace(
                  /{cell_lat}/g,
                  history.latitude ? history.latitude : ""
                )
                .replace(
                  /{cell_lon}/g,
                  history.longitude ? history.longitude : ""
                )
                .replace(
                  /{gps_lat}/g,
                  history.cell_latitude ? history.cell_latitude : ""
                )
                .replace(
                  /{gps_lon}/g,
                  history.cell_longitude ? history.cell_longitude : ""
                ),
              closeBoxURL: "",
            });

            Historymarker.addListener("mouseover", () => {
              infowindow.open(map, Historymarker);
            });
            Historymarker.addListener("mouseout", () => {
              infowindow.close(map, Historymarker);
            });

            if (
              cellhistory.some(
                (prev) => history.cell_latitude === prev.cell_latitude
              )
            ) {
            } else {
              if (history.cell_latitude !== 0 && history.cell_longitude !== 0) {
                history.cell_latitude !== 0 &&
                  new google.maps.Circle({
                    strokeColor: "#dce7f7",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: randomColorFinal,
                    fillOpacity: 0.15,
                    map,
                    center: {
                      lat: history.cell_latitude,
                      lng: history.cell_longitude,
                    },
                    radius: history.cell_accuracy,
                  });
                history.cell_latitude !== 0 &&
                  init_bounds.extend({
                    lat: history.cell_latitude,
                    lng: history.cell_longitude,
                  });
                cellhistory.push(history);
              }
            }
          }
        });

      res.length > 0 && map.fitBounds(init_bounds);
      //map.setBounds(init_bounds);
    }
    setHistoryDayLoading(false);
  }

  async function showDeviceHistory(days) {
    if (state.markers != []) {
      var res = await get(
        `/devices/${
          state.markers[0].id
        }/history?days=${days}&timezoneOffset=${new Date().getTimezoneOffset()}`
      );
      console.log(res);
      console.log(res);
      drawHistoryOnMap(res);
    }
  }

  const centerOnIsolatedMarker = () => {
    var point = { lat: state.isolatedMarker[1], lng: state.isolatedMarker[0] };
    map.setCenter(point);
    map.setZoom(desired_zoom_level);
    setBoundsChanged(false);
  };
  const centerOnEmptyMapSmall = () => {
    var point = { lat: 32.0, lng: -10.2 };
    map.setCenter(point);
    map.setZoom(desired_zoom_level);
    setBoundsChanged(false);
  };
  function _sendGpsRecalibrationRequest(id) {
    var res = put(`/devices/${id}/gps/1`);
    if (res) {
      confirm({
        description: "GPS will be recalibrated within 24 hours.",
        title: null,
        dialogProps: { fullScreen: false },
        cancellationButtonProps: { color: "error", disabled: true },
        confirmationButtonProps: { color: "primary", disableRipple: true },
      })
        .then(() => {})
        .catch(() => {
          /* ... */
        });
    }
  }
  function openInNewTab(url) {
    const win = window.open(url, "_blank");
  }
  async function openStreetView() {
    if (streetView === false) {
      var point = {
        lat: state.isolatedMarker[1],
        lng: state.isolatedMarker[0],
      };
      const streetviewService = new google.maps.StreetViewService();
      await streetviewService.getPanorama(
        { location: point },
        (result, status) => {
          if (status === google.maps.StreetViewStatus.OK && result) {
            const panorama = new google.maps.StreetViewPanorama(
              document.getElementById("pano"),
              {
                position: point,
                panControl: false,
                linksControl: false,
              }
            );
            setStreetView(!streetView);
            map.setStreetView(panorama);
          } else {
            alert(
              "Unable to load Street View, please select the location manually."
            );
            setStreetView(false);
            map.setStreetView(null);
          }
        }
      );
    } else {
      setStreetView(!streetView);
      map.setStreetView(null);
    }
  }

  function _renderFooter(gpsUserPosition) {
    return (
      <>
        <Button onClick={() => openGpsModal(false)}>Cancel</Button>
        <Button
          onClick={() => putGpsPosition()}
          theme="primary"
          disabled={
            !gpsUserPosition.latitude ||
            !gpsUserPosition.longitude ||
            longlatValidate(gpsUserPosition.latitude, "lat") ||
            longlatValidate(gpsUserPosition.longitude, "lon")
          }
        >
          Save
        </Button>
      </>
    );
  }

  async function putGpsPosition() {
    var res = await put(`/devices/${state.markers[0].id}/gpsposition`, {
      latitude: gpsUserPosition.latitude,
      longitude: gpsUserPosition.longitude,
    });

    if (res.ok) {
      window.location.reload();
    } else {
      confirm({
        description: "Something went wrong, please try again.",
        title: null,
        dialogProps: { fullScreen: false },
        cancellationButtonProps: { color: "error", disabled: true },
        confirmationButtonProps: { color: "primary", disableRipple: true },
      })
        .then(() => {})
        .catch(() => {
          /* ... */
        });
    }
  }

  function longlatValidate(val, pos) {
    if (val) {
      if (pos === "lat") {
        return !val.match(
          /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
        );
      }
      if (pos === "lon") {
        return !val.match(
          /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
        );
      }
    } else {
      return null;
    }
  }

  function displayRoadData(data) {
    var totalLenght = 0;
    var totalDuration = 0;

    data &&
      data.map((item) => {
        totalLenght = totalLenght + item.distance?.value;
        totalDuration = totalDuration + item.duration?.value / 60;
      });

    return (
      <>
        <div className={styles.pathOverlay}>
          <div className={styles.pathOverlayBanner}>
            Distance: {Math.round(totalLenght * 0.00062137, 3)} miles /{" "}
            {Math.round(totalLenght / 1000, 2)} kilometers
            <br></br>
            Duration: {Math.round(totalDuration, 2)} minutes
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {showStaticImage && staticImgUrl ? (
        <div
          onClick={() => setShowStaticImage(false)}
          style={{
            position: "absolute",
            width: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            height: "100%",
            zIndex: 9,
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img style={{ height: 400, width: 600 }} src={staticImgUrl} />
        </div>
      ) : null}

      <div id="pano" className={streetView === true ? styles.pano : ""}></div>

      {!loading &&
        state.markers[0] &&
        !(state.markers[0].longitude || state.markers[0].latitude) &&
        (state.markers[0].status === "OK" ||
          state.markers[0].status === "WORK_IN_PROGRESS" ||
          state.markers[0].status === "MOVEMENT") && (
          <>
            {gpsModal && (
              <Modal small footer={_renderFooter(gpsUserPosition)}>
                <InputRow>
                  <Label text="Latitude">
                    <TextInput
                      placeholder="eg. 38.907192"
                      error={
                        longlatValidate(gpsUserPosition.latitude, "lat") &&
                        "Enter a valid latitude."
                      }
                      value={gpsUserPosition.latitude}
                      onChange={(e) => {
                        setGpsUserPosition({
                          ...gpsUserPosition,
                          latitude: e.target.value,
                        });
                      }}
                    />
                  </Label>
                  <Label text="Longitude">
                    <TextInput
                      error={
                        longlatValidate(gpsUserPosition.longitude, "lon") &&
                        "Enter a valid longitude."
                      }
                      value={gpsUserPosition.longitude}
                      placeholder="eg. -77.036873"
                      onChange={(e) => {
                        setGpsUserPosition({
                          ...gpsUserPosition,
                          longitude: e.target.value,
                        });
                      }}
                    />
                  </Label>
                </InputRow>
              </Modal>
            )}

            <div className={styles.mapNoGpsOverlay}>
              <div className={styles.mapNoGpsOverlayBanner}>
                <div className={styles.mapNoGpsOverlayText}>
                  GPS is being recalibrated
                  <div className={styles.coordinatesButtonContainer}>
                    <Button
                      onClick={() => openGpsModal(true)}
                      theme="primary"
                      icon="add"
                    >
                      Add coordinates
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      {roadPathData && displayRoadData(roadPathData)}

      <div
        id="map"
        className={streetView === true ? styles.map : styles.mapFull}
      ></div>

      {!loading &&
        state.isolatedMarker &&
        state.markers[0] &&
        state.markers[0].longitude &&
        state.markers[0].latitude && (
          <>
            <div className={styles.bottom}>
              <div
                style={{
                  display: showCalendar ? "unset" : "none",
                }}
              >
                <Calendar
                  onChange={(item) => {
                    if (historyDayLoading) return;
                    setPickerState(item);
                    getDeviceHistoryForDate(moment(item).format("YYYY/M/DD"));
                  }}
                  date={pickerState}
                  minDate={availableDates?.[0]}
                  maxDate={availableDates?.[availableDates?.length - 1]}
                  disabledDates={disabledDates}
                />
              </div>

              <Button
                isLoading={historyLoading || historyDayLoading}
                disabled={historyLoading || historyDayLoading}
                margin={8}
                icon="history"
                onClick={() => setShowCalendar(!showCalendar)}
              >
                History
              </Button>

              {state.isolatedMarker && state.markers[0] && (
                <Button
                  margin={8}
                  icon="streetview"
                  onClick={() => openStreetView()}
                >
                  Street View
                </Button>
              )}
              {state.isolatedMarker
                ? boundsChanged === true && (
                    <Button
                      icon="pin_drop"
                      onClick={() => centerOnIsolatedMarker()}
                    >
                      Center on asset
                    </Button>
                  )
                : state.markers[0] && (
                    <Button
                      icon="zoom_out_map"
                      onClick={() => showAllMarkers()}
                    >
                      View all
                    </Button>
                  )}
            </div>
          </>
        )}

      {customBackButton === false ? (
        <div
          className={[styles.hideButton, styles.backButtonContainer].join(" ")}
        >
          <Button icon="arrow_back" onClick={() => dispatch(toggleMap())}>
            Back
          </Button>
        </div>
      ) : null}
      <div className={[styles.hideButton, styles.menu].join(" ")}>
        <Button icon="menu" onClick={() => setIsNavShowing(!isNavShowing)}>
          Menu
        </Button>
      </div>
      {loading && (
        <div className={styles.loading}>
          <Loading color="gray" size={36} />
        </div>
      )}
    </>
  );
}
