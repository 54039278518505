import React from 'react';
import { NavLink } from 'react-router-dom';
import { SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER } from '../../constants/roles';
import { isAuthorized, hasPermission } from '../../helpers/authorization';
import LogoIcon from '../LogoIcon';
import NavItem from '../NavItem';
import { useMediaQuery } from 'react-responsive'
import styles from './Navigation.module.scss';
import TTLogo from '../../../src/images/tt_logo_black.svg';
function AssetPageNavigation(user) {
  const isDesktop = useMediaQuery({ query: '(min-width: 1500px)' })

  var assets_link = isDesktop === true ? "/assets_dashboard" : "/assets";

  return (isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) && (
    <NavLink className={styles.navItemLink} to={assets_link}>
      <NavItem icon="diamond" title="Assets" />
    </NavLink>
  ));
}

export default function Navigation({ user, alarmCount }) {
  const isTablet = useMediaQuery({ query: '(min-width: 726px)' })
  return (
    <nav className={styles.nav}>
      <NavLink className={styles.logoLink} to="/" exact>
        <img src={TTLogo} alt="Tresor Track Logo" />
      </NavLink>
      {isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) && (
        <NavLink className={styles.navItemLink} to="/map">
          <NavItem icon="pageview" title="Dashboard" />
        </NavLink>
      )}

      {AssetPageNavigation(user)}

      {isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN]) && (
        <NavLink className={styles.navItemLink} to="/devices">
          <NavItem icon="blur_circular" title="TresorTracks" />
        </NavLink>
      )}
      {isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) && (
        <NavLink className={styles.navItemLink} to="/alarms">
          <NavItem
            icon="notifications"
            title="Alerts"
            notification={alarmCount}
          />
        </NavLink>
      )}

      {isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) && (
        <NavLink className={styles.navItemLink} to="/reports">
          <NavItem icon="poll" title="Reports" />
        </NavLink>
      )}

      {isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) &&
        hasPermission(user.perms, 'ADMIN') && <div className={styles.break} />}


      {isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN]) && (
        <NavLink className={styles.navItemLink} to="/customers">
          <NavItem icon="work" title="Customers" />
        </NavLink>
      )}


      {/*isAuthorized(user.roles, [CUSTOMER_USER]) &&
        hasPermission(user.perms, 'ADMIN') && (
          <NavLink className={styles.navItemLink} to={`/customer`}>
            <NavItem icon="work" title="Customer"  />
          </NavLink>
        )*/}

      {isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN]) && (
        <NavLink className={styles.navItemLink} to="/users">
          <NavItem icon="group" title="Users" />
        </NavLink>
      )}
      {isAuthorized(user.roles, [TENANT_ADMIN]) && (
        <NavLink className={styles.navItemLink} to="/admin_portal">
          <NavItem icon="settings" title="Admin" />
        </NavLink>
      )}

      <NavLink className={styles.accountLink} to="/account">
        <NavItem icon="person" title="Account" />
      </NavLink>
    </nav>
  );
}
