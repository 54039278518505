import React, { useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from '../../hooks/inputs';
import { useFetch } from '../../hooks/fetch';
import { AuthContext } from '../../contexts/Auth';
import { TENANT_ADMIN, CUSTOMER_USER,SYS_ADMIN } from '../../constants/roles';
import { isAuthorized,hasPermission } from '../../helpers/authorization';
import Button from '../../components/Button';
import FrameHeader from '../../components/FrameHeader';
import RowItem from '../../components/RowItem';
import Frame from '../../components/Frame';
import LoadingFrame from '../../components/LoadingFrame';
import QueryBar from '../../components/QueryBar';
import TextInput from '../../components/TextInput';
export default function CustomerGroups({ match: { params } }) {
  const { state } = useContext(AuthContext);
  const [query, setQuery] = useState('');
  const [sortBy] = useState('-created_time');
  const debouncedQuery = useDebounce(query, 350);

  const [data, loading /* , error */] = useFetch(`/groups`, {
    nameContains: debouncedQuery,
    orderBy: sortBy,
    customer_id: params.id,
    include_users: '1'
  });

  function _renderContent() {
    if (loading && !data) {
      return <LoadingFrame />;
    } else {
      return (
        <>
          <RowItem
            title="Default group"
            to={`/customers/${params.id}/groups/default`}
          />
          {data.results.length
            ? data.results.map(g => (
                <RowItem
                  key={g.id}
                  title={g.name}
                  to={`/customers/${params.id}/groups/${g.id}`}
                />
              ))
            : null}
        </>
      );
    }
  }

  return (
    <>
      <FrameHeader title="Groups">
        <Link to={`/customers/${params.id}/groups/new`}>
        {isAuthorized(state.user.roles, [SYS_ADMIN,TENANT_ADMIN, CUSTOMER_USER]) && hasPermission(state.user.perms, 'ADMIN') && (<Button theme="primary" icon="add">
            New group
          </Button>)}
        </Link>
      </FrameHeader>
      <Frame padding="0">
        <QueryBar button={false}>
          <TextInput
            value={query}
            placeholder={`Search by name`}
            icon="search"
            loading={loading && data}
            onChange={e => {
              setQuery(e.target.value);
            }}
          />

        </QueryBar>

        {_renderContent()}
      </Frame>
    </>
  );
}
