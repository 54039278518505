import React, { useContext, useEffect, useState } from 'react';
import TypesContext from '../../contexts/Types';
import { get, post, put } from '../../api';
import { AuthContext } from '../../contexts/Auth';
import { CUSTOMER_USER, SYS_ADMIN, TENANT_ADMIN } from '../../constants/roles';
import FrameHeader from '../../components/FrameSectionHeader/FrameSectionHeader';
import Space from '../../components/Space';
import styles from './RReports.module.scss';
import Frame from '../../components/Frame';
import RowItemActions from '../../components/RowItemActions';
import InputRow from '../../components/InputRow';
import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import { date } from '../../helpers/date';
import { DateRange, DateRangePicker, Calendar, DefinedRange } from 'react-date-range';
import Loading from '../../components/Loading/Loading';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,

} from 'chart.js';
import Image from '../../components/Image';
import { Radar, Line } from 'react-chartjs-2';
import { DateTime, Interval } from "luxon";
import SingleColumnPage from '../../components/SingleColumnPage/SingleColumnPage';
import { formLabelClasses } from '@mui/material';

export default function RRaports({ history }) {
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setselectedDevice] = useState(undefined);
  const [selectedDay, setselectedDay] = useState(undefined);
  const [deviceSummary, setDeviceSummary] = useState(undefined);
  const [availableDates, setAvailableDates] = useState([]);
  const [disabledDates, setDisabledDates] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [tripSummary, setTripSummary] = useState({});

  async function fetchActiveDevices() {
    const devices = await get('/devices', {
      active: 'true',
      include_assets: '1',
    });

    if (devices && devices.results) {
      setDevices(devices?.results)
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchActiveDevices();
    return () => {
    };
  }, []);

  const getDeviceSummary = async id => {
    setselectedDay(undefined)
    var res = await get(`/devices/${id}/history_summary?timezoneOffset=${(new Date()).getTimezoneOffset()}`);
    if (res?.dates) {
      setDeviceSummary(res.dates.reverse())
    }
    else {
      setDeviceSummary(null)
    }
  }

  useEffect(() => {
    if (selectedDevice?.id) getDeviceSummary(selectedDevice?.id)
    setDeviceSummary([])
    setAvailableDates([])
    setShowImg(false)
  }, [selectedDevice]);

  useEffect(() => {
    if (!deviceSummary || !deviceSummary?.length) return
    setAvailableDates(
      deviceSummary?.map(sm => {
        return new DateTime.fromSeconds(parseInt(sm?.slice(6, 16))).toJSDate()
      })
    )
  }, [deviceSummary]);

  useEffect(() => {
    if (showImg && selectedDevice?.id && pickerState) {
      post(`/reports`, {
        device_id: selectedDevice?.id,
        timezone_offset: new Date().getTimezoneOffset(),
        start_date: formatDate(pickerState),
        end_date: formatDate(pickerState),
        without_map: true,
        force_update: true,
      }).then(res => {
        if (!res) {
          return
        }
        try {
          const _trip_addresses = JSON.parse(res?.trip_addresses)
          setAddresses(_trip_addresses)
        }
        catch (e) { console.log(e) }

        setTripSummary({
          distance: `${(res?.trip_distance / 1000).toFixed(2)} km`,
          duration: `${(res?.trip_duration / 60).toFixed(0)} min`,
          speed: `${((res?.trip_distance / res?.trip_duration) * 3.6).toFixed(0)} km/h`,
          file: res?.file?.url,
        })
      }).catch((e) => {

        console.log(e)

      });
    }
  }, [showImg]);

  useEffect(() => {
    if (!availableDates || !availableDates?.length) return

    var getDaysArray = function (start, end) {
      for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
      }
      return arr;
    };

    if (availableDates?.length < 2) return
    const datesInRange = getDaysArray(availableDates[availableDates?.length - 1], availableDates?.[0])
    setDisabledDates(
      datesInRange.map(date => {
        if (availableDates.some((element) => element.getTime() === date.getTime())) return null
        else return date
      }).filter(Boolean)
    )
    setPickerState(availableDates?.[0])

  }, [availableDates]);

  const [pickerState, setPickerState] = useState(
    new Date
  );

  useEffect(() => {
    setImageLoaded(false)
    setShowImg(false)

  }, [pickerState]);

  useEffect(() => {
    setTripSummary({})
    setAddresses([])
  }, [pickerState, selectedDevice]);


  const formatDate = (date) => {
    return DateTime.fromJSDate(date).toFormat('yyyy.M.dd')
  }

  const getHistoryByDate = async () => {
    var res = await get(`/devices/${selectedDevice?.id}/history_date?&date=${date(selectedDay, 'summary')}&timezoneOffset=${(new Date()).getTimezoneOffset()}`);

  }

  const addressesTimeline = () => {
    return addresses.map((a, i) => {
      return <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px 0',
        // border: i%2==0? undefined : '0.5px lightgrey solid',
        borderTop: '0.5px #e7e7e7 solid'
      }}>
        <span>{a?.address}, {a?.city}, {a?.state} {a?.zip}</span>
        <span>
          {
            new DateTime.fromISO(a?.created_time, { zone: 'UTC' }).toLocal().toFormat('h:mm a')
          }
        </span>
      </div>
    })
  }
function breadcrumbs(){
  return (<Breadcrumb title="Reports" to={'/reports'}/>)
}
  return (loading ? (<Loading color="gray" />):(<SingleColumnPage breadcrumbs={breadcrumbs}>
  
    <Frame padding={0}>
      {devices?.map((d, i) => {
        const activeDevice = d === selectedDevice

return (<InputRow noPadding={true}><RowItemActions
                    key={d.id}
                    size="l"
                    to={`/reports/${d.id}`}
                    rightspace={true}
                    title={d.name}
                    
                   
                  /></InputRow>)
      
        return (
          <div
           className={styles.row}
            onClick={() => setselectedDevice(d)}
            key={d.id}
            style={{ background: activeDevice ? '#f8f8f8' : undefined }}
          >
            <div style={{ marginRight: 20 }}>
              <Space size="s">
                {d?.name} ({d?.id})
              </Space>
              <Link to={`/reports/${d.id}`}><Button>Details</Button></Link>
              {activeDevice && availableDates?.length ?
                <Calendar
                  onChange={item => setPickerState(item)}
                  date={pickerState}
                 maxDate={availableDates[0]}
                 minDate={availableDates[availableDates?.length - 1]}
                 disabledDates={disabledDates}
                />
                :
                activeDevice ?
                  <Loading />
                  : null
              }
            </div>

            {activeDevice && availableDates?.length ?
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                flexDirection: 'column'
              }}>

                <div>
                  <div>{tripSummary?.distance}</div>
                  <div>{tripSummary?.duration}</div>
                  <div>{tripSummary?.speed}</div>
                  <div>{tripSummary?.file}</div>
                  {tripSummary?.file && <Image url={tripSummary?.file} zoom={true} />}
                </div>
                <br />
                <div>
                  {addresses?.length ? addressesTimeline() : null}
                </div>
                <br />
                {/*showImg ?
                  <>
                    <div
                      style={{
                        display: imageLoaded ? 'none' : 'flex',
                        width: 256,
                        height: 192,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Loading color="gray" />
                    </div>
                    <img
                      width={256}
                      height={192}
                      onLoad={() => setImageLoaded(true)}
                      style={{ display: !imageLoaded ? 'none' : undefined }}
                      src={`https://justemp.online/himg/${selectedDevice?.id}/${formatDate(pickerState)}/${new Date().getTimezoneOffset()}`}
                    />
                  </>
                  :
                  <div
                    style={{
                      width: 256,
                      height: 192,
                    }}
                  />
                  */}
                <button onClick={() => setShowImg(true)}>
                  Show report for {DateTime.fromJSDate(pickerState).toFormat('M/dd/yyyy')}
                </button>
              </div>
              : null}
          </div>
        )
      }
      )}
    </Frame>
  </SingleColumnPage>))  
}