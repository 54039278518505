import React from 'react';
import styles from './Frame.module.scss';

export default function Frame({ children, padding = 24 }) {
  return (
    <section className={styles.frame} style={{ padding }}>
      {children}
    </section>
  );
}
