import React, { useState, useEffect } from 'react';
import Button from '../Button';

import CheckboxWithLabel from '../CheckboxWithLabel';
import styles from './UniversalFilter.module.scss';
import LoadingFrame from '../LoadingFrame';
export default function UniversalFilter({ onChange, inputArray, inputFilter, onRefresh,dataLoading,unselectAll=true,onlyDefault=false }) {
  const [filters, setFilters] = useState(inputFilter);
  const [loading, setLoading] = useState(false);
//alert(filters);
  function getArrayLenght() {
    var i = 0;
    filters && filters.map(type => { i++; });
    return i;
  }
 
  function filterNotEmpty() {
    var empty = true;
    filters.map(id => (empty = false))
    return empty;
  }

  async function unselectSelection() {
    inputArray.map(type => {
      (changeTypes(type.id, false))
    })
    statuses="NONE";
    onChange(statuses);
    onRefresh();
  }

  async function selectNotAssigned(){
    var array = filters;
    for(let i=0;i<inputArray.lenght;i++){
      delete array[i];
    }
    array=[];
    array.push(1);
    setFilters(array);
    statuses="1";
    onChange(statuses);
    //onRefresh();
  }

  async function resetSelection() {
    inputArray.map(type => {
      filterNotEmpty() == false ? (filters.indexOf(type.id) < 0 && (modifyTypes(type.id, true)))
        : (filters.includes(type.id) == false && filters.push(type.id))

      if (!statuses.includes(type.id)) {
        statuses += type.id + ",";
      }
    })
    const all = getArrayLenght() >= inputArray.length;
    onChange(all ? '' : statuses.slice(0, -1));
    onRefresh();
  }
  async function modifyTypes(e, value, shouldRefresh) {
    setLoading(true);
    if (value == true) {
      var array = filters;
      var index = array.indexOf(e);
      if (index < 0) {
        array.push(e);
        setFilters(array);
      }
    } else {
      var array = filters;
      var index = array.indexOf(e);
      delete array[index];
      setFilters(array);
    }
    inputArray.map(type => {
      if (filters.indexOf(type.id) >= 0) {
        statuses += type.id + ",";
      }
    })
    const all = getArrayLenght() >= inputArray.length;
    onChange(all ? '' : statuses.slice(0, -1));
    setLoading(false);
    shouldRefresh == true && onRefresh(true);
  }
   function changeTypes(e, value, shouldRefresh) {
    
    if (value == true) {
      var array = filters;
      var index = array.indexOf(e);
      if (index < 0) {
        array.push(e);
        setFilters(array);
      }
    } else {
      var array = filters;
      var index = array.indexOf(e);
      delete array[index];
      setFilters(array);
    }
    
  }
  let statuses = '';
  useEffect(() => {
    if (inputArray != undefined) {
      inputArray.map(type => {
        if (filters.indexOf(type.id) >= 0) {
          statuses += type.id + ",";
        }
      })

      const all = getArrayLenght() >= inputArray.length;
      onChange(all ? '' : statuses.slice(0, -1));
    }
  }, [filters]);

  return (loading ? (<LoadingFrame />) : (
    <>
      {unselectAll && <div className={styles.spacer}> <Button key={1 + (Math.random() * (100 - 1))} size='s' disabled={dataLoading} theme='secondary' onClick={e => resetSelection()}>Select all</Button>  <Button size='s' disabled={dataLoading} theme='secondary' onClick={e => unselectSelection()}>Select none</Button></div>}
      {onlyDefault && <div className={styles.spacer}> <Button key={1 + (Math.random() * (100 - 1))} size='s' disabled={dataLoading} theme='secondary' onClick={e => resetSelection()}>Select all</Button>  <Button size='s' disabled={dataLoading} theme='secondary' onClick={e => selectNotAssigned()}>Not assigned</Button></div>}
      {inputArray.map((type,i) =>
          <div key={i} className={styles.filter}>
            {<CheckboxWithLabel
              key={type.id}
              label={type.name}
              checked={filters.indexOf(type.id) > -1 ? (true) : (false)}
              onChange={e => modifyTypes(type.id, e.target.checked, true)}
              disabled={dataLoading}
            />}
          </div>
      )}
    </>));
}