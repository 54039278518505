import React from 'react';
import { MapReducer, initialMapState } from '../../reducers/Map';
import useThunk from '../../hooks/thunk';
import MapContext from '../../contexts/Map';
import HistoryMap from '../../components/HistoryMap';
export default function HistoryMapDisplay({ match: { params } }) {
  const [state, dispatch] = useThunk(MapReducer, initialMapState);

  return (<MapContext.Provider value={[state, dispatch]}>
    <HistoryMap
      deviceId={params.deviceId}
      reportId={params.reportId}
    />
  </MapContext.Provider>
  );
}