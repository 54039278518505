import React, { useContext, useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../../contexts/Auth';
import { useFetch } from '../../hooks/fetch';
import CustomerContext from '../../contexts/Customer';

import NewUser from '../UserNewCustomerUser';
import Info from '../CustomerInfo';
import UserCustomerGroups from '../UserCustomerGroups';
import NewGroup from '../UserNewCustomerGroup';
import DefaultGroupDetail from '../DefaultGroupDetail';
import GroupDetail from '../UserGroupDetail';
import CustomerSites from '../CustomerSites';
import UserCustomerUsers from '../UserCustomerUsers';
import UserUserInfo from '../UserUserInfo';
import Settings from '../CustomerSettings';
import CustomerDevices from '../CustomerDevices';
import CustomerSitesNew from '../CustomerSitesNew';
import Breadcrumb from '../../components/Breadcrumb';
import LoadingFrame from '../../components/LoadingFrame';
import SingleColumnPage from '../../components/SingleColumnPage';
import SubNavigation from '../../components/SubNavigation';
import SiteDetail from '../SiteDetail';


export default function CustomerIndex({ history }) {
  const { state } = useContext(AuthContext);
  const [data, loading] = useFetch(`/customers/${state.user.customer_id}`);
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    var isApiSubscribed = true;
    if (isApiSubscribed) {
      setCustomer(data);
    }
    return () => {
      isApiSubscribed = false;
    };
  }, [data]);

  const breadcrumbs = () => (
    <Breadcrumb title={customer && customer.name} />
  );

  const subNav = [
    { to: `/customer/info`, name: 'Info' },
    { to: `/customer/devices`, name: 'Devices' },
    { to: `/customer/sites`, name: 'Sites' },
    { to: `/customer/groups`, name: 'Notification groups' },
    { to: `/customer/users`, name: 'Users' }
  ];

  return (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      {loading ? (
        <LoadingFrame />
      ) : (
        <>
          <SubNavigation items={subNav} history={history} />
          <CustomerContext.Provider value={{ customer, setCustomer }}>
            <Switch>
              <Redirect from="/customer" to="/customer/info" exact />
              <Route path="/customer/info" component={Info} />
              <Route
                path="/customer/groups"
                component={UserCustomerGroups}
                exact
              />
              <Route path="/customer/groups/new" component={NewGroup} exact />
              <Route
                path="/customer/groups/default"
                component={DefaultGroupDetail}
                exact
              />

              <Route path="/customer/devices" component={CustomerDevices} />
              <Route
                path="/customer/groups/:groupId"
                component={GroupDetail}
                exact
              />
              <Route
                path="/customer/sites"
                component={CustomerSites}
                exact
              />
              <Route
                path="/customer/sites/new"
                component={CustomerSitesNew}
                exact
              />
              <Route
                path="/customer/site/:siteId"
                component={SiteDetail}
                exact
              />
              <Route
                path="/customer/users"
                component={UserCustomerUsers}
                exact
              />
              <Route path="/customer/users/new" component={NewUser} exact />
              <Route
                path="/customer/users/:id"
                component={UserUserInfo}
                exact
              />
              <Route path="/customer/settings" component={Settings} />
            </Switch>
          </CustomerContext.Provider>
        </>
      )}
    </SingleColumnPage>
  );
}
