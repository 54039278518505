import React, { useState, useContext, useEffect } from 'react';
import AlarmsContext from '../../contexts/Alarms';
import { fetchAlarms } from '../../actions/Alarms';
import { setMarkers } from '../../actions/Map';
import { get, post, upload } from '../../api';
import AssetContext from '../../contexts/Asset';
import MapContext from '../../contexts/Map';
import { MOVEMENT, INSPECTION, NOTE, MINOR_REPAIR, MINOR_WITH_NO_REPAIR, MAJOR } from '../../constants/statuses';
import { fetchAsset, fetchActivity } from '../../actions/Asset';
import history from '../../helpers/history';
import Button from '../../components/Button';
import CheckboxWithLabel from '../../components/CheckboxWithLabel';
import FileUploader from '../../components/FileUploader';
import FileUploadProgress from '../../components/FileUploadProgress';
import InputRow from '../../components/InputRow';
import Label from '../../components/Label';
import LoadingFrame from '../../components/LoadingFrame';
import Modal from '../../components/Modal';
import Icon from '../../components/Icon';
import Space from '../../components/Space';
import styles from './AssetInspectionWizard.module.scss';
import queryString from "query-string";
import { DefaultEditor } from 'react-simple-wysiwyg';
import TextInput from '../../components/TextInput';
import { ASSET_NOTE } from '../../constants/events';
import { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Select from '../../components/Select';
import moment from 'moment';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { is } from 'date-fns/locale';
import { useMediaQuery } from 'react-responsive'
import { useConfirm } from 'material-ui-confirm';

export default function AssetInspectionWizard({ match: { params }, location: { search } }) {

  const queryParams = queryString.parse(search);
  const [state, dispatch] = useContext(AssetContext);
  const [, mapDispatch] = useContext(MapContext);
  const [, alarmsDispatch] = useContext(AlarmsContext);
  const [report, setReport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [body, setBody] = useState('');
  const [referenceEventId, setReferenceEventId] = useState(null);
  const [events, setEvents] = useState([]);
  const [damage, setDamage] = useState("");
  const [severity, setSeverity] = useState("");
  const [type, setType] = useState('');
  const [orderNumbers, setOrderNumbers] = useState({
    work_order: "",
    service_request: "",
    cost: "",
    repair_complete_time: "",
  })
  const [attachments, setAttachments] = useState([]);
  const [alarms, setAlarms] = useState([]);
  const [step, setStep] = useState(-2);
  const [clearAlarms, setClearAlarms] = useState(false);
  const FORMAT = 'MM/dd/yyyy';
  const isSmallMobile = useMediaQuery({ query: '(max-width: 374px)' })
  const confirm = useConfirm();
  useEffect(() => {

    async function getEventsWithoutInspections() {
      var id = "";
      if (queryParams.asset === undefined) {
        id = state.asset.id;
      } else {
        id = queryParams.asset
      }
      const events = await get(`/events/no_inspection/${id}`, {
      });
      events && setEvents(events);
      /* if (events && events.length === 1) {
         setReferenceEventId(events[0].id);
       }*/
      queryParams.event && setReferenceEventId(Number(queryParams.event));
      setLoading(false);
    }

    async function fetchAlarm() {
      const alarms = await get('/alarms', {
        entity_type: 'DEVICE',
        entity_id: state.device.id,
        statuses: `ACTIVE_UNACK,ACTIVE_ACK`
      });
      const impactAlarms = alarms.results.filter(a => a.type === MOVEMENT);
      setAlarms(impactAlarms);
    }

    fetchAlarm();
    getEventsWithoutInspections()

  }, []);

  function onChange(e) {
    setBody(e.target.value);
  }
  function decodeHTML(content) {
    return (
      <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
    );
  }
  function option(label, value) {
    const active = damage === value;
    const classes = [styles.label, active ? (value === "NONE" ? styles.labelActiveNone : styles.labelActiveDamage) : ""].join(" ");

    return (
      <label className={classes}>
        <div className={styles.img}>
          <div className={styles[value]}>
            <Icon name={value === "NONE" ? "check_circle" : "error"} size={56} />
          </div>
        </div>
        <div className={styles.text}>{active ? <b>{label}</b> : label}</div>
        <input
          type="radio"
          name="damage"
          value={value}
          onChange={e => setDamage(e.target.value)}
          checked={active}
        />
      </label>
    );
  }

  function stepOption(label, explanation, value) {
    const active = type === value;
    const classes = [styles.label, active && styles.labelActive].join(" ");

    return (
      <label className={classes}>
        <div className={styles.img}>
          <div className={styles[value]}>
            <Icon name={value === NOTE ? "attach_email" : "taxi_alert"} size={56} />
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.flexRow}><div className={styles.text}>{active ? <b>{label}</b> : label}</div></div>
          <div className={styles.flexRow}><div className={styles.sub_text}><p>{explanation}</p></div></div>
        </div>
        <input
          type="radio"
          name="damage"
          value={value}
          onChange={e => setType(e.target.value)}
          checked={active}
        />
      </label>
    );
  }

  function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }

  function DateChange(desireddate) {
    desireddate && setOrderNumbers({ ...orderNumbers, repair_complete_time: desireddate.getMonth() + 1 + "/" + desireddate.getDate() + "/" + desireddate.getFullYear() });
  }

  function parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  function costValidate() {
    return (!orderNumbers.cost.match(/^\s{0,1}\d*[.,]{0,1}\d*[.,]{0,1}\d*$/));
  }

  function displaySeverity() {
    if (severity !== "" && severity!==undefined) {
      switch (severity) {
        case MINOR_WITH_NO_REPAIR: return "1-Minor with no repair";
        case MINOR_REPAIR: return "2-Minor repair";
        case MAJOR: return "3-Major, Severe, Replacement";
      }
    } else {
      return "0-No issues";
    }
  }

  function renderContent() {
    switch (step) {
      case -2:
        return (
          <>
            <Space size="s">
              <p>Step 1: What would you like to do?</p>
            </Space>
            <div className={styles.center}>
              <InputRow>
                <div className={styles.wrap}>
                  {stepOption(
                    "Create an inspection report for a recent impact alarm",
                    "Choose this option if you want to add a work order or service request number, repair cost, notes, or upload photos for a recent impact alarm.",
                    INSPECTION
                  )}

                  {stepOption(
                    "Add a note or upload photos",
                    "Choose this option is you want to add a note or upload photos of the asset.",
                    NOTE
                  )}


                </div>
              </InputRow>

            </div>
          </>
        );
      case -1:
        return (
          <>
            <Space size="s">
              <p>Step 2: Select the impact date associated with this report.</p>
            </Space>
            <div>
              {events && events.length > 0 && (<div className={styles.alarmSelect}>
                {events.map(event => (<>
                  <div className={styles.button, referenceEventId === event.id && styles.alarmSelected} onClick={() => setReferenceEventId(event.id)}>
                    <div className={styles.alarm}> Movement - {isSmallMobile? moment(event.created_time).format('MMM Do YYYY [at] h:mma') : moment(event.created_time).format('MMMM Do YYYY [at] h:mma')}
                    </div>
                  </div>
                </>))}
              </div>)}
            </div>
          </>
        );
      case 0:
        return (
          <>
            <Space size="s">
              <p>Step 3: Is the asset damaged?</p>
            </Space>
            <div className={styles.center}>
              <InputRow>
                <div className={styles.wrap}>
                  {option("Asset damaged", "DAMAGED")}
                  {option("Asset not damaged", "NONE")}
                </div>
              </InputRow>

              {damage === "DAMAGED" && (<>
                <InputRow>
                    <Label text="Severity">
                      <Select onChange={e => setSeverity(e.target.value)} value={severity}>
                        <option disabled value={''}>Select severity level</option>
                        <option value={MINOR_WITH_NO_REPAIR}>1-Minor with no repair</option>
                        <option value={MINOR_REPAIR}>2-Minor repair</option>
                        <option value={MAJOR}>3-Major, Severe, Replacement</option>
                      </Select>
                    </Label>
                    <Space />
                </InputRow>
              </>)}
              <div>
              </div>
            </div>
          </>
        );
      case 1:
        return (<>
          <Space size="s">
            <p>Step 4: Enter work order number, service request number, and any additional notes.</p>
          </Space>

          <div className={styles.center}>
            <InputRow>
              <Label text="Work order number">
                <TextInput
                  keyboardType="numeric"
                  type="number"
                  spinHide={true}
                  value={orderNumbers.work_order}
                  onChange={e => setOrderNumbers({ ...orderNumbers, work_order: e.target.value })}
                />
              </Label>
              <Label text="Service request number">
                <TextInput
                  keyboardType="numeric"
                  type="number"
                  spinHide={true}
                  value={orderNumbers.service_request}
                  onChange={e => setOrderNumbers({ ...orderNumbers, service_request: e.target.value })}
                />
              </Label>
            </InputRow> <InputRow>
              <Label text="Repair cost">
                <TextInput
                  keyboardType="numeric"
                  type="text"
                  error={costValidate() === true && "Only a digits, comma and dot are allowed"}
                  spinHide={true}
                  value={orderNumbers.cost}
                  onChange={e => setOrderNumbers({ ...orderNumbers, cost: e.target.value })}
                />
              </Label>

              <Label text="Repair completion date">
                <div className={styles.wizzardPickerWrapper}>
                  <DayPickerInput
                    formatDate={formatDate}
                    format={FORMAT}
                    //value={`Click to select`}
                    parseDate={parseDate}
                    classNames={styles.input}
                    style={styles.input}
                    placeholder={`Click to select`}
                    onDayChange={DateChange}
                    autoComplete="off"
                  />
                </div>
              </Label>

            </InputRow>
          </div>
          <Space size="s">
            <p>Notes</p>
          </Space>
          <div className={styles.center}>
            <Space size="m">
              <div className={styles.wyswigOuterWrapper}>
                <DefaultEditor
                  className={styles.wyswigWrapper}
                  value={body}
                  onChange={onChange} /></div>
            </Space>
          </div>
        </>);

      case 2:
        return (<>
          <Space size="s">
            <p>Step 5: Upload photos.</p>
          </Space>
          <Space size="m">
            <Space size="m">
              <FileUploader
                onError={errMsg => alert(errMsg)}
                onSelect={files => saveFile([files])}
              />
            </Space>
            <FileUploadProgress
              preview={true}
              files={attachments}
              uploading={saving}
              onRemove={i => _removeFile(i)}
            />
          </Space>
        </>);

      case 3:
        return (<>
          <Space size="s">
            <p>Step 6: Additional options.</p>
          </Space>
          <Space size="s">
            <Label text="Email report" />
            <CheckboxWithLabel
              label="Send this report via email"
              description={`This report will be emailed to everyone in the notification group.`}
              checked={report}
              onChange={e => setReport(e.target.checked)}
              fullwidth
            />
          </Space>
          <Space size="s">
            {!queryParams.cleared && (
              alarms.length ? (
                <div className={styles.clearAlarm}>
                  <Label text="Alarms" />
                  <CheckboxWithLabel
                    fullwidth={true}
                    label="Clear all impact alarms"
                    description={`If the inspection reveals that the asset and device are in working condition, you can clear any impact alarms on this device. This will reset the device status to "OK" allowing monitoring to continue.`}
                    checked={clearAlarms}
                    onChange={e => setClearAlarms(e.target.checked)}
                  />
                </div>
              ) : null)}
          </Space></>);

      case 4:
        return (<>
          <Space size="s">
            <p>Step 7: Review.</p>
          </Space>
          <Space size="m">
            <div className={styles.border}>
              <InputRow>
                <Label text="Asset name" theme="light">
                  {state.asset.name && <h4>{state.asset.name}</h4>}
                </Label>
                <Label text="Asset status" theme="light">
                  <h4>{damage === "DAMAGED" ? "Asset damaged" : "Asset not damaged"}</h4>
                </Label>
                <Label text="Severity" theme="light">
                  <h4>{displaySeverity()}</h4>
                </Label>
              </InputRow>
            </div>
          </Space>
          <Space size="m">
            <div className={styles.border}>
              <InputRow>
                <Label text="Work order number" theme="light">
                  <p>{orderNumbers.work_order}</p>
                </Label>
                <Label text="Service request number" theme="light">
                  <p>{orderNumbers.service_request}</p>
                </Label>
              </InputRow>
              <InputRow>
                <Label text="Repair cost" theme="light">
                  {orderNumbers.cost && <p>${orderNumbers.cost}</p>}
                </Label>
                <Label text="Repair completion date" theme="light">
                  <p>{orderNumbers.repair_complete_time}</p>
                </Label>
              </InputRow>
            </div>
          </Space>
          <Space size="m">
            <div className={[styles.border, styles.noteReview].join(' ')}>
              <Label text="Notes" theme="light">
                {decodeHTML(body)}
              </Label>
              <Space size="s" />
            </div>
          </Space>
          <Space size="m">
            <div className={styles.border}>
              <Label text="Attachments" theme="light">
                {attachments.map(att => (
                  <div className={styles.row}> {att.name} </div>
                ))}
              </Label>
            </div>
          </Space>
          <Space size="m">
            <div className={styles.border}>
              <InputRow>
                <Label text="Email report" theme="light">
                  {report ? <h4>Yes</h4> : "No"}</Label>
                {!queryParams.cleared && (
                  clearAlarms && <Label text="Clear all impact alarms" theme="light">
                    <h4>Yes</h4>
                  </Label>)}
              </InputRow>
            </div>
          </Space>
        </>);

      case 5:
        return (<>
          <Space size="s">
            <p>Step 2: Notes and photos.</p>
          </Space>
          <Space size="m">
            <Label text="Note" />
            <div className={styles.center}>
              <Space size="m">
                <div className={styles.wyswigOuterWrapper}>
                  <DefaultEditor
                    className={styles.wyswigWrapperSmall}
                    value={body}
                    onChange={onChange}
                  />
                </div>
              </Space>
            </div>
          </Space>
          <Space size="m">
            <Label text="Upload photos" />
            <Space size="m">
              <FileUploader
                onSelect={files => saveFile([files])}
              />
            </Space>
            <FileUploadProgress
              preview={true}
              files={attachments}
              uploading={saving}
              onRemove={i => _removeFile(i)}
            />
          </Space>
        </>);
    }
  }

  async function _saveInspection() {
    setSaving(true);
    const event = await post(`/inspections`, {
      asset_id: state.asset.id,
      damage,
      body,
      work_order: orderNumbers.work_order !== "" && orderNumbers.work_order,
      service_request: orderNumbers.service_request !== "" && orderNumbers.service_request,
      cost: orderNumbers.cost !== "" && orderNumbers.cost,
      repair_complete_time: orderNumbers.repair_complete_time,
      reference_event_id: referenceEventId && referenceEventId,
      severity: severity,
      //  report: report,
      clear_alarms: clearAlarms
    });

    let attachmentReqs = [];

    attachments.forEach(attachment => {
      const formData = new FormData();
      formData.set('entity_type', 'INSPECTION');
      formData.set('entity_id', event.reference_id);
      formData.set('type', 'IMAGE');
      formData.append('file', attachment);
      const res = upload(formData);
      attachmentReqs.push(res);
    });
    await Promise.all(attachmentReqs);

    if (state.device !== false) {
      const device = await get(`/devices/${state.device.id}`, {
        include_assets: '1'
      });
      alarmsDispatch(fetchAlarms());
      mapDispatch(setMarkers([device]));
    }
    dispatch(fetchActivity(state.asset.id, state.activity.filters, 1));
    dispatch(fetchAsset(state.asset.id));
    report && await post(`/inspection/${event.reference_id}/report`)
    history.push(`/assets/${state.asset.id}`);
  }

  async function saveFile(attachment) {
    if (attachment != undefined) {
      const isArray = Array.isArray(attachment);
      if (isArray) {
        var firstElement = attachment[0];
        if (firstElement !== undefined && firstElement.name !== undefined) {
          setAttachments([...attachments, ...attachment])
        } else {
          setAttachments([...attachments, ...firstElement])
        }
      } else {
        var newArray = [attachment]
        setAttachments([...attachments, ...newArray])
      }
    } else {
      alert('File saving error!');
    }
  }

  async function _removeFile(i) {

    if (attachments != undefined) {
      const newAttachments = attachments
        .slice(0, i)
        .concat(attachments.slice(i + 1, attachments.length));

      setAttachments(newAttachments);
    } else {
      alert('delete error');
    }
  }

  function nextStep() {
    if (type === INSPECTION) {
      if (step <= 3) {
        setStep(step + 1);
      } else {
        _saveInspection();
      }
    } else {
      if (step !== 5) {
        setStep(5);
      } else {
        _saveNote();
      }
    }
  }

  async function _saveNote() {
    setSaving(true);

    const event = await post(`/events`, {
      type: ASSET_NOTE,
      ...(state.device.id && { device_id: state.device.id }),
      asset_id: state.asset.id,
      body
    });

    let attachmentReqs = [];

    attachments.forEach(attachment => {
      const formData = new FormData();
      formData.set('entity_type', 'EVENT');
      formData.set('entity_id', event.id);
      formData.set('type', 'IMAGE');
      formData.append('file', attachment);
      const res = upload(formData);
      attachmentReqs.push(res);
    });

    await Promise.all(attachmentReqs);

    dispatch(fetchActivity(state.asset.id, state.activity.filters, 1));
    history.push(`/assets/${state.asset.id}`);
  }

  function quit() {
    confirm({
      description: 'Are you sure you want to cancel?',
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        history.push(`/assets/${state.asset.id}`);
      })
      .catch(() => { /* ... */ });
  }

  function validateStep(step) {
    switch (step) {
      case -2: return type !== "";
      case -1: return referenceEventId !== null;
      case 0:
        if (damage !== "" && severity !== '') {
          return true;
        }else if(damage==='DAMAGED' && severity===''){
          return false;
        }
        else if (damage !== "" && severity === '') {
          return true;
        }else{
          return false
        }

      //return damage !== "";
      case 1: return !costValidate();
      case 2: return true;
      case 3: return true;
      case 4: return true;
      case 5: return (body !== '' || attachments.length !== 0);
    }
  }

  function _renderFooter() {
    return (
      <>
        {step < -1 ? (
          <Button onClick={() => quit()}>Cancel</Button>
        ) : (<Button disabled={saving} onClick={() => setStep(step <= 4 ? step - 1 : -2)}>Back</Button>)}

        <Button theme="primary" onClick={nextStep} isLoading={saving} disabled={(step <= 3 || step == 5) ? !validateStep(step) : saving} >
          {step <= 3 ? "Next" : "Save"}
        </Button>
      </>
    );
  }

  return (
    <Modal footer={_renderFooter()}>
      {!state.asset.id || loading ? (
        <LoadingFrame />
      ) : (<>
        <Space size="l">
          <h2>New report</h2>
        </Space>
        {renderContent()}
      </>)}
    </Modal>
  );
}
