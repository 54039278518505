import React from "react";

import Label from "../Label";

import ImgFlat from "../../images/orientations/flat.png";
import ImgHorizontal from "../../images/orientations/horizontal.png";
import ImgVertical from "../../images/orientations/vertical.png";

import styles from "./OrientationOptions.module.scss";

export default function OrientationOptions({ selected, setSelected,label=true }) {
  function option(img, label, value) {
    const active = selected === value;
    const classes = [styles.label, active ? styles.labelActive : ""].join(" ");

    return (
      <label className={classes}>
        <div className={styles.img}>
          <img src={img} alt={label} />
        </div>
        <div className={styles.text}>{label}</div>
        <input
          type="radio"
          name="orientation"
          value={value}
          onChange={e => setSelected(e.target.value)}
          checked={active}
        />
      </label>
    );
  }

  return (
    <>
      {label && (<Label text="Install orientation" />)}

      <div className={styles.wrap}>
        {option(ImgFlat, "Mounted flat", "flat")}
        {option(ImgVertical, "Mounted vertically", "vertical")}
        {option(ImgHorizontal, "Mounted horizontally", "horizontal")}
      </div>
    </>
  );
}
