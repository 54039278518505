import React from 'react';
import { Link } from 'react-router-dom';
import Center from '../Center';
import Frame from '../Frame';
import Space from '../Space';
import Logo from '../Logo';
import TTLogo from '../../../src/images/tt_logo_black.svg';
import styles from './LoginFrame.module.scss';
export default function LoginFrame({
  children,
  heading,
  subHeading,
  showForgotPassword,
  showSignUp=true
}) {
  return (<>

    <div className={styles.smallcenter}>
      <div className={styles.logoWrap}>
        <img src={TTLogo} alt="Tresor Track Logo" />
      </div>
    </div>
    <Center>
      <main className={styles.frameWrap}>
        <Frame>
          <Space/>
          {(heading || subHeading) && (
            <Space size="xl">
              <h2 className={styles.heading}>{heading}</h2>
              <p>{subHeading}</p>
            </Space>
          )}

          {children}
        </Frame>

        {showForgotPassword && (
          <div>
            <Link className={styles.forgotPassword} to="/forgot">
              Forgot password?
            </Link>
          </div>
        )}

        
      </main>
    </Center>
    </>);
}
