import React, { useState } from 'react';
import Frame from '../../components/Frame';
import Label from '../../components/Label';
import InputRow from '../../components/InputRow';
import FrameHeader from '../../components/FrameHeader';
import Space from '../../components/Space';
import { post } from '../../api';
import TextInput from '../../components/TextInput';
import Button from "../../components/Button";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
export default function AdminPortalFunctions() {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('HEARTBEAT');
  const [json, setJson] = useState('');
  const [serial_number, setSerialNumber] = useState('999999999999999');
  const [deviceIdToClear, setDeviceIdToClear] = useState(0);
  const [customerId, setCustomerId] = useState(0);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  async function sendRequest() {
    setLoading(true);
    var json = { "serial_number": serial_number, "imei": "999999999999999", "iccid": "999999999999999", "firmware": "1", "modem_firmware": "mfw_nrf9160_1.2.0", "mac": "0", "records": [{ "sequence_number": 16, "reason": value, "device_time": "datetoupdate", "fields": [{ "latitude": 37.274035, "longitude": -121.862684, "accelerometer_x": 0.000000, "accelerometer_y": 10.000000, "accelerometer_z": 0.000000, "temperature": 16.0, "signal": 40, "battery": 3500 }] }] }
    var response = await fetch('https://tresortrackfunction01.azurewebsites.net/api/TresorTrackEndpoint1', { method: "POST", body: JSON.stringify(json) });
    setJson(JSON.stringify(json));
    setLoading(false);
  }
  async function clearDevice() {
    setLoading(true);

    var response = await post(`/devices/${deviceIdToClear}/change_owner`, { customer_id: customerId });

    setLoading(false);
  }

  return (
    <>
      <FrameHeader title="Functions" />
      <Frame padding={8}>
        <InputRow>
          <Space>
            <FormControl component="fieldset">
              <FormLabel component="legend">Type</FormLabel>
              <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                <FormControlLabel value="HEARTBEAT" control={<Radio />} label="HEARTBEAT" />
                <FormControlLabel value="MOVEMENT" control={<Radio />} label="MOVEMENT" />
              </RadioGroup>
            </FormControl>
          </Space>
          <Space>
            <Label text="Serial number">
              <TextInput value={serial_number} onChange={e => setSerialNumber(e.target.value)}></TextInput>
            </Label>
          </Space>



          <Space>
            <Button onClick={() => sendRequest()} disabled={loading}>Send request</Button>
          </Space>
        </InputRow>
        <TextInput value={json} onChange={e => setJson(e.target.value)}></TextInput>
      </Frame>
      <Space />
      <Frame>
        <InputRow>
          <Label text="Device Id to clear">
            <TextInput value={deviceIdToClear} onChange={e => setDeviceIdToClear(e.target.value)}></TextInput>
          </Label>

          {/*<Label text="CustomerId">
            <TextInput value={customerId} onChange={e => setCustomerId(e.target.value)}></TextInput>
  </Label>*/}
        </InputRow>
        <Button onClick={() => clearDevice()} disabled={loading || deviceIdToClear == 0 }>Clear device</Button>

      </Frame>
    </>
  );
}
