import { get, destroy, put } from '../api';

export const fetchAsset = id => async dispatch => {
  dispatch({ type: 'INCREMENT_LOADING' });

  const { asset, device } = await _fetchAsset(id);
  if (asset != null && asset != undefined) {
    dispatch({ type: 'UPDATE_ASSET', payload: { asset } });
    dispatch({ type: 'UPDATE_DEVICE', payload: { device } });

    const installPhoto = await _fetchInstallPhoto(asset.id);
    dispatch({ type: 'UPDATE_INSTALL_PHOTO', payload: { installPhoto } });

    dispatch({ type: 'DECREMENT_LOADING' });
  }
};

export const saveAsset = assetForm => async (dispatch, getState) => {
  dispatch({ type: 'UPDATE_SAVING', payload: { saving: true } });

  const { asset: currentAsset } = getState();
  await put(`/assets/${currentAsset.id}`, { ...currentAsset, ...assetForm });
  const { asset, device } = await _fetchAsset(currentAsset.id);
  if (asset != null && asset != undefined) {
  dispatch({ type: 'UPDATE_ASSET', payload: { asset } });
  dispatch({ type: 'UPDATE_DEVICE', payload: { device } });
  dispatch({ type: 'UPDATE_SAVING', payload: { saving: false } });
  }
};

export const fetchRelation = (assetId, deviceId) => async dispatch => {
  dispatch({ type: 'INCREMENT_LOADING' });

  const res = await get(`/relations`, {
    from_type: 'ASSET',
    from_id: assetId,
    to_type: 'DEVICE',
    to_id: deviceId
  });

  const relation = res && res.results && res.results.length && res.results[0];

  dispatch({ type: 'UPDATE_RELATION', payload: { relation } });
  dispatch({ type: 'DECREMENT_LOADING' });
};

export const removeRelation = (assetId, relationId) => async dispatch => {
  dispatch({ type: 'INCREMENT_LOADING' });

  await destroy(`/relations/${relationId}`);
  const { asset } = await _fetchAsset(assetId);

  dispatch({ type: 'UPDATE_ASSET', payload: { asset } });
  dispatch({ type: 'UPDATE_DEVICE', payload: { device: {} } });
  dispatch({ type: 'REMOVE_RELATION' });
  dispatch({ type: 'DECREMENT_LOADING' });
};

export const updateRelation = (assetId, relation) => async dispatch => {
  dispatch({ type: 'INCREMENT_LOADING' });
  dispatch({ type: 'UPDATE_RELATION', payload: { relation } });

  const { asset, device } = await _fetchAsset(assetId);
  if (asset != null && asset != undefined) {
  dispatch({ type: 'UPDATE_ASSET', payload: { asset } });
  dispatch({ type: 'UPDATE_DEVICE', payload: { device } });

  const installPhoto = await _fetchInstallPhoto(asset.id);
  dispatch({ type: 'UPDATE_INSTALL_PHOTO', payload: { installPhoto } });

  dispatch({ type: 'DECREMENT_LOADING' });
  }
};

export const fetchActivity = (assetId, filters, page,perPage=500) => async dispatch => {
  dispatch({ type: 'UPDATE_ACTIVITY_LOADING', payload: { loading: true } });

  const types = Object.entries(filters)
    .map(f => f[1] && f[0])
    .filter(Boolean)
    .join(',');

  const res = await get(`/assets/events`, {
    asset_id: assetId,
    include_files: '1',
    types,
    orderBy: '-created_time',
    take: perPage,
    skip: (page - 1) * perPage,
    include: 'COUNT(*)'
  });

  dispatch({
    type: 'UPDATE_EVENTS',
    payload: { events: res.results, page, total: res.total }
  });

  dispatch({ type: 'UPDATE_ACTIVITY_LOADING', payload: { loading: false } });
};

export const updateActivityPage = page => dispatch => {
  dispatch({ type: 'UPDATE_ACTIVITY_PAGE', payload: { page } });
};

export const updateActivityFilters = filters => dispatch => {
  dispatch({ type: 'UPDATE_ACTIVITY_FILTERS', payload: { filters } });
};

/** Private */
/** --------------------------------------------------------------- */

async function _fetchAsset(id) {
  const res = await get(`/assets/${id}`, { include_devices: '1' });
  if (res != null && res != undefined) {
    const { devices, ...asset } = res;
    const device = devices && devices.length > 0 && devices[0];

    return { asset, device };
  } else {
    return { undefined, undefined };
  }
}

async function _fetchInstallPhoto(id) {
  const files = await get(`/files`, {
    entity_type: 'ASSET',
    entity_id: id,
    take: 1,
    orderBy: '-created_time'
  });

  return files.results.length && files.results[0];
}
