import React, { useEffect, useState } from 'react';
import Button from '../Button';
import styles from './FileUploader.module.scss';

export default function FileUploader({ onSelect }) {
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.addEventListener('dragover', _preventDefault, false);
    window.addEventListener('drop', _preventDefault, false);

    return () => {
      if (error && error.timer) clearTimeout(error.timer);
      window.removeEventListener('dragover', _preventDefault, false);
      window.removeEventListener('drop', _preventDefault, false);
    };
  }, [error]);

  function _preventDefault(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function _dragOver(e) {
    _preventDefault(e);
    setDragging(true);
  }

  function _chooseFiles(e) {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

    // prettier-ignore
    const files = (e.dataTransfer && e.dataTransfer.files) || (e.target && e.target.files);
    const valid = Array.from(files).every(f => validTypes.includes(f.type));

    if (valid) {
      setDragging(false);
      _addToSelection(files);
    } else {
      e.target.value = '';

      const timer = setTimeout(() => {
        setError(null);
        setDragging(false);
      }, 5000);

      const message = 'Please ensure each file is a JPG, PNG, or GIF';
      setError({ timer, message });
    }
  }


/*  function displayError(msg){
    const timer = setTimeout(() => {
      setError(null);
      setDragging(false);
    }, 5000);

    const message = 'Please ensure each file is a JPG, PNG, or GIF';
    setError({ timer, message });
  }*/
  
  async function _addToSelection(selection) {
    const selectedFiles = Array.from(selection).map(file => file);
    //const isArray = Array.isArray(selectedFiles);
    onSelect(selection);
  }

  function _dragLeave(e) {
    setDragging(false);
  }

  function _renderDragging() {
    return (
      <div className={`${styles.state} ${styles.dragging}`}>
        <h3 className={styles.dropFileText}>Drop file to upload</h3>
      </div>
    );
  }

  function _renderUploadArea() {
    return (
      <div className={styles.uploadArea}>
        <div className={styles.uploadButton}>

       
    
        <Button icon="cloud_upload">Upload</Button>
          <input
            multiple
            className={styles.input}
            onChange={_chooseFiles}
            onClick={onInputClick}
            type="file" name="myImage" accept="image/*"
            id="uploadFile"
          />
           {/* 
         <FilePicker
            onChange={onSelect}
            extensions={['jpg', 'jpeg', 'png', 'gif', 'bmp']}
            onError={errMsg => (displayError(errMsg))}
            input={{ multiple: true }}
          >
            <Button icon="cloud_upload">Upload</Button>
          </FilePicker>  
  */}

        </div>
      </div>
    );
  }
 const onInputClick = (event) => {
    event.target.value = ''
}
  function _renderError() {
    return (
      <div className={`${styles.state} ${styles.error}`}>
        <h3>Oops! Something went wrong.</h3>
        <p>{error.message}</p>
      </div>
    );
  }

  return (
    <>
      <div
        className={`${styles.wrap} ${error && styles['wrap--error']}`}
        onDragOver={_dragOver}
        onDragEnter={_dragOver}
        onDragLeave={_dragLeave}
        onDrop={_chooseFiles}
      >
        {_renderUploadArea()}

        
         


        {dragging && _renderDragging()}
        {error && _renderError()}
      </div>
    </>
  );
}
