import React, { useContext, useState, useEffect } from 'react';

import {
  normalizePermissions,
  denormalizePermissions
} from '../../helpers/user';
import { put, get } from '../../api';
import UserContext from '../../contexts/User';

import UserInfoForm from '../../components/UserInfoForm';
import FrameHeader from '../../components/FrameHeader';
import LoadingFrame from '../../components/LoadingFrame';

// import styles from './TenantInfo.module.scss';

export default function UserInfo() {
  const { user, setUser } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    async function getPermissions() {
      const { permissions } = await get(`/users/${user.id}/permissions`);

      setPermissions(denormalizePermissions(permissions));
      setLoading(false);
    }

    getPermissions();
  }, []);

  const updateUser = async (user, perms) => {
    setSaving(true);

    setPermissions(perms);

    const [userReq /*, permsReq */] = await Promise.all([
      put(`/users/${user.id}`, { ...user }),
      put(`/users/${user.id}/permissions`, {
        permissions: normalizePermissions(perms)
      })
    ]);

    setUser(userReq);
    setSaving(false);
  };

  return (
    <>
      <FrameHeader title="Info" />

      {loading ? (
        <LoadingFrame />
      ) : (
        <UserInfoForm
          state={user}
          permissions={permissions}
          saving={saving}
          onSave={updateUser}
        />
      )}
    </>
  );
}
