import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { put, get,post, upload } from '../../api';
import assetTypesSelect from '../../helpers/assetTypes';
import TypesContext from '../../contexts/Types';
import { AuthContext } from '../../contexts/Auth';
import history from '../../helpers/history';
import Button from '../../components/Button';
import Label from '../../components/Label';
import Modal from '../../components/Modal';
import InputRow from '../../components/InputRow';
import TextInput from '../../components/TextInput';
import Select from '../../components/Select';
import Space from '../../components/Space';
import LoadingFrame from '../../components/LoadingFrame';
import AssetContext from '../../contexts/Asset';
import { hasPermission,isAuthorized } from "../../helpers/authorization";
import { fetchAsset } from '../../actions/Asset';
import styles from "./AssetEdit.module.scss";
import Image from '../../components/Image';
import Frame from '../../components/Frame';
import { SYS_ADMIN, TENANT_ADMIN} from '../../constants/roles';
export default function AssetEdit({ match: { params } }) {
  const [state, dispatch] = useContext(AssetContext);
  const { state: currentUser } = useContext(AuthContext);
  const types = useContext(TypesContext);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [sites, setSites] = useState([]);
  const [newPhoto, setNewPhoto] = useState(null);
  const [saving, setSaving] = useState(false);
  const [asset, setAsset] = useState({});
  const [newSite, setNewSite] = useState('');
  const [newNotificationGroup, setNewNotificationGroup] = useState('');
  useEffect(() => {
    async function fetchGroupsAndSites() {
      const [asset, groups, sites] = await Promise.all([
        get(`/assets/${params.assetId}`),
        get(`/users/${currentUser.user.user_id}/groups`),
        get(`/assets/sites`),
      ]);
      asset && setAsset(asset);

      if (sites && sites.results && sites.results.length) {
        setSites(sites.results);
      }

      if (groups && groups.results && groups.results.length) {
        setGroups(groups.results);
      }
      if (asset && isAuthorized(currentUser.user.roles, [SYS_ADMIN, TENANT_ADMIN])) {
        const [groups_for_admin, sites_for_admin] = await Promise.all([
          get(`/customers/${asset.customer_id}/groups`),
          get(`/sites/${asset.customer_id}`),
        ]);
        if (sites_for_admin && sites.results && sites.results.length) {
          setSites(sites_for_admin.results);
        }
  
        if (groups_for_admin) {
          setGroups(groups_for_admin);
        }
      }
      
      setLoading(false);
    }
    fetchGroupsAndSites();
  }, []);


  function valid() {
    return asset.name !== '';
  }

  function _renderFooter() {
    return (
      <>
        {state.asset && <Link to={`/assets/${state.asset.id}`}>
          <Button>Cancel</Button>
        </Link>}

        {
          (hasPermission(currentUser.user.perms, "ASSET_U") || hasPermission(currentUser.user.perms, "ADMIN"))
          &&
          <Button
            theme="primary"
            onClick={saveEdits}
            isLoading={saving}
            disabled={!valid}
          >
            Save
          </Button>
        }
      </>
    );
  }
  async function _chooseFiles(e) {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    const files = (e.dataTransfer && e.dataTransfer.files) || (e.target && e.target.files);
    const valid = Array.from(files).every(f => validTypes.includes(f.type));
    if (valid) {
      setNewPhoto(files[0]);

    } else {
      alert('Please select valid photo file!');
    }
  }

  function returnImage() {
    if (newPhoto !== null) {
      return (<Image url={URL.createObjectURL(newPhoto)} zoom={true} token={false} />);
    } else {
      if (state.installPhoto.id) {
        return (
          <Image url={state.installPhoto.url} zoom={true} />);
      }
      else {
        return (
          <Image url="/img/photo_placeholder.jpg" />);
      }
    }
  }

  async function saveEdits() {
    setSaving(true);
    const formData = new FormData();
    formData.set('entity_type', 'ASSET');
    formData.set('entity_id', asset.id);
    formData.set('type', 'IMAGE');
    formData.append('file', newPhoto);
    const res = await upload(formData);

    const update = await put(`/assets/${asset.id}`, asset);
    dispatch(fetchAsset(state.asset.id));
    history.push(`/assets/${state.asset.id}`);
  }


  async function createNewSite(newSiteName){
    var newSite = await post('/site/new', {
      name:newSiteName,
      customer_id: parseInt(currentUser.user.customer_id)
    });

    if (newSite && newSite.id === -1) {
      alert("A site with this name already exists.");
    } else {
      if (newSite.id !== 0) {
        const [sites] = await Promise.all([
          get(`/assets/sites`)
        ]);
        if (sites && sites.results && sites.results.length) {
          setSites(sites.results);
        }
        setNewSite('')
        setAsset({ ...asset, site_id: newSite.id })
      }
    }
  }

  async function createNewNotificationGroup(newNotificationGroupName) {
    var newGroup = await post('/groups', {
      name:newNotificationGroupName,
      tenant_id: currentUser.user.tenant_id,
      customer_id: parseInt(currentUser.user.customer_id)
    });

    if (newGroup && newGroup.id === -1) {
      alert("A notification group with this name already exists.");
    } else {
      if (newGroup.id !== 0) {
        const [groups] = await Promise.all([
          get(`/users/${currentUser.user.user_id}/groups`),
        ]);
        if (groups && groups.results && groups.results.length) {
          setGroups(groups.results);
        }
        setNewNotificationGroup('')
        setAsset({ ...asset, group_id: newGroup.id })
      }
    }
  }
  return (
    <Modal footer={_renderFooter()}>
      {loading ? (
        <LoadingFrame />
      ) : (
        <>
          <Space size="m">
            <h2>Edit asset</h2>
          </Space>
          {state.device.id && (
            <div className={styles.bannerImage}>

              <div className={styles.floatleft}>
                <Button icon="cloud_upload" >Change photo</Button>
                <input
                  className={styles.input}
                  onChange={_chooseFiles}
                  type="file"
                  accept="image/*"
                />
              </div>
              {returnImage()}
            </div>
          )}
          {hasPermission(currentUser.user.perms, "ASSET_U") ? (<div className={styles.mainInfo}>
            <fieldset
              disabled={!hasPermission(currentUser.user.perms, "ASSET_U")}
            >
              <Frame>
              <InputRow>
                <Label text="Name">
                  <TextInput
                    value={asset.name}
                    onChange={e =>
                      setAsset({ ...asset, name: e.target.value })
                    }
                  />
                </Label>
              </InputRow>

              <InputRow>
                <Label text="Type">
                  <Select
                    value={asset.assettype_id}
                    onChange={e =>
                      setAsset({
                        ...asset,
                        assettype_id: e.target.value
                      })
                    }
                  >
                    {assetTypesSelect(types.assets)}
                  </Select>
                </Label>

              </InputRow>
              </Frame>
              <br/>
            </fieldset>
            <Frame>
              <InputRow>
                <Label text="Select site">
                  <fieldset disabled={!hasPermission(currentUser.user.perms, "ASSET_U")}>
                    <Select
                      value={asset.site_id}
                      onChange={e =>
                        setAsset({ ...asset, site_id: e.target.value })
                      }>
                      {sites.map(site => (
                        <option key={site.id} value={site.id}>
                          {site.name}
                        </option>
                      ))}
                    </Select>
                  </fieldset>
                </Label>
                </InputRow>
                <InputRow>
                  <Label text="Or create a new site" className={styles.noBottomMargin}>
                    <InputRow>
                      <TextInput value={newSite} onChange={e => setNewSite(e.target.value)} placeholder={''} />
                      <div style={{ flex: "unset" }}><Button theme="primary" disabled={newSite.length < 2} onClick={() => createNewSite(newSite)}>Create</Button></div>
                    </InputRow>
                    {hasPermission(currentUser.user.perms, 'ADMIN') && (
                      <Link alt="Click to manage sites" to={'/customer/sites'}><Button icon="launch" theme="primary">Manage sites</Button></Link>
                    )}
                  </Label>

                </InputRow>
              
              </Frame>
              <br />
              <Frame>
                <InputRow>
                  <Label text="Select notification group">
                    <fieldset disabled={!hasPermission(currentUser.user.perms, "ASSET_U")}>
                      <Select
                        value={asset.group_id}
                        onChange={e =>
                          setAsset({ ...asset, group_id: e.target.value })
                        }>
                        {groups.map(group => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                      </Select>
                    </fieldset>
                  </Label>
                </InputRow>
                <InputRow>
                  <Label text="Or create a new group" className={styles.noBottomMargin}>
                    <InputRow>
                      <TextInput value={newNotificationGroup} onChange={e => setNewNotificationGroup(e.target.value)} placeholder={''} />
                      <div style={{ flex: "unset" }}><Button theme="primary" disabled={newNotificationGroup.length < 2} onClick={() => createNewNotificationGroup(newNotificationGroup)}>Create</Button></div>
                    </InputRow>
                    {hasPermission(currentUser.user.perms, 'ADMIN') && (
                      <Link to={'/customer/groups'}><Button icon="launch" theme="primary" >Manage groups</Button></Link>
                    )}
                  </Label>
                </InputRow>
              </Frame>
              <Space size="m" />
          </div>) : (null)}

        </>
      )}
    </Modal>
  );
}
