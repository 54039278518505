import React from 'react';
import styles from './LogoIcon.module.scss';
import Img from '../../images/TRESORTRACK.svg';

export default function Logo({ size }) {
  return (
    <img
      className={styles.img}
      src={Img}
      alt="Tresor Track Inc"
      width={size}
    />
  );
}
