import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { get } from '../../api';
import { useDebounce } from '../../hooks/inputs';
import TypesContext from '../../contexts/Types';
import Frame from '../../components/Frame';
import LoadingFrame from '../../components/LoadingFrame';
import NoResults from '../../components/NoResults';
import Popover from '../../components/Popover';
import QueryBar from '../../components/QueryBar';
import RowItem from '../../components/RowItem';
import StatusIndicator from '../../components/StatusIndicator';
import TextInput from '../../components/TextInput';
import StatusFilters from '../../components/StatusFilters';
import { useMediaQuery } from 'react-responsive'
import {date} from '../../helpers/date'
export default function ActiveAssets({ mobile = false }) {
  const types = useContext(TypesContext);
  const [query, setQuery] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [loading, setLoading] = useState(true);
  const [statuses, setStatuses] = useState('');
  const [assets, setAssets] = useState([]);
  const debouncedQuery = useDebounce(query, 350);
  const [simpleMode, setSimpleMode] = useState(false);
  const isMobile = false;

  useEffect(() => {
    async function getAssets(first) {
      first && setLoading(true);

      const res = await get('/assets', {
        active: 'true',
        include_devices: '1',
        orderBy: sortBy,
        nameContains: debouncedQuery,
        device_statuses: statuses
      });

      setAssets(res.results);
      setLoading(false);
    }

    getAssets(true);

    /** Re-check active assets every 30 seconds */

    const timer = setInterval(() => {
      getAssets(false);
    }, 1000 * 30);

    return () => {
      clearInterval(timer);
    };
  }, [debouncedQuery, sortBy, statuses]);

  function _renderAssets() {
    return assets.length ? (
      assets.map(a => {
        const device = a.devices && a.devices.length && a.devices[0];
        const type = types.assets.find(t => t.id === a.assettype_id);

        const fullDateTime = moment
          .utc(device.status_updated_time)
          .format('dddd, MMMM Do YYYY, h:mm:ss a');
        //const timeAgo = moment.utc(device.status_updated_time).fromNow();
        const timeAgo = date(device.status_updated_time, 'fromNow');
        return (
          <RowItem
            bbMode={isMobile}
            mobile={mobile}
            key={a.id}
            title={a.name}
            //subtitle={type.name}
            to={`/assets/${a.id}`}
            left={<StatusIndicator status={device.status} />}
            right={
              device.status_updated_time && (
                <span title={fullDateTime}>{`Last updated ${timeAgo}`}</span>
              )
            }
          />
        );
      })
    ) : (
        <NoResults message="No active assets found" />
      );
  }

  return (
    (simpleMode && isMobile) ? (loading && !assets.length ? <LoadingFrame /> : <div style={{ paddingBottom: '24px' }}>{_renderAssets()}</div>) :
      (<Frame padding="0">
        <QueryBar>
          <TextInput
            value={query}
            placeholder="Search by name"
            icon="search"
            loading={!!assets.length && loading}
            onChange={e => {
              setQuery(e.target.value);
            }}
          />

          <Popover
            icon="filter_list"
            text="Filter"
            flag={statuses !== ''}
            align="right"
            width={300}
          >
            {/* 

            <StatusFilters onChange={statuses => setStatuses(statuses)} />*/}
          </Popover>
        </QueryBar>

        {loading && !assets.length ? <LoadingFrame /> : _renderAssets()}
      </Frame>)
  );
}
