import React, { useState,  useContext } from 'react';
import CustomerDevices from '../../routes/CustomerDevices';
import { Route, Link } from 'react-router-dom';
import SingleColumnPage from '../../components/SingleColumnPage';
import { AuthContext } from '../../contexts/Auth';
import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/Button';
import DeviceNew from '../../components/DeviceNew';
import InstallDevice from '../../routes/InstallDevice';
import { SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER } from '../../constants/roles';
import {isAuthorized, hasPermission } from "../../helpers/authorization";
export default function DevicesSimple() {
  const { state } = useContext(AuthContext);
  const [params] = useState({
    params: {
      params: {
        id: state.user.customer_id
      }
    },
  });
  const breadcrumbs = () => (<Breadcrumb title="Tresor Track devices"  />);

  const btns = () => (hasPermission(state.user.perms, 'ADMIN') && (
    <Link to="/devices/new">
      <Button icon="add" theme="primary">
        New device
      </Button>
    </Link>
  ));
  return (<SingleColumnPage breadcrumbs={breadcrumbs} >
    <CustomerDevices match={params} admin={isAuthorized(state.user.roles, [TENANT_ADMIN]) &&
            hasPermission(state.user.perms, 'ADMIN')}/>
    {/*<Route path="/devices/new" component={DeviceNew} exact />
    <Route path="/devices/:id(\d+)/install" component={InstallDevice} exact/>*/}
  </SingleColumnPage>
  );
}
