import React from 'react';

import Breadcrumbs from '../../components/Breadcrumbs';
import CenteredColumn from '../../components/CenteredColumn';
import PageContent from '../../components/PageContent';
import PageHeader from '../../components/PageHeader';

// import styles from './SingleColumnPage.module.scss';

export default function SingleColumnPage({ children, breadcrumbs, buttons, breadcrumbs_extra }) {
  return (
    <>
      <PageHeader right={buttons && buttons()}>
        {breadcrumbs && <Breadcrumbs>{breadcrumbs()}</Breadcrumbs>}

      </PageHeader>
      {breadcrumbs_extra && <PageHeader secondheader={true}>
        {breadcrumbs_extra && <Breadcrumbs>{breadcrumbs_extra()}</Breadcrumbs>}

      </PageHeader>}
      <PageContent>
        <CenteredColumn>{children}</CenteredColumn>
      </PageContent>
    </>
  );
}
