import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { post } from '../../api';
import CustomerContext from '../../contexts/Customer';

import FrameHeader from '../../components/FrameHeader';
import FrameFooter from '../../components/FrameFooter';
import Frame from '../../components/Frame';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import Label from '../../components/Label';

// import styles from './NewCustomerGroup.module.scss';

export default function NewCustomerGroup({ match: { params } }) {
  const { customer } = useContext(CustomerContext);

  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const createGroup = async e => {
    e.preventDefault();
    setSaving(true);

    await post('/groups', {
      name,
      tenant_id: customer.tenant_id,
      customer_id: parseInt(params.id)
    });

    setRedirect(`/customers/${params.id}/groups`);
  };

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <FrameHeader title="New notification group" />
      <form onSubmit={createGroup}>
        <Frame>
          <Label text="Group name">
            <TextInput
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Label>

          <FrameFooter>
            <Button
              type="submit"
              theme="primary"
              isLoading={saving}
              disabled={!name}
            >
              Create
            </Button>
          </FrameFooter>
        </Frame>
      </form>
    </>
  );
}
