import React, { useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "../../helpers/history";
import ScrollToTop from "react-router-scroll-top";
import useThunk from "../../hooks/thunk";
import { AuthContext } from "../../contexts/Auth";
import { authWithToken } from "../../actions/Auth";
import { initialAuthState, AuthReducer } from "../../reducers/Auth";
import LogIn from "../LogIn";
import Forgot from "../Forgot";
import Faq from "../Faq";
import QuickStart from "../QuickStart";
import Register from "../Register";
import Expired from "../Expired";
import TresorTrack from "../TresorTrack";
import ErrorPage from "../Error";
import TermsOfService from "../TermsOfService";
import DeviceInstallRedirect from "../DeviceInstallRedirect";
import LocationSharing from "../LocationSharing";
import Support from "../Support";
import HistoryMapDisplay from "../HistoryMapDisplay/HistoryMapDisplay";
import Center from "../../components/Center";
import Loading from "../../components/Loading";
import ProtectedRoute from "../../components/ProtectedRoute";
import "./App.module.scss";

export default function App() {
  const [state, dispatch] = useThunk(AuthReducer, initialAuthState);

  useEffect(() => {
    const refreshToken = window.localStorage.getItem("refreshToken");
    refreshToken
      ? dispatch(authWithToken(refreshToken))
      : dispatch({ type: "AUTH_STATE_CHECKED" });
  }, []);

  const _app = () => (
    <AuthContext.Provider value={{ state, dispatch }}>
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <Route exact path="/log-in" component={LogIn} />
            <Route exact path="/forgot" component={Forgot} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/expired" component={Expired} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/quick-start" component={QuickStart} />
            <Route exact path="/terms-of-service" component={TermsOfService} />
            <Route exact path="/terms-and-conditions" component={TermsOfService} />
            <Route exact path="/support" component={Support} />
            <Route
              exact
              path="/error/:statusCode?/:errorId?"
              component={ErrorPage}
            />

            {/* QR Code redirects */}
            <ProtectedRoute
              exact
              path="/qr/device/:serialNumber"
              component={DeviceInstallRedirect}
            />
            <Route
              exact
              path="/history/:deviceId/:reportId"
              component={HistoryMapDisplay}
            />

            <Route
              path="/location/:accessKey"
              component={LocationSharing}
            />

            <ProtectedRoute path="/" component={TresorTrack} />
          </Switch>
        </ScrollToTop>
      </Router>
    </AuthContext.Provider>
  );

  const _loading = () => (
    <Center>
      <Loading color="gray" size={42} />
    </Center>
  );

  return state.isCheckingAuthState ? _loading() : _app();
}
