import React, { useState } from "react";
import { put } from "../../api";
import Modal from "../Modal";
import Button from "../Button";
import Space from "../Space";
import OrientationOptions from "../OrientationOptions";
import { getXYZ } from "../../helpers/orientation";
import Label from "../../components/Label";
import SliderCustom from '../../components/SliderCustom';
export default function InstallDeviceStep2({ selectedAsset, back, next ,step=false}) {
  const [selected, setSelected] = useState("flat");
  const [sensitivity, setSensitivity] = useState(2);

  async function saveAndNext() {
    const thresholds = getXYZ(selected, sensitivity);
    await put(`/assets/${selectedAsset.id}`, {
      ...selectedAsset,
      ...thresholds
    });
    next();
  }

  function _renderFooter() {
    return (
      <>
        {back && <Button  onClick={back}>Back</Button>}
        <Button theme="primary" onClick={saveAndNext}>
          Next
        </Button>
      </>
    );
  }

  return (
    <Modal footer={_renderFooter()}>
      <Space size="m">
        <h2>Step {step==false ? 3 : 2}: Install the device</h2>
      </Space>
      <Space size="m">
        <p>
          Next, install the device on the asset according to the Mounting
          Options provided on the TresorTrack Web Site.
        </p>
      </Space>
      <Space size="l">
        <OrientationOptions selected={selected} setSelected={setSelected} />
      </Space>
      <Label text="Sensitivity">
        <SliderCustom
          inputValue={sensitivity}
          onChangeAction={(e, v) => { setSensitivity(v) }}
        />
      </Label>
    </Modal>
  );
}
