import React from 'react';
import styles from './TableItem.module.scss';
import { Link } from 'react-router-dom';
export default function TableItem({ children,header=false,asset=false,right=false }) {
  return (
  
  
  <div className={ header==true ? styles.inputRow: styles.header}>
    
   
   {children}
  
  
</div>
  );
}
