import { headers, serialize,headersNoAuth } from "../helpers/api";
import { HOST } from "../constants/api";
import history from "../helpers/history";

export function get(url = "", params = {}) {
  return fetch(HOST + url + serialize(params), {
    headers: headers(),
    credentials: "omit"
  }).then(res => _handleResponse(res));
}
export function getNoAuth(url = "", params = {}) {
  return fetch(HOST + url + serialize(params), {
    headers: headersNoAuth(),
  }).then(res => _handleResponseNoAuth(res));
}
export function post(url = "", data = {}, handler = true) {
  return fetch(HOST + url, {
    method: "post",
    headers: headers(),
    credentials: "omit",
    body: JSON.stringify(data)
  }).then(res => handler? _handleResponse(res) : res);
}

export function put(url = "", data = {}) {
  return fetch(HOST + url, {
    method: "put",
    headers: headers(),
    credentials: "omit",
    body: JSON.stringify(data)
  }).then(res => _handleResponse(res));
}

export function destroy(url = "") {
  return fetch(HOST + url, {
    method: "delete",
    headers: headers(),
    credentials: "omit"
  });
}

export async function upload(formData) {
  const bearerToken = window.localStorage.getItem("bearerToken");

  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();

    xhr.open("POST", `${HOST}/files`, true);
    xhr.setRequestHeader("Authorization", `Bearer ${bearerToken}`);
    xhr.onload = () => resolve(xhr.response);
    xhr.onerror = () => undefined;
    xhr.send(formData);
  });
}

async function _handleResponseNoAuth(res) {
  if (res.ok) {
    switch (res.status) {
      case 204:
        if(res.statusText==='No Content') {
          return null;
        }
        return res;

      default:
        return await res.json();
    }
  }
}


async function _handleResponse(res) {
  if (res.ok) {
    switch (res.status) {
      case 204:
        return res;

      default:
        return await res.json();
    }
  } else {
    try {
      var error = "";
      if (res.status === 401) {
        const body = await res.json();

        if (body.responseStatus.message === "Invalid Username or Password") {
          return body;
        }
      }
      if (res.status == 404) {
        // no access
      }
      if (res.status === 500) {
        //socket erroir
      }

      const id = new Date().valueOf();
      error = "Sorry! Something went wrong. Please try again."
      try {
        var log_error = await res.json();
        console.log(log_error)
        log_error.responseStatus.url = window.location.href;
        const trace = JSON.stringify(log_error, null, 2);
        window.localStorage.setItem(`error-${id}`, trace);
        if(process.env.REACT_APP_ENV === 'PRODUCTION'){
         // sendCrashLogs(log_error, trace);
        }
      } catch (E) {
        console.log(E)
      }

      if (res.status === 401) { //Insufficent permissions
        _forceLogOut(true) //silent
        return 
      }

      console.log(res)
      return history.push(`/error/${res.status}/${id}`);
    } catch (e) {
      console.log(e)
      /** Last-ditch effort, log out */
      _forceLogOut(false);
    }
  }
}
function sendCrashLogs(error,trace){
  var res = post('/crashlog',{
  error:error,
  stacktrace:trace});
}
function _forceLogOut(silent) {
  window.localStorage.removeItem("refreshToken");
  window.localStorage.removeItem("bearerToken");
  if (silent) {
    window.location.replace("/log-in");
  }
  else {
    window.location.replace("/log-in?tokenExpired=true");
  }
}
