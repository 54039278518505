import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useFormState } from 'react-use-form-state';

import { post,get } from '../../api';
import validate from '../../helpers/validate';
import assetTypesSelect from '../../helpers/assetTypes';
import { AuthContext } from '../../contexts/Auth';
import TypesContext from '../../contexts/Types';

import Modal from '../../components/Modal';
import Button from '../Button';
import DeviceCard from '../DeviceCard';
import Label from '../Label';
import Select from '../Select';
import Space from '../Space';
import InputRow from '../InputRow';
import TextInput from '../TextInput';

import styles from './InstallDeviceStep1.module.scss';

export default function InstallDeviceStep1({ device, assets, loading, next,back,step=false }) {
  const { state } = useContext(AuthContext);
  const types = useContext(TypesContext);

  const [selectedAsset, setSelectedAsset] = useState(0);
  const [saving, setSaving] = useState(false);

  const [formState, { text, select }] = useFormState({
    name: '',
    type: 1,
    serial: ''
  });

  const valid = validate(formState, { required: ['name'] });

  async function _createAsset() {

    const { tenant_id, customer_id } = state.user;
    const { name, type, serial } = formState.values;

    const checkName = await get(`/assets/name/${name}`);
    if (checkName != undefined) {
      if (checkName === false) {
        alert("That asset name already exists. Type in a new asset name.");
      } else {
        setSaving(true);

        const res = await post(`/assets`, {
          name,
          assettype_id: type,
          serial,
          tenant_id,
          customer_id
        });
        next(res);
      }
    }
    
  }

  function _handleNext() {
    valid ? _createAsset() : next(selectedAsset);
  }

  function _renderFooter() {
    return (
      <>
        {back && !step && <Button  onClick={back}>Back</Button>}
        <Button
          theme="primary"
          onClick={_handleNext}
          isLoading={saving}
          disabled={!selectedAsset && !valid}
        >
          Next
        </Button>
      </>
    );
  }

  return (
    <Modal footer={_renderFooter()}>
      <Space size="s">
        <h2>Step {step==false ? 2 : 1}: Assign an asset</h2>
      </Space>

      <Space size="m">
        <p>
          First, select or create the asset this device will be installed on.
        </p>
      </Space>

     {/*<Space size="m">
        <Label text="Device" theme="light" />
        <DeviceCard device={device} />
      </Space> */} 

      <Space size="l">
        <Label text="Asset" theme="light" />
        <div className={styles.assetWrap}>
        {assets && assets.length>0 &&(<>
          <Space size="s">
            <Label text="Choose an inactive asset">
              <Select
                disabled={loading}
                value={selectedAsset}
                onChange={e => setSelectedAsset(e.target.value)}
              >
                <option value={-1}>---</option>
                {assets.map(a => (
                  <option key={a.id} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </Select>
            </Label>
          </Space>

          <Space size="l" />

          <Space size="m">
            <p>Or create a new asset:</p>
          </Space>
        
        </>)}  

          <InputRow>
            <Label text="Name">
              <TextInput
                {...text('name')}
                error={formState.errors.name}
                required
              />
            </Label>
          </InputRow>

          <InputRow>
            <Label text="Type">
              <Select {...select('type')}>
                {assetTypesSelect(types.assets)}
              </Select>
            </Label>

           
          </InputRow>
        </div>
      </Space>
    </Modal>
  );
}
