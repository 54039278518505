import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { createTheme } from 'baseui';
import Button from '../Button';
import AssetCard from '../AssetCard';
import Label from '../Label';
import Select from '../Select';
import Icon from '../Icon';
import { post } from "../../api";
import Space from '../Space';
import Modal from '../Modal';
import { PinCode } from "baseui/pin-code";
import styles from './InstallOnAssetStep1.module.scss';
import TextInput from '../TextInput';
import Image from "../Image";
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { ERROR, NOT_AVAILABLE, DEVICE_ALREADY_ASSIGED, DEVICE_READY_TO_ASSIGN, DEVICE_SIM_NOT_ACTIVATED } from '../../constants/activation_statuses.js';
import InputRow from '../InputRow';
import LoadingFrame from '../LoadingFrame';
export default function InstallOnAssetStep1({ asset, devices, loading, next, activation_feedback, back }) {
  const [selectedDevice, setSelectedDevice] = useState(0);
  const [checkingDevice, setCheckingDevice] = useState(false);
  const [values, setValues] = useState(["", "", "", ""]);
  const [checkingPin, setCheckingPin] = useState(false);
  const [returnMessage, setReturnMessage] = useState(null);
  const [pinEnabled, setPinEnabled] = useState(false);
  const [serialEnabled, setSerialEnabled] = useState(true);
  const [newDeviceSerial, setNewDeviceSerial] = useState(null);
  //const [displayPin, setDisplayPin] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [backOptions, setBackOptions] = useState(true);
  //const [loading,setLoading] = useState(true);
  const engine = new Styletron();
  const primitives = {
    inputFillActive: '#a9a9a9',
    inputFill: '#d9d9d9',
  };
  const overrides = {
    colors: {
      inputFillActive: primitives.inputFillActive,
      inputFill: primitives.inputFill,
    },
  };
  const pinTheme = createTheme(primitives, overrides);

  async function checkSerial(value) {
    setReturnMessage(null);
    if (value.length >= 15) {
      setCheckingDevice(true);
      const deviceToTake = await post(`/device/exist`, {
        serial: value,
      });
      setReturnMessage(deviceToTake);
      if (deviceToTake != undefined) {
        switch (deviceToTake) {

          case ERROR:
            //setError(true);
            break;
          case NOT_AVAILABLE:
            // setError(true);
            break;
          case DEVICE_ALREADY_ASSIGED:
            alert("You have selected a device already in your list. Proceed to next step.");
            next(null, value);
            break;
          case DEVICE_READY_TO_ASSIGN:
            setSerialEnabled(false);
            setNewDeviceSerial(value);
            //setDisplayPin(true);

            setPinEnabled(true);//disable field
            break;
          case DEVICE_SIM_NOT_ACTIVATED:
            setNewDeviceSerial(value);
            setSerialEnabled(false);
            //setDisplayPin(true);
            setBackOptions(false);//disable back button
            setPinEnabled(true);//disable field
            break;

          default:

            break;
        }
      } else {
        alert("Sorry! Unable to retrieve data. Please try again.")
      }
      setCheckingDevice(false);
    }
  }
  async function changeValues(input) {
    setReturnMessage(null);
    setValues(input);
    setError(false);
    setSuccess(false);
    if (input[0] !== "" && input[1] !== "" && input[2] !== "" && input[3] !== "") {
      setCheckingPin(true);
      const device = await post(`/device/${newDeviceSerial}/pin/`, {
        activation_code: input.toString(),
        activate_device: true,
      });
      if (device && device.id !== 0) {
        //var res = await activation_feedback(true);
        //Promise.all(res);
        setSelectedDevice(device.id);
        setSuccess(true);
        setBackOptions(false);//disable back button
        setPinEnabled(false);//disable field
        setCheckingDevice(true);
        //next(device.id);
      } else {
        setValues([
          ,
          ,
          ,

        ])
        setError(true)
        setValues([
          "",
          "",
          "",
          ""
        ])
      }
      setCheckingPin(false);
    }
  }

  function renderMessageResponse() {
    switch (returnMessage) {

      case ERROR:
        return (
          <div className={styles.wrap}>
            <div className={styles.wrapvert}>
              <div className={styles.error}>
                <Icon name="error" size={22} />
              </div>
              <h1>Something went wrong</h1>
            </div>
            <p className={styles.bodyLeft}>Looks like something went wrong with the process.
                      <br />
                      Contact the TresorTrack support team to troubleshoot this issue.
                      <br /><br />
                      Call 650-495-8758 or e-mail support@tresortrack.com</p>
          </div>);
      case NOT_AVAILABLE:
        return (
          <div className={styles.wrap}>
            <div className={styles.wrapvert}>
              <div className={styles.error}>
                <Icon name="error" size={22} />
              </div>
              <h1>Device not found</h1>
            </div>
            <p className={styles.bodyLeft}>Looks like you typed in the wrong serial number.</p>
          </div>);
      case DEVICE_ALREADY_ASSIGED:
        return "";//"DEVICE_ALREADY_ASSIGED";
      case DEVICE_READY_TO_ASSIGN:
        return "";//"DEVICE_READY_TO_ASSIGN";
      case DEVICE_SIM_NOT_ACTIVATED:
        return "DEVICE_SIM_NOT_ACTIVATED";

      default:

        break;
    }
  }
  function renderWrongPin() {
    return (<div className={styles.wrap}>
      <div className={styles.wrapvert}>
        <div className={styles.error}>
          <Icon name="error" size={22} />
        </div>
        <h1>Wrong PIN</h1>
      </div>
      <p className={styles.bodyLeft}>Looks like you typed in the wrong PIN code.</p>
    </div>);
  }
  function renderSuccess() {
    return (<div className={styles.wrap}>
      <div className={styles.wrapvert}>
        <div className={styles.icon}>
          <Icon name="check_circle" size={22} />
        </div>
        <h1>PIN correct</h1>
      </div>
      <p className={styles.bodyLeft}>PIN matches our records. Let's move to the next step.</p>
    </div>);
  }
  function _renderFooter() {
    return (
      <>
        {back ? (<Button onClick={back} >Cancel</Button>) : (<>
          {backOptions && <Link to={`/assets/${asset.id}`}>
            <Button>Cancel</Button>
          </Link>}


        </>)}
        <Button
          theme="primary"
          onClick={() => next(selectedDevice)}
          disabled={!selectedDevice}
        >
          Next
        </Button>
      </>
    );
  }

  return (<Modal footer={_renderFooter()}>



    {loading ? (<LoadingFrame />) : (<>
      <Space size="s">
        <h2>Step 1: Choose a device</h2>
      </Space>

      <Space size="s">
        <p>First, select the device that will be installed on this asset.</p>
      </Space>

      <Space size="s">
        <AssetCard asset={asset} />
      </Space>

      <Space size="xxs">
        <Label text="Device" theme="light" />
        <div className={styles.deviceWrap}>
          {devices && devices.length > 0 ? (<>
            <Space size="xs">
              <Label text="Serial number">
                <Select
                  disabled={loading}
                  value={selectedDevice}
                  onChange={e => setSelectedDevice(e.target.value)}
                >
                  <option value={-1}>Choose a device...</option>
                  {devices.map(d => (
                    <option key={d.id} value={d.id}>
                      {d.serial_number}
                    </option>
                  ))}
                </Select>
              </Label>
            </Space>



            <Space size="s" />
            <Space size="s">
              <div className={styles.bannerImage}>
                <Image url={"/img/TresorTrack.png"} token={false} zoom={true} max={false} />
              </div>
            </Space>
            <Space size="xxs">
              <InputRow>
                <Label text="Or add a new device">
                  <TextInput
                    keyboardType="numeric"
                    serial_width={true}
                    type="number"
                    spinHide={true}
                    maxLength={15}
                    disabled={checkingDevice || !serialEnabled}
                    placeholder="Enter serial number"
                    onChange={e => checkSerial(e.target.value)}></TextInput>
                </Label>

                <Label text="PIN">
                  {pinEnabled ? (

                    <StyletronProvider value={engine}>
                      <BaseProvider theme={pinTheme}>
                        <PinCode
                          values={values}
                          onChange={({ values }) => changeValues(values)}
                          disabled={checkingPin || !pinEnabled}
                          clearOnEscape
                          placeholder=""
                          autoFocus={true}
                        />
                      </BaseProvider>
                    </StyletronProvider>
                  ) :
                    <StyletronProvider value={engine}>
                      <PinCode disabled placeholder="" />
                    </StyletronProvider>
                  }
                </Label>

              </InputRow>
              <InputRow>
                <>
                  {returnMessage != null && <div>
                    {renderMessageResponse()}
                  </div>}
                  {error && <div>{renderWrongPin()}</div>}
                  {success && <div>{renderSuccess()}</div>}
                </>


              </InputRow>

            </Space>

          </>) : (<>
            <Space size="s">
              <div className={styles.lineHeight}>No devices available. To add a new device, enter the serial number and the 4-digit PIN code that is located on the TresorTrack device.</div>
            </Space>
            <Space size="s">
              <div className={styles.bannerImage}>
                <Image url={"/img/TresorTrack.png"} token={false} zoom={true} max={false} />
              </div>
            </Space>
            <Space size="xxs">
              <InputRow>
                <Label text="Serial number">
                  <TextInput
                    keyboardType="numeric"
                    serial_width={true}
                    type="number"
                    spinHide={true}
                    maxLength={15}
                    disabled={checkingDevice || !serialEnabled}
                    placeholder="Enter serial number"
                    onChange={e => checkSerial(e.target.value)}></TextInput>
                </Label>
                <Label text="PIN">
                  {pinEnabled ? (

                    <StyletronProvider value={engine}>
                      <BaseProvider theme={pinTheme}>
                        <PinCode
                          values={values}
                          onChange={({ values }) => changeValues(values)}
                          disabled={checkingPin || !pinEnabled}
                          clearOnEscape
                          placeholder=""
                          autoFocus={true}
                        />
                      </BaseProvider>
                    </StyletronProvider>
                  ) :
                    <StyletronProvider value={engine}>
                      <PinCode disabled placeholder="" />
                    </StyletronProvider>
                  }
                </Label>
              </InputRow>
              <InputRow>
                <>
                  {returnMessage != null && <div>
                    {renderMessageResponse()}
                  </div>}
                  {error && <div>{renderWrongPin()}</div>}
                  {success && <div>{renderSuccess()}</div>}
                </>
              </InputRow>
            </Space>
          </>)}
        </div>
      </Space>
    </>)}

  </Modal>

  );
}
